import React, { FC } from "react";
import { ImgSelectionOption } from "./types";
import { BaseFormContentProps } from "../../@types/form";
import styles from "./imgSelection.module.scss";
import { toggleInList } from "../../utils/array";

interface ImgSelectionProps extends BaseFormContentProps {
  // TODO : FIX THIS EMBARRASSING SHIT AND MAKE IT REQUIRED. IT IS BECAUSE TS IS YELLING AT YOU IN THE MODELS
  options?: ImgSelectionOption[];
  prompt?: string;
}
export const ImgSelection: FC<ImgSelectionProps> = ({
  options,
  prompt,
  onChange,
  data,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.prompt}>{prompt}</div>
      <div className={styles.cards}>
        {options?.map((option) => (
          <div
            className={`${styles.card} ${
              data && data.includes(option.id) ? styles.selected : ""
            }`}
            onClick={() => onChange(toggleInList(data, option.id))}
          >
            <img src={option.imgUrl} alt="" />
            <h6>{option.title}</h6>
          </div>
        ))}
        <div
          onClick={() => onChange(toggleInList(data, "other"))}
          className={`${styles.card} ${
            data && data.includes("other") ? styles.selected : ""
          }
          ${styles.other}
          `}
        >
          Other
        </div>
      </div>
    </div>
  );
};
