import { ReactComponent as Gigi } from "../../styles/icons/Gigi.svg";

export const GigiTitle: React.FC<{
  title: string;
  description: string;
  talkToGigi?: boolean;
}> = ({ title, description, talkToGigi }) => {
  return (
    <div style={{ margin: "32px 0px" }} className="column gapLg">
      <div className="alignCenter gapMd">
        <Gigi width={24} height={24} />
        <h2>{title}</h2>
      </div>
      <p style={{ fontSize: "13px", lineHeight: 1.25, color: "#a9a9a9" }}>
        {description}
        {talkToGigi && (
          <>
            <br /> <br /> looking for something else? Talk to GIGI
          </>
        )}
      </p>
    </div>
  );
};
