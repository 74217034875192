import { BaseStyleProfileStep } from "../BaseStyleProfileStep";
import { BrandSearchAndRate } from "./components/brandSearchAndRate/BrandSearchAndRate";
import { UserDataForm } from "./components/userDataForm/UserDataForm";

export class UserBasicDataStep extends BaseStyleProfileStep {
  name = "UserData";
  Content = UserDataForm;

  constructor() {
    super();
  }

  isStepComplete = (data: any) =>
    data && !!data.age && data.profession && data.style && data.gender;
  getIntro = () =>
    "Fill in the following information. This helps me to understand your needs and preferences";
}
