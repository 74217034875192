import { ReactNode, useContext } from "react";
import { AppContext } from "../../App";

export const PageWrapper: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { isMobile } = useContext(AppContext);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: "1",
        padding: isMobile ? "58px 0px 32px 0px " : "16px 0px 16px 0px",
        height: "100%",
      }}
    >
      {children}
    </div>
  );
};
