import { isEmpty, isNil } from "lodash";
import { useInfiniteQuery, useQuery } from "react-query";
import { BasicQuery } from "../../../@types/query";
import { LIMIT } from "../../../params";
import { getProducts } from "../../../services/api/products/products.api";
import { getWizardProducts } from "../../../services/api/users/wizard/wizard.api";
import { store } from "../../../store";

export const useProductsSearch = (
  { term, filters, limit, weights }: BasicQuery,
  enabled?: boolean
) => {
  const isEnabled = isNil(enabled) ? !!(term || !isEmpty(filters)) : enabled;
  return useQuery({
    queryFn: async () => {
      const res = await getProducts({
        term,
        filters,
        limit: limit ?? 50,
        weights,
      });
      return res.items;
    },
    queryKey: ["ProductsResults", term, filters, weights],
    enabled: !!store.getState().app.user && isEnabled,
  });
};

export const useInfiniteProductSearch = (
  query: BasicQuery,
  enabled?: boolean
) => {
  const isEnabled = isNil(enabled)
    ? !!(query.term || !isEmpty(query.filters))
    : enabled;

  const limit = query.limit ?? LIMIT;
  return useInfiniteQuery({
    queryFn: async ({ pageParam }) => {
      const res = await getProducts({
        ...query,
        limit,
        skip: pageParam,
      });
      return res.items;
    },
    queryKey: [
      "InfiniteProductsResults",
      query.term,
      query.filters,
      query.weights,
      query.msgId,
      query.limit,
    ],
    enabled: !!store.getState().app.user && isEnabled,
    getNextPageParam: (last, all) =>
      last.length === limit ? all.length * limit : null,
  });
};
export const useWizardProductsSearch = (
  query: BasicQuery & { brands: string[] },
  disabled: boolean
) =>
  useQuery({
    queryFn: async () => {
      const res = await getWizardProducts(query);
      return res.items;
    },
    queryKey: ["wizardProducts", query.term, query.filters],
    enabled: !disabled,
  });
