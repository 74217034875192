import React, { FC, ReactNode, useState } from "react";
import { InputChangeEvent } from "../../@types/events";
import styles from "./searchAndRate.module.scss";
import { isEmpty } from "lodash";
import { Input } from "../Input/Input";
import { Search } from "../Input/Search/Search";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
interface SearchAndRateProps {
  onSearch: (term: string) => void | Promise<void>;
  selectedData: { content: ReactNode; rate: number; id: string }[];
  isAsync?: boolean;
  searchOptions: { label: ReactNode; value: string }[] | null | undefined;
  onSelectOption: (id: string) => void;
  onRateSelected: (id: string, rate: number) => void;
  placeholder?: string;
  recommendedItems?: { content: ReactNode; rate: number; id: string }[];
}

export const SearchAndRate: FC<SearchAndRateProps> = ({
  onSearch,
  selectedData,
  isAsync,
  searchOptions,
  onSelectOption,
  onRateSelected,
  placeholder,
  recommendedItems,
}) => {
  // TODO: RATING IS IN ANOTHER STEP. FIRST FINISH THE MVP TO GET TO THE TARGET AUDIENCE
  const [term, setTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onChange = async (val: string) => {
    setTerm(val);
    if (!isAsync) {
      onSearch(val);
      return;
    }
    setIsLoading(true);
    await onSearch(val);
    setIsLoading(false);
  };
  return (
    <div className="column flex1">
      <div className={styles.searchContainer}>
        <Search
          onChange={onChange}
          value={term}
          autoCompleteOptions={searchOptions}
          onSelectAutoComplete={onSelectOption}
          isLoading={isLoading}
          placeholder={placeholder ?? undefined}
        />
      </div>
      {recommendedItems && (
        <div className="column gapLg paddingLg">
          <span>Based on similar profiles:</span>
          <div className={"alignCenter gapMd"}>
            {recommendedItems.map((item, index) => (
              <div
                className={`${styles.selectedItem} ${styles.recommended}`}
                onClick={() => onSelectOption(item.id)}
                key={index}
              >
                {item.content}
              </div>
            ))}
          </div>
        </div>
      )}
      <div className={"column gapLg"}>
        <div>My Favorites</div>
        <div className={styles.selectedItemsContainer}>
          {!isEmpty(selectedData) &&
            selectedData.map((selected, index) => (
              <div className={styles.selectedItem} key={index}>
                {selected.content}
                <div className={styles.removeItem}>
                  <DeleteOutlinedIcon />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
