import { FC, ReactElement, useEffect, useState } from "react";
import { useLocation } from "react-router";

interface PathResolverProps {
  children: ReactElement;
  exclude?: string[];
  include?: string[];
}

export const WithPathResolver: FC<PathResolverProps> = ({
  children,
  exclude,
  include,
}) => {
  const location = useLocation();
  const [shouldPass, setShouldPass] = useState(true);
  useEffect(() => {
    if (!location.pathname) return;
    const checkExpressions = (expressions: string[]) => {
      return expressions.some((expression) => {
        if (expression.startsWith("*")) {
          return location.pathname.includes(expression.slice(1, -1));
        } else {
          return location.pathname === expression;
        }
      });
    };
    if (exclude && checkExpressions(exclude)) {
      setShouldPass(false);
      return;
    }
    if (include && !checkExpressions(include)) {
      setShouldPass(false);
      return;
    }
    setShouldPass(true);
  }, [location.pathname]);

  if (shouldPass) return children;

  return <></>;
};
