import { useInfiniteQuery, useQuery } from "react-query";
import { BasicQuery } from "../../../@types/query";
import { LIMIT } from "../../../params";
import {
  getProducts,
  getSameFashionFor,
  getSimilarProducts,
} from "../../../services/api/products/products.api";
import { allMockProducts } from "../../../services/mock/products";
import { getNextSkip } from "../../../utils/reactQuery";

export const useProduct = (productId: string) => {
  return useQuery({
    queryFn: async () => {
      const mockProduct = allMockProducts.find(({ id }) => id === productId);
      if (mockProduct) {
        return mockProduct;
      }
      const res = await getProducts({
        ids: [productId],
      });
      return res.items[0];
    },
    queryKey: ["ProductDetails", productId],
    enabled: !!productId,
  });
};
export const useSimilarProducts = (
  productId: string,
  filters?: BasicQuery["filters"]
) => {
  return useInfiniteQuery({
    queryFn: async ({ pageParam }) => {
      const res = await getSimilarProducts(productId, {
        skip: pageParam,
        limit: LIMIT,
        filters: filters ?? [],
      });
      return res.items;
    },
    queryKey: ["similarProduct", productId, filters],
    getNextPageParam: getNextSkip,
    keepPreviousData: true,
    cacheTime: 60 * 1000,
    staleTime: 60 * 1000,
    enabled: !!productId,
  });
};
export const useSimilarFashionProducts = (productId: string) =>
  useQuery({
    queryFn: async () => {
      const res = await getSameFashionFor(productId);
      return res.items;
    },
    queryKey: ["ProductSimialrFashion", productId],
    enabled: !!productId,
  });
