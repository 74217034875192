import {
  Lookbook,
  LookbookDetails,
} from "../../../pages/lookbook/LookbookDetails/LookbookDetails";
import { api, baseUrl } from "../api";

export const getLookbookDetails = async (
  id: string
): Promise<LookbookDetails> => {
  try {
    const res = await api.calls.get(
      `${baseUrl}/api/products/lookalike/lookbook/${id}`
    );
    return res.data.lookbook;
  } catch (e) {
    console.error(e);
    throw new Error("failed lookboom details");
  }
};
export const getLookbooks = async (): Promise<{
  items: Lookbook[];
}> => {
  try {
    const res = await api.calls.get(
      `${baseUrl}/api/products/lookalike/lookbook`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    return { items: [] };
  }
};
export const getUserFeed = async (): Promise<{
  items: { _id: string; path: string }[];
}> => {
  try {
    const res = await api.calls.get(
      `${baseUrl}/api/products/lookalike/lookbook/feed`
    );
    return res.data;
  } catch (e) {
    console.error(e);
    return { items: [] };
  }
};
