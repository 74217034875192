import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { keys, pick } from "lodash";
import { useState } from "react";
import { useMutation } from "react-query";
import { queryClient } from "../../..";
import { Button } from "../../../components/button/Button";
import { Loader } from "../../../components/loader/Loader";
import { Modal } from "../../../components/Modal/Modal";
import {
  useAdminLookalikeProducts,
  useLookalikeParsingFilterOptions,
  useLookalikesParsing,
  useLookalikesParsingValues,
} from "../../../hooks/admin/lookalikes/useLookalikesParsing";
import { useFlattenInfiniteQuery } from "../../../hooks/query/useFlattenInfinite";
import { updateLookalikeParsing } from "../../../services/api/admin/lookalikes.admin";
import s from "./LookalikeParsing.module.scss";

export const LookalikesParsing = () => {
  const [filters, setFilters] = useState({
    taggingStatus: "model",
    lookbookCode: "all",
  });
  const { data, isLoading: isLoadingLookalikes } =
    useLookalikesParsing(filters);
  const { data: values } = useLookalikesParsingValues();
  const { data: filterOptions } = useLookalikeParsingFilterOptions();
  const lookalikes = useFlattenInfiniteQuery(data);
  const [lookalikeItem, setLookalikeItem] = useState<any>();
  const [form, setForm] = useState<Record<string, string>>();
  const { mutateAsync: updateForm, isLoading } = useMutation({
    mutationKey: ["saveFormParsingLookalike", lookalikeItem?._id],
    mutationFn: async ({ id, ...values }: any) => {
      const res = await updateLookalikeParsing(id, values);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["adminLookalikesParsing"] });
      queryClient.invalidateQueries({ queryKey: ["adminLookalikeProducts"] });
    },
  });
  const { data: lookalikeProducts } = useAdminLookalikeProducts(
    lookalikeItem?._id
  );

  const onUpdateForm = async () => {
    await updateForm({ id: lookalikeItem._id, ...form });
    // setLookalikeItem(null);
  };

  return (
    <>
      <div className="column gapLg paddingLg">
        <h3>Filters</h3>
        <div className="alignCenter gapMd">
          <FormControl fullWidth>
            <InputLabel id="tagging-status">Tagging status</InputLabel>
            <Select
              labelId={"tagging-status"}
              id={"tagging-status"}
              value={filters.taggingStatus}
              label={"tagging status"}
              onChange={(ev) =>
                setFilters((prev) => ({
                  ...prev,
                  taggingStatus: ev.target.value,
                }))
              }
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={"tagged"}>Tagged</MenuItem>
              <MenuItem value={"model"}>Model</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="lookbook-code">lookbook code</InputLabel>
            <Select
              labelId={"lookbook-code"}
              id={"lookbook-code"}
              value={filters.lookbookCode}
              label={"lookbook code"}
              onChange={(ev) =>
                setFilters((prev) => ({
                  ...prev,
                  lookbookCode: ev.target.value,
                }))
              }
            >
              <MenuItem value="all">All</MenuItem>
              {filterOptions?.lookbooks.map((l) => (
                <MenuItem value={l.code}>{l.code}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className={s.page}>
        {isLoadingLookalikes && <Loader />}
        {lookalikes?.map((item) => (
          <img
            alt="lookalike"
            src={item.lookalikes.path}
            onClick={() => {
              setLookalikeItem(item.lookalikes);
              setForm(() => pick(item.lookalikes, ...keys(values)));
            }}
          />
        ))}
      </div>

      <Modal
        open={!!lookalikeItem}
        onClose={() => setLookalikeItem(false)}
        width="75vw"
      >
        {lookalikeItem && (
          <div style={{ overflow: "auto" }} className="column gapXlg overflow">
            <h3 style={{ color: "black" }}>
              Is Tagged:{lookalikeItem.tagStatus === "tagged" ? "yes" : "no"}
            </h3>
            {values && lookalikeItem && (
              <>
                <div className="flex gapXlg">
                  <img width={"500px"} src={lookalikeItem?.path} />

                  <div
                    style={{ minWidth: "200px" }}
                    className="paddingLg column gapXlg"
                  >
                    {keys(values).map((key: string) => (
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          {key}
                        </InputLabel>
                        <Select
                          labelId={key}
                          id={key}
                          value={form && form[key]}
                          label={key}
                          onChange={(ev) =>
                            setForm((prev) => ({
                              ...prev,
                              [key]: ev.target.value,
                            }))
                          }
                        >
                          {values[key].map((value: string) => (
                            <MenuItem value={value}>{value}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ))}
                    <Button
                      isLoading={isLoading}
                      onClick={() => onUpdateForm()}
                    >
                      Save
                    </Button>
                  </div>
                </div>
                <div
                  style={{ flexWrap: "wrap" }}
                  className="flex alignCenter gapMd"
                >
                  {lookalikeProducts?.map((p) => (
                    <img
                      style={{ width: "150px", borderRadius: "16px" }}
                      src={p.imgUrls[0]}
                      alt="product"
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        )}
      </Modal>
    </>
  );
};
