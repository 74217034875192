import React from "react";
import { AdminGuard, RouteGuard } from "../components/routeGuard/RouteGaurd";

export const withGaurd = (Fc: React.FC): React.FC => {
  const Guarded: React.FC = (props) => {
    return (
      <RouteGuard>
        <Fc />
      </RouteGuard>
    );
  };

  return Guarded;
};
export const withAdminGuard = (Fc: React.FC): React.FC => {
  const Guarded: React.FC = (props) => {
    return (
      <AdminGuard>
        <Fc />
      </AdminGuard>
    );
  };

  return Guarded;
};
