import { FC } from "react";
import { BaseStyleProfileStep } from "../BaseStyleProfileStep";
import { BrandSearchAndRate } from "./components/brandSearchAndRate/BrandSearchAndRate";
import { values } from "lodash";
import { ConnectAccountsView } from "./components/connectAccountsView/ConnectAccountsView";

export class ConnectAccountsStep extends BaseStyleProfileStep {
  name = "ConnectAccounts";
  Content = ConnectAccountsView;

  constructor() {
    super();
  }

  isStepComplete = (data: any) => !!data?.email;
  getIntro = () => "Connect your accounts social accounts ";
}
