import React, { ReactNode } from "react";

export const FloatingFooter: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <div
      style={{
        position: "absolute",
        bottom: "32px",
        left: 0,
        padding: "10px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {children}
    </div>
  );
};
