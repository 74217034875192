import { FC } from "react";
import { BaseStyleProfileStep } from "../BaseStyleProfileStep";
import { BrandSearchAndRate } from "./components/brandSearchAndRate/BrandSearchAndRate";
import { values } from "lodash";

export class BrandSelectionStep extends BaseStyleProfileStep {
  name = "BrandSelection";
  Content = BrandSearchAndRate;

  constructor() {
    super();
  }

  isStepComplete = (data: any) => values(data).length > 4;
  getIntro = () =>
    "Select at least 5 vendors you usually buy from or love their style ";
}
