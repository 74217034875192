import { FC } from "react";
import { BaseFormContentProps } from "../../@types/form";

export interface BaseWizardStepType {
  getIntro: () => string;
  Content: FC<BaseFormContentProps>;
  isStepComplete: (data: any) => boolean;
  name: string;
  getAdditionalProps: () => any;
  getInitialForm: () => any;
}

export abstract class BaseWizardStep implements BaseWizardStepType {
  abstract getIntro: () => string;
  abstract Content: FC<BaseFormContentProps>;
  abstract isStepComplete: (data: any) => boolean;
  abstract name: string;
  getAdditionalProps = () => ({});
  getInitialForm = () => ({});
}
