import React, { FC } from "react";
import { BaseFormContentProps } from "../../../../../../@types/form";
import { CompleteSentence } from "../../../../../../components/completeSentence/CompleteSentece";
import s from "./userDataForm.module.scss";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
interface UserDataSentenceProps extends BaseFormContentProps {
  data: Record<string, string>;
}

export const UserDataForm: FC<UserDataSentenceProps> = ({ data, onChange }) => {
  return (
    <div className={s.form}>
      <FormControl className={s.select}>
        <InputLabel id="gender-label">Gender</InputLabel>
        <Select
          labelId="gender-label"
          id="gender"
          value={data.gender ?? ""}
          label="Gender"
          onChange={(ev) => onChange({ ...data, gender: ev.target.value })}
        >
          {/* <MenuItem value={"M"}>Male</MenuItem> */}
          <MenuItem value={"F"}>Female</MenuItem>
        </Select>
      </FormControl>
      <FormControl className={s.select}>
        <InputLabel id="age-label">Age</InputLabel>
        <Select
          labelId="age-label"
          id="age"
          value={data.age ?? ""}
          label="Age"
          onChange={(ev) => onChange({ ...data, age: ev.target.value })}
        >
          {/* <MenuItem value={18}>18-24</MenuItem> */}
          <MenuItem value={25}>25-34</MenuItem>
          {/* <MenuItem value={35}>35-44</MenuItem> */}
        </Select>
      </FormControl>

      <FormControl className={s.select}>
        <InputLabel id="job-label">Occupation</InputLabel>
        <Select
          id="job"
          labelId="job-label"
          value={data.job ?? ""}
          label="Occupation"
          onChange={(ev) => onChange({ ...data, job: ev.target.value })}
        >
          <MenuItem value={"Lawyer"}>Lawyer</MenuItem>
          <MenuItem value={"Doctor"}>Doctor</MenuItem>
          <MenuItem value={"Business"}>Business</MenuItem>
          <MenuItem value={"Executive"}>Executive</MenuItem>
          <MenuItem value={"Programmer"}>Programmer</MenuItem>
          <MenuItem value={"Teacher"}>Teacher</MenuItem>
          <MenuItem value={"Other"}>Other</MenuItem>
        </Select>
      </FormControl>

      <FormControl className={s.select}>
        <InputLabel id="style-preference-label">Style preferences</InputLabel>
        <Select
          id="style-preference"
          labelId="style-preference-label"
          value={data.stylePreference}
          label="Style preferences"
          onChange={(ev) =>
            onChange({ ...data, stylePreference: ev.target.value })
          }
        >
          <MenuItem value={"athleisure"}>Athleisure</MenuItem>
          <MenuItem value={"formal"}>Formal</MenuItem>
          <MenuItem value={"casual"}>Casual</MenuItem>
          {/* <MenuItem value={"street"}>Street</MenuItem> */}
          <MenuItem value={"hipster"}>Hipster</MenuItem>
          <MenuItem value={"other"}>other</MenuItem>
        </Select>
      </FormControl>
      <FormControl className={s.select}>
        <InputLabel id="shopping-online-label">Shopping online</InputLabel>
        <Select
          id="shopping-online"
          labelId="shopping-online-label"
          value={data.shoppingCount}
          label="Shopping online"
          onChange={(ev) =>
            onChange({ ...data, shoppingCount: ev.target.value })
          }
        >
          <MenuItem value={3}>1-3 times a month</MenuItem>
          <MenuItem value={4}>once a week</MenuItem>
          <MenuItem value={8}>twice a week</MenuItem>
          <MenuItem value={12}>3+ times a week </MenuItem>
          <MenuItem value={"other"}>other</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};
