import { LinearProgress, LinearProgressProps, Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { capitalize, map, values } from "lodash";
import { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import { Brand } from "../../@types/entities/brand";
import { User } from "../../@types/entities/user";
import { Password } from "../../components/Input/Password/Password";
import { AppTextField } from "../../components/Input/TextField/TextField";
import { Button } from "../../components/button/Button";
import {
  DesktopMedia,
  TouchScreenMedia,
} from "../../components/media/MediaQueries";
import { useProfilePicks } from "../../hooks/query/users/useProfilePicks";
import { useEditUserProfile } from "../../hooks/query/users/useUsers";
import { sendForgotPassword } from "../../services/api/users/users.api";
import chat1Src from "../../styles/assets/chat_1.jpg";
import chat2Src from "../../styles/assets/chat_2.jpg";
import funShoppingSrc from "../../styles/assets/fun_shopping.jpeg";
import DressImg from "../../styles/assets/personal_shopper.png";
import s from "./OnboardPage.module.scss";
import { BrandSelection } from "./cmps/brandSelection/BrandSelection";
import { UserInfoForm } from "./cmps/userInfo/UserInfoForm";

export const OnboardPage: React.FC = () => {
  const [step, setStep] = useState(0);
  const [form, setForm] = useState<UserProfileBuilderForm>(InitialForm);
  const [error, setError] = useState("");

  const [searchParams] = useSearchParams();
  const { mutateAsync: submitProfile, isLoading: isSubmitting } =
    useEditUserProfile();
  const navigate = useNavigate();

  const onNextStep = async () => {
    if (!isLastStep) return setStep(step + 1);
    const { profile, signInDetails, ...restProfile } = form;
    try {
      await submitProfile({
        ...restProfile,
        formal: [""],
        night: [""],
        casual: [""],
        ...profile,
        age: parseInt(profile.age),
        ...signInDetails,
      } as User & { password: string });
      navigate(`/feed?${searchParams.toString()}`);
    } catch (error: any) {
      console.log({ error });

      setError(error!.response.data.msg as string);
    }
  };

  const isLastStep = step === formValidator.length - 1;

  return (
    <>
      <DesktopMedia>
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className={s.desktopMsg}>
            <h2>We suggest moving to a mobile browser {":)"}</h2>
            <div>
              As an AI startup, we have a mobile-first approach.
              <br /> a desktop version will be released soon.{" "}
            </div>
          </div>
          <iframe
            id="forceMobile"
            style={{
              width: "535px",
              height: "900px",
              outline: "none",
              border: "thin solid #f5f5f5",
              borderRadius: "15px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
            }}
            src="https://shopper-h65l.onrender.com/onboard"
          />
        </div>
      </DesktopMedia>
      <TouchScreenMedia>
        <div className={s.page}>
          {searchParams.get("app") && (
            <div style={{ margin: "0 0 15px 15px" }}>
              <Button type="tertiary" onClick={() => navigate(-1)}>
                Cancel
              </Button>
            </div>
          )}
          <div className={s.progressContainer}>
            <ProgressBar
              variant="determinate"
              value={(step / (formValidator.length - 1)) * 100}
            />
          </div>
          <div className={s.contentContainer}>
            <div
              style={{ transform: `translateX(-${step * 100}vw)` }}
              className={s.content}
            >
              <div className={s.step}>
                <div className={`${s.explanationTitle} spaceDownLg`}>
                  Create your fashion profile
                </div>
                <UserInfoForm
                  profile={form.profile}
                  onChange={(profile) => setForm({ ...form, profile })}
                />
              </div>
              <div className={s.step}>
                <div className={`${s.explanationTitle} spaceDownLg`}>
                  What Are your Favorite brands?
                </div>
                <BrandSelection
                  onChange={(brands) => {
                    setForm({ ...form, brands });
                  }}
                  selectedBrands={form.brands}
                />
              </div>
              <div className={s.step}>
                <div className={`${s.explanationTitle} spaceDownMd`}>
                  What's your fashion pick?
                </div>
                <div className={`${s.explanationSubtitle} spaceDownLg`}>
                  Select a profile that relates to your fashion direction.
                </div>
                <PresetProfileList
                  brandIds={map(form.brands, "_id")}
                  selectedProfileId={form.presetProfileId}
                  enabled={step === 2}
                  onSelectProfile={(preset) =>
                    setForm({ ...form, presetProfileId: preset })
                  }
                />
              </div>
              <div className={s.step}>
                <UserSignIn
                  form={form.signInDetails}
                  onChange={(details) =>
                    setForm({ ...form, signInDetails: details })
                  }
                  error={error}
                />
              </div>
            </div>
          </div>
          {/* <div style={{ color: "red" }}>{error}</div> */}
          <Button
            onClick={onNextStep}
            disabled={!formValidator[step](form)}
            className={s.ctaBtn}
            isLoading={isSubmitting}
          >
            {isLastStep ? "Let's go shopping" : "Continue"}
          </Button>
        </div>
      </TouchScreenMedia>
    </>
  );
};

const Welcome = () => {
  return (
    <div className={s.step}>
      <div>
        <img src={funShoppingSrc} className={s.animationImg} alt="" />
      </div>
      <div className={s.explanationContainer}>
        <div className={`${s.explanationTitle} spaceDownLg`}>
          A delightful fashion experience <br />
          begins now
        </div>
        <div className={`${s.explanationSubtitle}`}>
          We turn your fashion ideas into reality. <br /> With your own AI
          personal shopper, enjoy <br /> a shopping experience that saves time
          and money.
        </div>
      </div>
    </div>
  );
};

export const PersonalizedExplanation = () => {
  return (
    <div className={s.step}>
      <div>
        <img className={s.animationImg} src={DressImg} alt="animated dress" />
      </div>
      <div className={s.explanationContainer}>
        <span className={s.explanationTitle}>
          A truly personalized experience
        </span>
        <p className={s.explanationSubtitle}>
          Your personal shopper learns what you love, and adapts to ensure each
          result is a perfect match for your taste and lifestyle.
        </p>
      </div>
    </div>
  );
};

export const ChatIntro = () => {
  return (
    <div className={s.step}>
      <div className={s.chatsImgContainer}>
        <img className={s.animationImg} src={chat1Src} alt="chat" />
        <div className="justifyEnd">
          <img className={s.animationImg} src={chat2Src} alt="chat" />
        </div>
      </div>
      <div className={s.explanationContainer}>
        <span className={s.explanationTitle}>Finally, someone to talk to</span>
        <p className={s.explanationSubtitle}>
          From the first chat, your personal shopper understands your fashion
          desires - helping you transform an idea in your mind into a product
          you'll love.
        </p>
      </div>
    </div>
  );
};

export const PresetProfileList: React.FC<{
  profiles?: User[];
  onSelectProfile: (preset: string | null) => void;
  selectedProfileId: string | null;
  brandIds: string[];
  enabled: boolean;
}> = ({
  onSelectProfile,
  selectedProfileId: selectedProfile,
  brandIds,
  enabled,
}) => {
  const { data, isLoading } = useProfilePicks(brandIds, enabled);
  if (isLoading) {
    return (
      <>
        <Skeleton
          style={{
            display: "flex",
            borderRadius: "16px",
            padding: "100px",
            flex: 1,
          }}
        />
        <Skeleton
          style={{
            display: "flex",
            borderRadius: "16px",
            padding: "100px",
            flex: 1,
          }}
        />
        <Skeleton
          style={{
            display: "flex",
            borderRadius: "16px",
            padding: "100px",
            flex: 1,
          }}
        />
      </>
    );
  }

  return (
    <div className={s.presetProfiles}>
      {data?.map((profile) => (
        <div
          className={`${s.profile} ${
            profile._id === selectedProfile ? s.selected : ""
          }`}
          onClick={() => onSelectProfile(profile._id!)}
        >
          <div className={s.profileHeader}>
            {/* <Avatar initials="AA" /> */}
            <div className={s.profileHeaderInfo}>
              <span className={s.profileName}>
                {capitalize(profile.username)}
              </span>
            </div>
          </div>
          <div className={s.profilePicksContainer}>
            <span>Vibe</span>
            <div className={s.picksList}>
              {profile.products.map((p) => (
                <img src={p.imgUrls[0]} className={s.pickImg} alt="pick" />
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export interface SignInDetails {
  username: string;
  password: string;
}
export const UserSignIn: React.FC<{
  form: SignInDetails;
  onChange: (form: SignInDetails) => void;
  isLogin?: boolean;
  error?: string;
}> = ({ form, onChange, isLogin, error }) => {
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  if (isForgotPassword) return <ForgotPassword initialEmail={form.username} />;
  return (
    <div className="column gapXlg flex1">
      <div className="column gapMd">
        <div className={`${s.explanationTitle}`}>
          <div>{isLogin ? "Sign In" : "Create Account"}</div>
        </div>
        <span
          style={{ fontSize: "14px", color: "#797979", textAlign: "center" }}
        >
          Your personal shopper is waiting for you
        </span>
      </div>
      <div className="column gapLg">
        <div className="column gapSm">
          <span className="inputLabel">Email</span>
          <AppTextField
            type="email"
            value={form.username}
            onChange={(ev) => onChange({ ...form, username: ev.target.value })}
            placeholder="gigi_hadid@gmail.com"
          />
        </div>
        <div className="column gapSm">
          <span className="inputLabel">Password</span>
          <Password
            placeholder="Fashionista4Life"
            value={form.password}
            // type="password"
            onChange={(value) => onChange({ ...form, password: value })}
          />
        </div>
        {error && <div style={{ color: "red", fontSize: "14px" }}>{error}</div>}
        {isLogin && (
          <div
            style={{ fontSize: "12px", textAlign: "center" }}
            className="underline"
            onClick={() => setIsForgotPassword(true)}
          >
            Forgot password
          </div>
        )}
      </div>
      {isLogin && (
        <div style={{ marginTop: "32px", textAlign: "center" }}>
          Don't have an account?{" "}
          <Link className="underline" to="/onboard">
            Sign up
          </Link>
        </div>
      )}
    </div>
  );
};
export const ProgressBar = styled(LinearProgress)<LinearProgressProps>({
  "&.MuiLinearProgress-root": {
    height: "8px",
    margin: "0px 20px",
    borderRadius: "15px",
    backgroundColor: "#f3f3f3",
  },
  ".MuiLinearProgress-bar1Determinate": {
    height: "8px",
    background:
      "linear-gradient(90deg, rgba(253,252,255,0.7665660014005602) 0%, rgba(183,221,253,1) 13%, rgba(195,189,253,1) 58%, rgba(220,169,245,1) 79%)",
  },
});

const ForgotPassword: React.FC<{
  initialEmail: string;
}> = ({ initialEmail }) => {
  const [email, setEmail] = useState(initialEmail);
  const [isSent, setIsSent] = useState(false);
  const {
    mutateAsync: sendVerification,
    isLoading,
    isError,
  } = useMutation({
    mutationFn: async (email: string) => {
      await sendForgotPassword(email);
    },
  });

  const onForgotPassword = async () => {
    try {
      await sendVerification(email);
      setIsSent(true);
    } catch (error) {}
  };

  return (
    <div className={s.step}>
      <div className="column gapXlg">
        {!isSent ? (
          <>
            <div className={s.explanationTitle}>Reset your password</div>
            <AppTextField
              type="email"
              value={email}
              placeholder="Your registered email"
              onChange={(ev) => setEmail(ev.target.value)}
            />

            <Button isLoading={isLoading} onClick={() => onForgotPassword()}>
              Send reset email
            </Button>

            {isError && <div className="error">Please enter a valid email</div>}
          </>
        ) : (
          <div className={s.explanationTitle}>Password sent to {email}</div>
        )}
      </div>
    </div>
  );
};

// TODO: LATER, INTERFACE AND STUFF FOR SELF VALIFATION, PROBABLY A CLASS TO IMPLEMENT AN INTERFACE. NOT NOW, I HAVE A HUGE HEADACHE

const formValidator: Array<(form: UserProfileBuilderForm) => boolean> = [
  // () => true,
  (form) => values(form.profile).every((value) => !!value),
  // () => true,
  (form) => form.brands.length >= 3,
  // () => true,
  (form) => form.presetProfileId !== "",
  (form) => !!form.signInDetails.username && !!form.signInDetails.password,
  // 2: (form) => form.casual.length > 3,
  // 3: (form) => form.formal.length > 3,
  // 4: (form) => form.night.length > 3,
];

export interface UserProfileBuilderForm {
  profile: {
    age: string;
    // location: string;
    // job: string;
    name: string;
  };
  brands: Brand[];
  casual?: string[];
  formal?: string[];
  night?: string[];
  presetProfileId: string | null;
  signInDetails: {
    username: string;
    password: string;
  };
}
const InitialForm: UserProfileBuilderForm = {
  profile: {
    name: "",
    age: "",
    // location: "NYC",
    // job: "STAM",
  },
  brands: [],
  signInDetails: {
    username: "",
    password: "",
  },
  presetProfileId: "",
  // casual: [],
  // formal: [],
  // night: [],
};
