import { useContext, useEffect, useRef, useState } from "react";
import "swiper/css/bundle";
import { AppContext } from "../../App";
import { ProductsGrid } from "../../components/ProductsGrid/ProductsGrid";
import { Pill } from "../../components/pill/Pill";
import { usePersonalFeed } from "../../hooks/query/feed/useHomeFeed";
import { useFlattenInfiniteQuery } from "../../hooks/query/useFlattenInfinite";
import { toggleInList } from "../../utils/array";
import { WithSearch } from "../search/SearchPage";
import s from "./Feed.module.scss";

export const FeedV2 = () => {
  const { isMobile } = useContext(AppContext);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } =
    usePersonalFeed(selectedCategories);
  const products = useFlattenInfiniteQuery(data);
  const categoriesEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const sentinel = document.getElementById("categoriesSentinel");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!categoriesEl.current) return;
          if (!entry.isIntersecting) {
            categoriesEl.current.classList.add(s.sticky);
          } else {
            categoriesEl.current.classList.remove(s.sticky);
          }
        });
      },
      { threshold: [1.0] }
    );
    if (sentinel) {
      observer.observe(sentinel);
    }
  }, []);

  return (
    <WithSearch>
      <div className={s.container}>
        {/* <WalkMeFeed /> */}
        <div id="categoriesSentinel" />
        <div className={s.spaceSaver}>
          <div
            // ref={categoriesEl}
            className={`${s.feedCategories} ${isMobile ? s.mobileApp : ""}`}
          >
            {["casual", "formal", "premium", "sexy", "night"].map(
              (cat, index) => (
                <div
                  style={{ animationDelay: `${index / 30}s` }}
                  className={s.pillContainer}
                >
                  <Pill
                    size="sm"
                    onClick={() =>
                      setSelectedCategories((prev) => toggleInList(prev, cat))
                    }
                    isSelected={selectedCategories.includes(cat)}
                  >
                    {cat}
                  </Pill>
                </div>
              )
            )}
          </div>
        </div>
        <div className="scrollerContainer">
          <ProductsGrid
            products={products}
            // allowDislike
            hasNextPage={!!hasNextPage}
            fetchNextPage={fetchNextPage}
            isLoading={isLoading}
            isFetchingNextPage={isFetchingNextPage}
            invalidateKeyOnDislike={["InfiniteProductsResults"]}
          />
        </div>
      </div>
    </WithSearch>
  );
};
