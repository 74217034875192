import React, { useContext, useEffect, useState } from "react";
import {
  MiniProduct,
  ProductDetails,
} from "../../../../@types/entities/product.types";
import { AppContext } from "../../../../App";
import { useChat } from "../../../../hooks/useChat";
import { ChatPage } from "../../../../pages/chat/ChatPage";
import { MemoizedDetailsPage } from "../../../../pages/shop/ProductDetailsPage/ProductDetailsPage";
import {
  TransitionContainer,
  TransitionPage,
} from "../../../TransitionContainer/TransitionContainer";
import { SlidingWindow } from "../../SlidingWindow";
export const ProductSlidingWindow: React.FC<{
  isOpen: boolean;
  product: MiniProduct | null;
  onClose: () => void;
  direction?: "bottom" | "right";
  hideForPerformance?: boolean;
  shouldMove?: boolean;
}> = ({
  product,
  isOpen,
  onClose,
  direction = "right",
  hideForPerformance,
  shouldMove,
}) => {
  // const swiper = useRef<SwiperClass>();
  const [step, setStep] = useState(0);
  const { queryOnProduct } = useChat();
  const { isApp } = useContext(AppContext);
  // const onBack = () => {
  //   if (swiper.current && !swiper.current?.isBeginning) {
  //     swiper.current?.enable();
  //     swiper.current?.slidePrev();
  //     swiper.current?.disable();
  //   } else {
  //     onClose();
  //   }
  // };
  const [ui, setUI] = useState({ open: false, closing: false });

  useEffect(() => {
    setUI({ ...ui, open: true });
  }, []);

  useEffect(() => {
    if (!isOpen) setUI({ ...ui, closing: true });
  }, [isOpen]);

  return (
    <SlidingWindow
      isOpen={ui.open && !ui.closing}
      onExit={onClose}
      onClose={() => (!step ? onClose() : setStep((prev) => prev - 1))}
      direction="right"
      keepMounted={false}
      shouldMove={shouldMove}
    >
      <>
        {!hideForPerformance && (
          <TransitionContainer step={step}>
            <TransitionPage naked>
              <MemoizedDetailsPage
                product={product as ProductDetails}
                onClose={onClose}
                onQueryOnProduct={() => {
                  setStep((prev) => prev + 1);
                  queryOnProduct(product as ProductDetails);
                  // swiper.current?.enable();
                  // swiper.current?.slideNext();
                  // swiper.current?.disable();
                }}
              />
            </TransitionPage>
            <TransitionPage naked>
              {product && (
                <ChatPage
                  inWindow
                  id={product._id!}
                  onBack={() => {
                    setStep((prev) => prev - 1);
                  }}
                />
              )}
            </TransitionPage>
          </TransitionContainer>
        )}
      </>
    </SlidingWindow>
  );
};
