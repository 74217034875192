import { produce } from "immer";
import { useEffect, useState } from "react";
import { MiniProduct } from "../../../../@types/entities/product.types";
import { socketService } from "../../../../services/mock/socket";
import { ProductSlidingWindow } from "./ProductSlidingWindow";

export const ProductWindowManager: React.FC<{
  onOpenFirst: () => void;
  onCloseFirst: () => void;
}> = ({ onOpenFirst, onCloseFirst }) => {
  const [productWindows, setProductsWindows] = useState<MiniProduct[]>([]);
  const [shouldBeforeLastMove, setShouldBeforeLastMove] = useState(true);

  const [isClosingVisible, setIsClosingVisible] = useState(false);
  useEffect(() => {
    socketService.on("windowOpen", "product", (product: MiniProduct) => {
      setProductsWindows((prev) => {
        if (!prev.length) onOpenFirst();
        return [...prev, product];
      });
      setShouldBeforeLastMove(true);
    });
    socketService.on("cleanup", "footer", (data: any) => {
      console.log("cleanup");

      setProductsWindows([]);
      onCloseFirst();
    });
    return () => {
      socketService.off("windowOpen", "product");
      socketService.off("cleanup", "footer");
    };
  }, []);

  const onCloseWindow = async () => {
    setIsClosingVisible(true);
    setShouldBeforeLastMove(false);
    if (productWindows.length === 1) {
      onCloseFirst();
    }
    setTimeout(() => {
      setProductsWindows(
        produce((draft) => {
          draft.pop();
        })
      );
      setIsClosingVisible(false);
    }, 300);
  };

  return (
    <>
      {productWindows.map((product, index) => (
        <ProductSlidingWindow
          key={`${product._id}_${index}`}
          onClose={() => onCloseWindow()}
          isOpen={index !== productWindows.length - 1 || !isClosingVisible}
          hideForPerformance={productWindows.length - index > 2}
          // shouldMove={
          //   shouldBeforeLastMove && productWindows.length - index === 2
          // }
          product={product}
          direction="right"
        />
      ))}
    </>
  );
};
