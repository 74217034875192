import { FC } from "react";
import styles from "./avatar.module.scss";

export interface AvatarProps {
  imgUrl?: string;
  initials?: string;
  onClick?: () => void;
}

export const Avatar: FC<AvatarProps> = ({ imgUrl, initials, onClick }) => {
  return (
    <div
      className={`${styles.avatar} ${
        !imgUrl && initials ? styles.initials : ""
      }`}
      onClick={onClick && onClick}
    >
      {imgUrl && <img src={imgUrl} alt="" />}
      {initials && (
        <span className={styles.initials}>{initials.slice(0, 2)}</span>
      )}
    </div>
  );
};
