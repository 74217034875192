import React from "react";
import { BrandIconMap } from "../../utils/maps/brands/brands";
import { Logo } from "../logo/Logo";
import styles from "./BrandThumbnail.module.scss";
interface BrandThumbnailProps {
  icon: keyof typeof BrandIconMap;
  size?: number;
}

export const BrandThumbnail: React.FC<BrandThumbnailProps> = ({
  icon,
  size = 30,
}) => {
  const Icon = BrandIconMap[icon];
  return Icon ? (
    <Icon width={size} height={size} />
  ) : (
    <div className={styles.brandName}>
      <Logo type={"placeholder"} />
      {icon}
    </div>
  );
};
