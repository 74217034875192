import { useInfiniteQuery } from "react-query";
import { getSuggestedProductsForUser } from "../../../services/api/products/products.api";
import { store } from "../../../store";

export const usePersonalFeed = (tags: string[]) => {
  const limit = 24;
  return useInfiniteQuery({
    queryFn: async ({ pageParam }) => {
      const res = await getSuggestedProductsForUser(tags, {
        skip: pageParam,
        limit,
      });
      return res.items;
    },
    queryKey: ["personalFeed", store.getState().app.user?._id, ...tags],
    cacheTime: 30000,
    staleTime: 3000,
    getNextPageParam: (last, all) =>
      last.length === limit ? all.length * limit : null,
  });
};
