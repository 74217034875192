import { NegationFilter } from "../../../../@types/entities/product.types";
import { BasicQuery } from "../../../../@types/query";

export const COLLECTIONS: {
  src: string;
  title: string;
  payload: BasicQuery;
}[] = [
  {
    src: "https://images.urbndata.com/is/image/Anthropologie/88186283_041_b?$an-category$&qlt=80&fit=constrain&hei=1046&qlt=80&wid=698",
    title: "Wedding Guest",
    payload: {
      term: "elegant dress for a formal event",
      weights: {
        formal: 0,
        sexy: 0,
        glamour: 0,
        modern: 0,
      },
      filters: [
        {
          key: "not",
          value: { main_color: ["white"] },
        } as NegationFilter,
      ],
    },
  },
  {
    src: "https://www.fortunateone.com/cdn/shop/products/L8A3672_1659414164_520x.jpg?v=1661393351",
    title: "Summer Breeze",
    payload: {
      term: "light summer dress for the summer",
      weights: {
        solid: 0,
        sexy: 0,
        cute: 0,
        draped: 0,
      },
    },
  },
  {
    src: "https://www.net-a-porter.com/variants/images/1647597325785351/ou/w764_a3-4_q60.jpg",
    title: "Evening Elegance",
    payload: {
      term: "Evening elegant dress for the night",
      weights: {
        formal: 0,
        sexy: 0,
        dark: 0,
        bodycon: 0,
      },
    },
  },
  {
    src: "https://cdn.shopify.com/s/files/1/0414/2622/3270/products/63258BC19FORESTGREEN-1_large.jpg?v=1663893542",
    title: "Party Ready",
    payload: {
      term: "sexy dress for a night party",
      weights: {
        embellished: 0,
        shiny: 0,
        sexy: 0,
        formal: 0,
      },
    },
  },
  {
    src: "https://img.mytheresa.com/652/738/100/jpeg/catalog/product/f1/P00892001_b1.jpg",
    title: "Vintage Charm",
    payload: {
      term: "Retro vintage dress ",
    },
  },
  {
    src: "https://www.fortunateone.com/cdn/shop/files/0S0A8927_1712190702_d1d804e8-4fcd-4874-87cb-725613b0fe79_520x.jpg?v=1713310863",
    title: "Boho Vibes",
    payload: {
      term: "Boho dress",
      weights: {
        solid: 0,
        light: 0,
        sexy: 0,
      },
    },
  },
  {
    src: "https://media.thereformation.com/image/list/fn_select:jq:first(.%5B%5D%7Cif%20has(%22metadata%22)%20then%20select(any(.metadata%5B%5D;%20.external_id%20==%20%22sfcc-is-main%22%20and%20.value.value%20==%20%22True%22))%20else%20empty%20end)/f_auto,q_auto,dpr_auto/w_600/1315271-BLK.json?_s=RAABAB0%20600w",
    title: "Office Chic",
    payload: {
      term: "classic work attire ",
      weights: {
        conservative: 0,
        modern: 0,
        dark: 0,
        chic: 0,
      },
    },
  },
  {
    src: "https://cdn.shopify.com/s/files/1/0414/2622/3270/products/photo-2311-26348d-olive_large.jpg?v=1641430045",
    title: "Casual comfort",
    payload: {
      term: "casual comfortable dress",
    },
  },
];
