import { ViewProps } from "../../@types/commonUi";
import { SlidingWindow } from "../../components/slidingWindow/SlidingWindow";
import { EventWizard } from "./EventWizard";

export const EventWizardView: React.FC<ViewProps> = (props) => {
  return (
    <SlidingWindow
      keepMounted={false}
      {...props}
      customHeader={<></>}
      direction="bottom"
    >
      <EventWizard onClose={() => props.onClose()} />
    </SlidingWindow>
  );
};
