import QueryString from "qs";
import { List, ListForCreate } from "../../../@types/entities/list";
import { BasicQuery } from "../../../@types/query";
import { api, baseUrl } from "../api";

export const getLists = async (): Promise<{ items: List[] }> => {
  try {
    const res = await api.calls.get(`${baseUrl}/api/lists`);
    return res.data;
  } catch (e) {
    console.error(e);
    return { items: [] };
  }
};

export const createList = async (list: ListForCreate) => {
  try {
    const res = await api.calls.post(`${baseUrl}/api/lists`, {
      listToCreate: list,
    });
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

export const getListProducts = async (id: string, query: BasicQuery) => {
  try {
    const res = await api.calls.get(`${baseUrl}/api/lists/${id}/products`);
    return res.data;
  } catch (e) {
    console.error(e);
  }
};
export const getListSuggestions = async (id: string, query: BasicQuery) => {
  const stringified = QueryString.stringify(query);
  try {
    const res = await api.calls.get(
      `${baseUrl}/api/lists/${id}/suggestions?${stringified}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

export const addProductsToList = async (
  listId: string,
  productIds: string[]
) => {
  try {
    const res = await api.calls.post(
      `${baseUrl}/api/lists/${listId}/products`,
      {
        productIds,
      }
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
};

export const removeProductsFromList = async (
  listId: string,
  productIds: string[]
) => {
  const qs = QueryString.stringify({ ids: productIds });
  try {
    const res = await api.calls.delete(
      `${baseUrl}/api/lists/${listId}/products?${qs}`
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
};
