import React, { FC } from "react";
import styles from "./formSteps.module.scss";
import { FormStep } from "./formStep/FormStep";

export const FormSteps: FC<{ stepsCount: number; currentStep: number }> = ({
  stepsCount,
  currentStep,
}) => {
  return (
    <div className={styles.stepsList}>
      {[...new Array(stepsCount)].map((_, index) => {
        return <FormStep isCompleted={index < currentStep} />;
      })}
    </div>
  );
};
