import { capitalize } from "lodash";
import { useState } from "react";
import { MiniProduct } from "../../../../@types/entities/product.types";
import { BlurWrapper } from "../../../../components/blurContainer/Blur";
import { SlidingWindow } from "../../../../components/slidingWindow/SlidingWindow";
import { useAppSelector } from "../../../../store";
import { ReactComponent as Arrow } from "../../../../styles/icons/ArrowRight.svg";
import { FeedV2 } from "../../../feed/FeedV2";
import { ProductCard } from "../../../shop/cmps/productCard/ProductCard";
import s from "./HomeView.module.scss";
export const PersonalFeedPreview: React.FC<{
  products: MiniProduct[];
  onOpenView: () => void;
  onClickProduct: (p: MiniProduct) => void;
}> = ({ products, onClickProduct, onOpenView }) => {
  const [windowName, setWindow] = useState<string>("");
  const { user } = useAppSelector((s) => s.app);
  return (
    <>
      <div className="column gapLg">
        <div onClick={() => onOpenView()} className={s.feedTitleContainer}>
          <div>
            <div className={s.feedTitle}>{capitalize(user?.name)}'s feed</div>
          </div>
          <div className="alignCenter justifyCenter">
            <Arrow />
          </div>
        </div>
        <div className={s.feedContentScroller}>
          {products.slice(0, 9).map((p, index) =>
            index < 8 ? (
              <ProductCard hideLike product={p} onClick={onClickProduct} />
            ) : (
              <BlurWrapper
                style={{ minWidth: "120px", flex: 1 }}
                cta="See more"
                onClick={() => onOpenView()}
              >
                <ProductCard
                  hideLike
                  product={p}
                  sumVersion
                  onClick={onClickProduct}
                />
              </BlurWrapper>
            )
          )}
        </div>
      </div>
      <SlidingWindow
        isOpen={!!windowName}
        onClose={() => setWindow("")}
        title={"My Personalized Feed"}
        direction="right"
        keepMounted={false}
      >
        <FeedV2 />
      </SlidingWindow>
    </>
  );
};
