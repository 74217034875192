import { capitalize, debounce, keys } from "lodash";
import { useCallback, useState } from "react";
import { BasicQuery } from "../../../../@types/query";
import { Button } from "../../../../components/button/Button";
import { AppSlider } from "../../../../components/filters/filterCmpMap/PriceRangeValues";
import { SlidingWindow } from "../../../../components/slidingWindow/SlidingWindow";
import { useInfiniteProductSearch } from "../../../../hooks/query/product/useProuctsSearch";
import { useFlattenInfiniteQuery } from "../../../../hooks/query/useFlattenInfinite";
import { ImgsPreviewsColumns } from "../../../eventWizard/steps/StyleAdjuster";
import s from "./PersonalizedWeights.module.scss";

interface BasePersonalizesWeightsProps {
  onChange: (key: string, value: number) => void;
  weights: any;
  weightList: string[];
  hideWeight?: boolean;
}

export const PersonalizedWeights: React.FC<BasePersonalizesWeightsProps> = ({
  onChange,
  weights,
  weightList,
  hideWeight,
}) => {
  const handleChange = (weight: string, value: number) => {
    onChange(weight, value);
  };

  return (
    <div className="column gapXlg">
      {keys(weights).map((key, index) => {
        return (
          <div className={s.weight} key={key}>
            {!hideWeight && (
              <span style={{ alignSelf: "center", minWidth: "7ch" }}>
                {capitalize(key)}
              </span>
            )}
            <DebouncedAppSlider
              initialValue={weights[key]}
              onChange={(value) => handleChange(key, value)}
            />
          </div>
        );
      })}
    </div>
  );
};

export interface PersonalizesWeightsViewProps
  extends Omit<BasePersonalizesWeightsProps, "onChange"> {
  onChange: (weights: Record<string, number>) => void;
  payload: Pick<BasicQuery, "filters" | "term">;
}

export const PersonalizedWeightsContent: React.FC<
  PersonalizesWeightsViewProps
> = ({ onChange, weights: initialWeights, weightList, payload }) => {
  const [weights, setWeights] =
    useState<Record<string, number>>(initialWeights);
  const { data, isLoading } = useInfiniteProductSearch({
    limit: 9,
    filters: payload.filters,
    weights,
    term: payload.term,
  });
  const products = useFlattenInfiniteQuery(data);
  const handleChange = (weight: string, value: number) => {
    setWeights((prev) => ({ ...prev, [weight]: value }));
  };

  return (
    <div className={s.detailsWeightsContainer}>
      <div
        style={{ paddingTop: "16px" }}
        className="flex column gapLg scrollerContainer"
      >
        <PersonalizedWeights
          weightList={weightList}
          weights={weights}
          onChange={handleChange}
        />
        <ImgsPreviewsColumns
          imgs={products.map((p) => p.imgUrls[0])}
          isLoading={isLoading}
        />
      </div>

      <div className={s.footer}>
        <Button size="lg" onClick={() => onChange(weights)}>
          Personalize search
        </Button>
      </div>
    </div>
  );
};

export const PersonalizedWeightsView: React.FC<
  PersonalizesWeightsViewProps & { isOpen: boolean; onClose: () => void }
> = ({ isOpen, onClose, ...weightsProps }) => {
  return (
    <SlidingWindow
      isOpen={isOpen}
      onClose={onClose}
      title="Personalize Search Results"
      height="100%"
      keepMounted={false}
    >
      <PersonalizedWeightsContent
        {...weightsProps}
        onChange={(x) => {
          weightsProps.onChange(x);
          onClose();
        }}
      />
    </SlidingWindow>
  );
};

export const DebouncedAppSlider: React.FC<{
  initialValue: number | number[];
  onChange: (v: number) => void;
}> = ({ initialValue, onChange }) => {
  const [value, setValue] = useState(initialValue);

  const debounced = useCallback(
    debounce((value) => {
      onChange(value);
    }, 400),
    []
  );
  const handleChange = (value: number | number[]) => {
    setValue(value);
    debounced(value);
  };
  return (
    <AppSlider
      min={-50}
      max={50}
      value={value}
      onChange={(_, value) => handleChange(value)}
    />
  );
};
