import styles from "./TypingInficator.module.scss";

export const TypingIndicator = () => {
  return (
    <div className={styles.typingIndicator}>
      <span />
      <span />
      <span />
    </div>
  );
};
