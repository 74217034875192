import { FC } from "react";
import { PageWrapper } from "../../components/pageWrapper/PageWrapper";
import { useAppSelector } from "../../store";

import { useNavigate } from "react-router";
import { deleteAccount, logout } from "../../services/api/users/users.api";
import { SupportPage } from "../support/SupportPage";
import s from "./Profile.module.scss";

export const ProfilePage: FC = () => {
  const { user } = useAppSelector((s) => s.app);
  return (
    <PageWrapper>
      <div className="column flex1 paddingLg gapXlg">
        <h1>{user?.name}</h1>
        <SupportPage />
        <div className="flex1 column justifyEnd">
          <UserSettings userId={user?._id} />
        </div>
      </div>
    </PageWrapper>
  );
};

export const UserSettings: React.FC<{ userId?: string }> = ({ userId }) => {
  const navigate = useNavigate();

  if (!userId) return <></>;
  const onLogout = async () => {
    await logout();
    navigate("/");
  };

  const onDeleteAccount = async () => {
    await deleteAccount();
    navigate("/");
  };

  return (
    <div
      style={{ paddingBottom: "80px" }}
      className="column gapLg justifyEnd flex1"
    >
      <div className={s.logout} onClick={onLogout}>
        Log out
      </div>

      <div className={s.delete} onClick={onDeleteAccount}>
        Delete account
      </div>
    </div>
  );
};
