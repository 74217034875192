import QueryString from "qs";
import { MiniProduct } from "../../../@types/entities/product.types";
import { InfiniteQuery } from "../../../@types/query";
import { api } from "../api";
import { adminApi } from "./common";

export const getAdminProductAttributes = async (): Promise<
  Record<string, string[]>
> => {
  try {
    const { data } = await api.calls.get(`${adminApi}/products/attributes`);
    return data.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getAdminProducts = async (
  filters: Record<string, string>,
  pagination: InfiniteQuery
): Promise<MiniProduct[]> => {
  try {
    console.log("I am in the calling");

    const stringified = QueryString.stringify({ filters, ...pagination });
    const { data } = await api.calls.get(
      `${adminApi}/products/?${stringified}`
    );
    return data.items;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const updateAdminProductsParsing = async (
  updateMap: Record<string, Record<string, string>>
): Promise<void> => {
  try {
    const { data } = await api.calls.put(`${adminApi}/products`, { updateMap });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
