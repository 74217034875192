import { useQuery } from "react-query";
import { getDetectionsBySourceId } from "../../../services/api/products/lookalike/lookalike.api";

export const useSourceOutfitDetections = (sourceId?: string) => {
  return useQuery({
    queryKey: ["sourceDetector", sourceId],
    queryFn: async () => {
      const res = await getDetectionsBySourceId(sourceId!);
      return res;
    },
    enabled: !!sourceId,
  });
};
