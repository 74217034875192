import React from "react";
import styles from "./SearchOptions.module.scss";
import { BaseListOption } from "../../../../@types/form";

interface Props {
  searchOptions: BaseListOption[] | null;
  onSelect: (
    val: string,
    ev: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  focusIndex?: number;
}

export const SearchOptions: React.FC<Props> = ({
  searchOptions,
  onSelect,
  focusIndex,
}) => {
  if (!searchOptions) return <></>;
  return (
    <div className={styles.autoCompleteList}>
      {searchOptions.length ? (
        searchOptions.map((option, index) => (
          <div
            onClick={(ev) => {
              onSelect(option.value, ev);
            }}
            className={`${styles.autoCompleteItem} ${
              index === focusIndex ? styles.focused : ""
            }`}
          >
            {option.label}
          </div>
        ))
      ) : (
        <div className={styles.noResultsItem}>No results found...</div>
      )}
    </div>
  );
};
