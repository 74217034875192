import { EventWizardFormStep } from "../EventWizard";
import { WizardOptionsView } from "../common/commonEventWizard";

export const EventInfo: React.FC<EventWizardFormStep> = ({
  onChange,
  value,
}) => {
  const onSelectEvent = (ev: string) => {
    onChange(ev);
  };
  return (
    <WizardOptionsView
      options={[
        "Wedding guest",
        "Bachelorette party",
        "Cocktail party",
        "Graduation",
        "Prom",
        "Formal event",
        "Beach party",
      ].map((o) => ({ value: o, label: o }))}
      onSelect={onSelectEvent}
      value={[value]}
      title="I need a dress for..."
    />
  );
};
