import { PriceRangeValues } from "../../../components/filters/filterCmpMap/PriceRangeValues";
import { WizardStepTitle } from "../common/commonEventWizard";

export const PriceRange: React.FC<{
  priceRange: { lt: number; gt: number };
  onChange: ({ lt, gt }: { lt: number; gt: number }) => void;
}> = ({ priceRange, onChange }) => {
  return (
    <div className="column flex1">
      <WizardStepTitle> Price range</WizardStepTitle>
      <div className="column flex1 justifyCenter">
        <PriceRangeValues
          options={[0, 100000]}
          selectedOptions={[priceRange.gt, priceRange.lt]}
          onChange={(value) => {
            onChange(value);
          }}
        />
      </div>
    </div>
  );
};
