import { isEmpty } from "lodash";
import { useInfiniteQuery } from "react-query";
import { LIMIT } from "../../../params";
import { getAdminProducts } from "../../../services/api/admin/products.admin";
import { getNextSkip } from "../../../utils/reactQuery";

export const useAdminProductsForParsing = (filters: Record<string, string>) => {
  return useInfiniteQuery({
    queryKey: ["admin", "products", filters],
    queryFn: async ({ pageParam }) =>
      getAdminProducts(filters, { limit: LIMIT, skip: pageParam }),
    enabled: !isEmpty(filters),
    getNextPageParam: getNextSkip,
  });
};
