// import OutboundIcon from "@mui/icons-material/Outbound";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import { SvgIconOwnProps } from "@mui/material";
export const SendIcon: React.FC<
  SvgIconOwnProps & { onClick?: () => void; disabled?: boolean }
> = ({ onClick, disabled, ...props }) => {
  return (
    <div
      style={{
        color: "#ffffff",
        backgroundColor: "#0d73ec",
        opacity: disabled ? 0 : 1,
        transition: "0.15s",
        borderRadius: "50%",
        width: "30px",
        height: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={() => !disabled && onClick && onClick()}
    >
      <ArrowUpwardRoundedIcon
        color="inherit"
        fontSize="medium"
        // style={{ transform: "rotate(-45deg)" }}
        {...props}
      />
    </div>
  );
};
