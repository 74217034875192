import { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { getLists } from "../../../services/api/lists/lists.api";
import { useAppSelector } from "../../../store";
import { appActions } from "../../../store/app/slice";

export const useLists = () => {
  const { user } = useAppSelector((root) => root.app);
  const dispatch = useDispatch();
  const { data, ...rest } = useQuery({
    queryFn: () => getLists(),
    queryKey: ["lists", user?._id],
    enabled: !!user?._id,
    cacheTime: 60 * 1000 * 60,
    staleTime: 60 * 1000 * 60,
  });

  useEffect(() => {
    if (data?.items) {
      const allIds = data.items.flatMap((list) =>
        list.savedProducts.map(({ id }) => id)
      );
      dispatch(appActions.setLikedProducts({ ids: allIds }));
    }
  }, [data]);

  return { data, ...rest };
};
