import {
  MiniProduct,
  ProductDetails,
  ProductSection,
} from "../../../@types/entities/product.types";
import { store } from "../../../store";
import {
  MockMatchingCargoPants,
  MockMiniProduct,
  allMockProducts,
  convertProductToNormal,
  mockProductSectionsByUser,
} from "../../mock/products";
import { getChatIntro } from "../chat/chat";
import { mockApi } from "../utils";

export const getProducts = async (query: string) => {
  const getProductsByTerm = () => {
    if (query.includes(getChatIntro("getSimilar"))) {
      const [, productName] = query.split(getChatIntro("getSimilar"));
      const product = allMockProducts.find(({ title }) =>
        title.includes(productName.trim())
      );
      if (product) {
        return getSimilarProductFor(product) ?? [];
      }
    }
    if (query.includes(getChatIntro("matchLook"))) {
      const [, productName] = query.split(getChatIntro("matchLook"));
      const product = allMockProducts.find(({ title }) =>
        title.includes(productName.trim())
      );
      if (product) {
        return getMatchingItemsFor(product) ?? [];
      }
    }

    return allMockProducts.filter(
      (product) =>
        product.tags?.some(
          (tag) => tag && query.includes(tag.replace("-", " "))
        ) && product.tags.includes(store.getState().app.user?.tag ?? "")
    );
  };
  return await mockApi<MiniProduct[] | ProductDetails[]>(
    getProductsByTerm().map((p) =>
      convertProductToNormal(p as MockMiniProduct)
    ),
    1000
  );
};

export const getProductById = async (
  productId: string
): Promise<ProductDetails> => {
  return await mockApi<ProductDetails>(
    allMockProducts.find(({ id }) => productId === id) ?? allMockProducts[0]
  );
};

export const getProductSections = async (): Promise<ProductSection[]> => {
  const user = store.getState().app.user?.tag ?? "avatarMaya";
  return await mockApi<ProductSection[]>(
    mockProductSectionsByUser[user as "avatarMaya" | "avatarViki"],
    3000
  );
};

export const getSimilarProductFor = (product: MiniProduct, limit?: number) => {
  if (!product.tags) return null;
  return allMockProducts
    .filter((mock) => product.tags?.every((tag) => mock.tags?.includes(tag)))
    .slice(0, limit ?? -1);
};

export const getMatchingItemsFor = (product: MiniProduct, limit?: number) => {
  if (product.tags && product.tags.includes("cargo"))
    return MockMatchingCargoPants.slice(0, limit ?? -1);
};
