import { FC } from "react";
import { BaseStyleProfileStep } from "../../BaseStyleProfileStep";
import { BrandSearchAndRate } from "../components/brandSearchAndRate/BrandSearchAndRate";
import { isEmpty, values } from "lodash";
import { BaseStyleScenarioStep } from "./BaseStyleScenarioStep";

export class WorkStyleScenarioType extends BaseStyleScenarioStep {
  name = "WorkScenarioStep";
  constructor() {
    super();
  }

  getAdditionalProps = () => ({
    options: [
      {
        imgUrl:
          "https://strongful.co.il/cdn/shop/files/STRO4085.jpg?v=1689842655&width=990",
        id: "1",
        title: "",
      },
      {
        imgUrl:
          "https://guitamoda.com/wp-content/uploads/2021/04/606f1558c0e94.jpg",
        id: "3",
        title: "",
      },
      {
        imgUrl: "https://is4.revolveassets.com/images/p4/n/z/LBLR-WK13_V1.jpg",
        id: "4",
        title: "",
      },
      {
        imgUrl:
          "https://omaisawoman.com/cdn/shop/products/31604518_1.jpg?v=1663140365",
        id: "5",
        title: "",
      },
      {
        imgUrl:
          "https://adikastyle.com/cdn/shop/files/adika31.70472_b2ce24e4-7f84-45e1-85a7-ad4038d5c3c2_900x.jpg?v=1692612924",
        id: "6",
        title: "",
      },
      {
        imgUrl:
          "https://seestarz.com/cdn/shop/products/ZOAR2826_39f9f169-343b-4e9f-9542-1f25e387ebb9.jpg?v=1666196444&width=1080",
        id: "7",
        title: "",
      },
    ],
    prompt:
      "You are going to an important meeting with a client. Which style you like the most",
  });
}
