import { Box, ModalOwnProps, Modal as MUIModal } from "@mui/material";
import React from "react";

interface ModalProps extends ModalOwnProps {
  width?: string;
  height?: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#ffff",
  boxShadow: 24,
  borderRadius: "4px",
  height: "90vh",
  width: "90vw",
  p: 4,
  display: "flex",
  overflow: "hidden",
  color: "#ffff",
};

export const Modal: React.FC<ModalProps> = (props) => {
  return (
    <MUIModal {...props}>
      <Box
        sx={{
          ...style,
          width: props.width ?? style.width,
          height: props.height ?? style.height,
        }}
      >
        {props.children}
      </Box>
    </MUIModal>
  );
};
