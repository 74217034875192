import { TextareaAutosize } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Socket } from "socket.io-client";
import { SocketWrapper } from "../../../services/sockets/socket";
import { useAppSelector } from "../../../store";

export const InstagramApp = () => {
  const { user, didLoadHome, deviceId } = useAppSelector((store) => store.app);
  const [message, setMessage] = useState<string>();
  const socket = useRef<Socket | null>(null);

  useEffect(() => {
    socket.current = new SocketWrapper(deviceId ?? user?._id!).socket;
    socket.current.on("webhookMessage", (data) => {
      setMessage(JSON.stringify(data));
    });
  }, [user]);

  return (
    <div style={{ width: "100%" }}>
      <TextareaAutosize
        minRows={30}
        style={{ width: "100%" }}
        value={message}
      />
    </div>
  );
};
