import React, { ReactNode } from "react";
import Media from "react-media";
import { MediaQueries } from "../../styles/consts";

const MediaWrapper: React.FC<{ children: ReactNode; media: string }> = ({
  children,
  media,
}) => {
  return (
    <Media query={media}>
      {(matches) => {
        return matches ? children : <></>;
      }}
    </Media>
  );
};

export const MobileMedia: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return <MediaWrapper media={MediaQueries.mobile}>{children}</MediaWrapper>;
};
export const NotMobileMedia: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return <MediaWrapper media={MediaQueries.notMobile}>{children}</MediaWrapper>;
};
export const NarrowMedia: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return <MediaWrapper media={MediaQueries.narrow}>{children}</MediaWrapper>;
};
export const TouchScreenMedia: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <MediaWrapper media={MediaQueries.upToDesktop}>{children}</MediaWrapper>
  );
};
export const DesktopMedia: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return <MediaWrapper media={MediaQueries.desktop}>{children}</MediaWrapper>;
};
export const NotTouchScreenMedia: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return <MediaWrapper media={MediaQueries.notNarrow}>{children}</MediaWrapper>;
};
