import React, { useState } from "react";
import s from "./ChatList.module.scss";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import { ReactComponent as Gpt } from "../../../../styles/assets/gpt.svg";
import { getChatList } from "../../../../services/api/chat/chat";
import { useQuery } from "react-query";

export const ChatList: React.FC = () => {
  const [selectedChatIdx, setSelectedChatIdx] = useState(0);
  const { data: chatList, isLoading: isLoadingChats } = useQuery({
    queryKey: ["chatList"],
    queryFn: () => getChatList(),
  });
  return (
    <div className={s.history}>
      <div className={s.chatInstance}>
        <Gpt width={20} height={20} />
        new chat
      </div>
      <div className={s.chatInstance}>
        <ExploreOutlinedIcon fontSize="small" />
        Explore
      </div>
      <div className={s.historyTitle}>history</div>
      {["chat 1", "chat 21", "chat 5"].map((chat) => (
        <div className={s.chatInstance}>{chat}</div>
      ))}
    </div>
  );
};
