import { Skeleton } from "@mui/material";

const COLOR = "#e4e4e4";

export const ProductSkeleton: React.FC<{
  repeat: number;
  sumVersion?: boolean;
}> = ({ repeat = 1, sumVersion }) => {
  return (
    <>
      {[...new Array(repeat)].map((_) => (
        <div id="productCard" className="flex column gapSm">
          <Skeleton
            variant="rectangular"
            style={{
              aspectRatio: "11/18",
              width: "100%",
              borderRadius: "15px",
              height: "auto",
              backgroundColor: COLOR,
            }}
          />
          {!sumVersion && (
            <>
              <Skeleton
                variant="text"
                style={{ width: "38%", backgroundColor: COLOR }}
              />
              <Skeleton
                variant="text"
                style={{ width: "50%", backgroundColor: COLOR }}
              />
              <Skeleton
                variant="text"
                style={{ width: "44%", backgroundColor: COLOR }}
              />
            </>
          )}
        </div>
      ))}
    </>
  );
};
