import { ArrowForwardRounded, Close } from "@mui/icons-material";
import { produce } from "immer";
import { debounce, isEmpty, noop } from "lodash";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { Link, useSearchParams } from "react-router-dom";
import { Chat as ChatType } from "../../@types/entities/chat";
import { ProductDetails } from "../../@types/entities/product.types";
import { AppTextField } from "../../components/Input/TextField/TextField";
import { Button } from "../../components/button/Button";
import {
  IntersectionObserverCmp,
  SentinalForObserver,
} from "../../components/intersector/Intersector";
import { DesktopMedia } from "../../components/media/MediaQueries";
import { WithPage } from "../../components/withPage/WithPage";
import { useJoinWaitingList } from "../../hooks/system/useLogin";
import { wait } from "../../services/api/utils";
import { ReactComponent as AppleAppStore } from "../../styles/assets/app-store-badge.svg";
import { ReactComponent as Logo } from "../../styles/icons/logo.svg";
import { Chat } from "../chat/Cmps/Chat/Chat";
import styles from "./LandingPage.module.scss";
import { SearchContextProductThumbnail, SearchStep } from "./landingV2";

export const LandingPage: React.FC = () => {
  const pageRef = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();
  const [isJoinScreen, setIsJoinScreen] = useState(false);
  const currentIndex = useRef(0);
  const prevScroll = useRef(0);

  // useEffect(() => {
  //   window.addEventListener("scroll", (ev) => {
  //     handleScroll(ev);
  //   });
  // }, []);
  // const sections = document.querySelectorAll(".landing-page-section");

  const handleScroll = useCallback(
    debounce((ev) => {
      console.log("scroll");

      // prevScroll.current = currentScroll <= 0 ? 0 : currentScroll;
    }, 150),
    []
  );

  return (
    <>
      <JoinScreen
        onClose={() => setIsJoinScreen(false)}
        isOpen={isJoinScreen}
        onOpen={() => setIsJoinScreen(true)}
      />
      <WithPage>
        <>
          <DesktopMedia>
            <div
              className={`${styles.headerContainer} ${
                searchParams.get("app") === "true" ? styles.mobileApp : ""
              }`}
            >
              <div className={styles.header}>
                <div className={`flex1 alignCenter gapMd`}>
                  <Logo />
                </div>

                <div>Show Off</div>
                <div className="flex1 justifyEnd">
                  <Button
                    size="lg"
                    onClick={() => {
                      console.log("Click");

                      setIsJoinScreen(true);
                    }}
                  >
                    Sign up
                  </Button>
                </div>
              </div>
            </div>
          </DesktopMedia>

          <div
            ref={pageRef}
            style={{
              height: "100dvh",
              overflow: "auto",
              scrollSnapType: "y mandatory",
              scrollSnapStop: "always",
            }}
          >
            <div className={`${styles.homeOpener} landing-page-section`}>
              <video
                disablePictureInPicture
                disableRemotePlayback
                playsInline
                autoPlay
                muted
                loop
                className={styles.bgVideo}
              >
                <source
                  src={
                    "https://storage.googleapis.com/gigi_pics_public/Picture%20moving.mp4"
                  }
                  type="video/mp4"
                />
              </video>

              <div className={styles.textContainer}>
                <div className="column gapXlg">
                  <div className={styles.title}>
                    Your Personal
                    <br />
                    Fashion Shopper
                  </div>
                  <div className={styles.bottomContent}>
                    <p className={styles.subtitle}>
                      Find products you'll love from all over the web.
                      <br />
                      Tailored to your unique style.
                    </p>
                    <div className={styles.actionBtns}>
                      {/* <Link to={`/feed?${searchParams.toString()}`}> */}
                      {/* <AppTextField
                        fullWidth
                        placeholder="fashionista@gmail.com"
                        value={email}
                        onChange={(ev) => setEmail(ev.target.value)}
                      /> */}
                      <Button size="lg" onClick={() => setIsJoinScreen(true)}>
                        <div className="gapMd alignCenter">
                          Sign up
                          <ArrowForwardRounded />
                        </div>
                      </Button>
                    </div>

                    {/* <Link to={`/login?${searchParams.toString()}`}>
                    <div className={`${styles.actionBtn} ${styles.secondary}`}>
                      Sign up
                    </div>
                  </Link> */}
                  </div>
                </div>
              </div>

              {/* <div
                style={{ paddingBottom: "25px" }}
                className="flex1 column justifyEnd"
              >
                {!searchParams.get("deviceId") && (
                  <Link
                    to="https://apps.apple.com/us/app/stili/id6499213024"
                    target="_blank"
                  >
                    <div
                      style={{ width: "150px", height: "60px" }}
                      className="justifyCenter alignCenter"
                    >
                      <AppleAppStore width={"100%"} />
                    </div>
                  </Link>
                )}
              </div> */}
            </div>

            <ChatSection />
            <ContextualSearchSection />
            <CelebrityLookalikeSection />
            <ReviewsSection />
            <Footer onClickSignUp={() => setIsJoinScreen(true)} />
          </div>
        </>
      </WithPage>
    </>
  );
};

const CommonSectionContainer: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  // const { onOpen, isOpen, onClose, data } = useDelayedClose();
  const ref = useRef<HTMLElement>(null);
  return (
    <div className={styles.sectionContainer}>
      <section
        id="section1"
        ref={ref}
        className={`${styles.section} landing-page-section`}
      >
        <IntersectionObserverCmp
          isInView={() => {
            // onOpen(1);
            ref.current?.classList.add(styles.open);
            // ref.current?.scrollIntoView({
            //   behavior: "smooth",

            //   inline: "nearest",
            // });
          }}
          isOutsideView={() => {
            // onClose();
            // ref.current?.classList.remove(styles.open);
          }}
        >
          <SentinalForObserver />
        </IntersectionObserverCmp>
        {children}
      </section>
    </div>
  );
};

const ChatSection = () => {
  const [messages, setMessages] = useState<ChatType["messages"]>([]);
  const mockMessages: ChatType["messages"] = [
    {
      content: "I need a dress for a beach party in mykonos",
      sender: "user",
    },
    {
      content:
        "Yasou!🇬🇷 Because you like clean and elegant styles, this is what i found for you",
      sender: "chat",
      data: {
        products: [
          {
            imgUrls: [
              "https://storage.googleapis.com/gigi_pics_public/64492_20WHITE-1_large.jpg",
            ],
            brand: "",
            price: 0,
          },
          {
            imgUrls: [
              "https://storage.googleapis.com/gigi_pics_public/20230209_HM-1409_1677716323_520x.jpg",
            ],
            brand: "",
            price: 0,
          },
          {
            imgUrls: [
              "https://storage.googleapis.com/gigi_pics_public/medium_albus-lumen-white-cloud-layered-silk-chiffon-gown-2.jpg",
            ],
            brand: "",
            price: 0,
          },
          {
            imgUrls: [
              "https://storage.googleapis.com/gigi_pics_public/medium_maygel-coronel-blue-surf-exclusive-lapiere-dress.jpg",
            ],
            brand: "",
            price: 0,
          },
        ] as ProductDetails[],
      },
    },
    { sender: "user", content: "I want it more flirty and wild" },
    {
      sender: "chat",
      content: "You got it! adding a flirty touch to your results🌶️",
      data: {
        products: [
          {
            imgUrls: [
              "https://storage.googleapis.com/gigi_pics_public/HelloMolly4835_1687824208_81a90727-1c06-46f6-8dd8-e6bd139cedb9_1800x1800.jpg",
            ],
            brand: "",
            price: 0,
          },
          {
            imgUrls: [
              "https://storage.googleapis.com/gigi_pics_public/BBAB-WD111_V1.avif",
            ],
            brand: "",
            price: 0,
          },
          {
            imgUrls: [
              "https://storage.googleapis.com/gigi_pics_public/P00912246_b1.avif",
            ],
            brand: "",
            price: 0,
          },
          {
            imgUrls: [
              "https://storage.googleapis.com/gigi_pics_public/MELR-WD646_V1.avif",
            ],
            brand: "",
            price: 0,
          },
        ] as ProductDetails[],
      },
    },
  ];
  useEffect(() => {
    let cancel = false;

    (async () => {
      while (true) {
        setMessages([]);
        for (const message of mockMessages) {
          await wait(2000);
          if (cancel) return;
          setMessages(
            produce((draft) => {
              draft.push({ sender: message.sender, content: message.content });
            })
          );
          if (message.sender === "chat") {
            await wait(1300);
            setMessages(
              produce((draft) => {
                draft[draft.length - 1] = message;
              })
            );
          }
        }
        await wait(2000);
      }
    })();

    return () => {
      cancel = true;
    };
  }, []);
  return (
    <CommonSectionContainer>
      <div className={styles.sectionTitle}>
        <h2>Meet Your Fashion Concierge</h2>
        <p>
          Discover, refine, and explore the perfect styles tailored just for
          you. Your unique fashion journey starts here.
        </p>
      </div>

      {/* <img src={personalSrc} alt="chat" /> */}
      <div
        style={{ minWidth: "100%", maxWidth: "100%", alignItems: "center" }}
        className="column flex1 relative"
      >
        <div className={styles.chatContainer}>
          <div className={styles.chat}>
            <Chat
              chatId="intro"
              goToResults={noop}
              type="gpt"
              chat={{
                id: "",
                messages: isEmpty(messages)
                  ? [{ sender: "user", content: "" }]
                  : messages,
                lastUpdate: 0,
                title: "",
                createdAt: 0,
              }}
              isLoading={false}
            />
          </div>
        </div>
      </div>
    </CommonSectionContainer>
  );
};

const ContextualSearchSection = () => {
  return (
    <CommonSectionContainer>
      <div
        style={{
          height: "700px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "30px",
          width: "100%",
        }}
      >
        <h2>Search Without Limits</h2>

        <div
          className={styles.searchStep}
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            position: "relative",
            width: "100%",
          }}
        >
          <SearchStep
            styles={{
              productClassName: styles.overrideProductSearchContext,
              searchClassName: styles.overrideSearchPhrase,
              blinkerClassName: styles.blinkerOverride,
            }}
          />
        </div>
        <div className="flex1" />
      </div>
    </CommonSectionContainer>
  );
};

const lookalikes = [
  {
    mainImg:
      "https://storage.googleapis.com/gigi_pics_public/haileybieber_1721945398292_b323122e-b01d-4519-87cc-d54806a1ac0b%20(1).jpeg",
    lookalikes: [
      {
        src: "https://storage.googleapis.com/gigi_pics_public/medium_attico-black-leather-midi-dress.jpg",
        brand: "The Attico",
        price: 1445,
      },
      {
        src: "https://storage.googleapis.com/gigi_pics_public/01273311-01.webp",
        brand: "Steve Madden",
        price: 99,
      },
      {
        src: "https://storage.googleapis.com/gigi_pics_public/205421369-1-black.webp",
        brand: "Forever 21",
        price: 29,
      },
    ],
  },
  {
    mainImg:
      "https://storage.googleapis.com/gigi_pics_public/Screenshot%202024-08-01%20at%204.28.59%E2%80%AFPM.png",
    lookalikes: [
      {
        src: "https://storage.googleapis.com/gigi_pics_public/1314260.1%20(1).webp",
        brand: "Reformation",
        price: 430,
      },
      {
        src: "https://storage.googleapis.com/gigi_pics_public/danij4115412000_1639071889740_2-0%20(1).jpg",
        brand: "Here Comes The Sun",
        price: 79.99,
      },
      {
        src: "https://storage.googleapis.com/gigi_pics_public/CM1380B_BRONZE-1_large%20(1).jpg",
        brand: "Dannijo",
        price: 375,
      },
    ],
  },
  {
    mainImg:
      "https://storage.googleapis.com/lookalike_imgs/margotrobbie_1722208306796_fc5af81a-c906-44cf-9662-81091612790f",
    lookalikes: [
      {
        src: "https://storage.googleapis.com/gigi_pics_public/MELR-WD893_V1%20(1).avif",
        brand: "Michael Costello",
        price: 235,
      },
      {
        src: "https://storage.googleapis.com/gigi_pics_public/1-modelinfo-hailey-us4_26edd05d-4837-480d-8993-e601a56e687b_450x610%20(1).jpg",
        brand: "Hello Molly",
        price: 75,
      },
      {
        src: "https://storage.googleapis.com/gigi_pics_public/L8A3468_1657082984_10c883e8-f17d-4bd5-93b6-067656453621_1800x1800%20(1).jpg",
        brand: "Princess Polly",
        price: 70,
      },
    ],
  },
];
export const CelebrityLookalikeSection = () => {
  const [lookalike, setLookalike] = useState(lookalikes[0]);

  useEffect(() => {
    let intervalId: NodeJS.Timer;
    let index = 0;
    intervalId = setInterval(() => {
      if (index > lookalikes.length - 1) index = 0;
      setLookalike(lookalikes[index]);
      index++;
    }, 4000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
  return (
    <CommonSectionContainer>
      <div className={styles.sectionTitle}>
        <h2>Get inspired by the stars</h2>
      </div>
      <p style={{ marginBottom: "32px" }}>
        Follow Celebrities, Instagram influencers, and pop culture leaders to
        find the latest trends in fashion.{" "}
      </p>
      <div style={{ width: "100%" }} className="flex1 column alignCenter">
        <div className={styles.celebsLookalikesImgsContainer}>
          <div className={styles.celebsMainImgContainer}>
            <img
              src={lookalike.mainImg}
              alt=""
              className={styles.celebsMainImg}
            />
          </div>
          <div
            style={{
              width: "400px",
              display: "flex",
              flexWrap: "wrap",
              gap: "16px",
              justifyContent: "center",
            }}
            className="gapLg"
          >
            {lookalike.lookalikes.map((l, idx) => {
              return (
                <SearchContextProductThumbnail
                  productClassName={styles.overrideProductSearchContext}
                  loc={idx}
                  item={{
                    src: l.src,
                    brand: l.brand,
                    price: l.price,
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </CommonSectionContainer>
  );
};

export const ReviewsSection = () => {
  return (
    <div
      className={`${styles.reviews} ${styles.sectionContainer} landing-page-section`}
    >
      <h2>What our users say</h2>
      <div className={styles.reviewsContainer}>
        <div className={styles.overlay} />
        <div className={styles.columns}>
          {reviews
            .map((r) => ({
              review: r.review,
              img:
                r.img ??
                "https://storage.googleapis.com/gigi_pics_public/Ellipse%20283.png",
              name: r.name ?? "Neta",
              info: r.info ?? "26,Miami",
            }))
            .map((r) => (
              <div className={styles.singleReview}>
                <div className={styles.user}>
                  <img
                    src={r.img}
                    width="80px"
                    height="80px"
                    style={{ borderRadius: "50%", objectFit: "cover" }}
                  />
                  <div className={styles.userInfo}>
                    <span>{r.name}</span>
                    <span className={styles.info}>{r.info}</span>
                  </div>
                </div>
                <div>{r.review}</div>
              </div>
            ))}
        </div>
      </div>
      <div className={styles.overlay} />
    </div>
  );
};

const reviews = [
  {
    review:
      "The price comparison feature is a game-changer! I found the same dress for $50 less than what I saw on another site.",
    img: "https://storage.googleapis.com/gigi_pics_public/Screenshot%202024-08-01%20at%205.15.28%E2%80%AFPM.png",
    name: "Joanne",
    info: "34, New York NY",
  },

  {
    img: "https://storage.googleapis.com/gigi_pics_public/Screenshot%202024-08-01%20at%205.17.05%E2%80%AFPM.png",
    name: "Hailey",
    info: "26, London UK",
    review:
      "I was in a rush to find a dress for an event, and Gigi helped me pick the perfect one in minutes. It's like having a personal stylist in my pocket!",
  },

  {
    img: "https://storage.googleapis.com/gigi_pics_public/Screenshot%202024-08-01%20at%205.11.24%E2%80%AFPM.png",
    name: "Romi",
    info: "24, Chicago IL",
    review:
      "The personalization on this app is insane. It knows my style better than I do!",
  },

  {
    img: "https://storage.googleapis.com/gigi_pics_public/Screenshot%202024-08-01%20at%205.00.25%E2%80%AFPM.png",
    name: "Jennifer",
    info: "24,Los Angeles CA",
    review:
      "I discovered so many new brands that I absolutely love, all thanks to Gigi. It's opened up a whole new world of fashion for me.",
  },

  {
    img: "https://storage.googleapis.com/gigi_pics_public/Screenshot%202024-08-01%20at%204.59.50%E2%80%AFPM.png",
    name: "Dafne",
    info: "22, Austin TX",
    review:
      "The chat feature is amazing. It feels like I'm talking to someone who truly understands fashion. Finally, an app that gets me!",
  },

  {
    img: "https://storage.googleapis.com/gigi_pics_public/Screenshot%202024-08-01%20at%204.55.30%E2%80%AFPM.png",
    name: "Hailey",
    info: "29, Dallas TX",
    review:
      "I love how I can speak about fashion in my own words, and the app understands exactly what I want. It's like magic!",
  },

  {
    img: "https://storage.googleapis.com/gigi_pics_public/Screenshot%202024-08-01%20at%204.52.30%E2%80%AFPM.png",
    name: "Carly",
    info: "29, Miami FL",
    review:
      "The price comparison tool saved me so much money! I never shop without using Gigi now.",
  },

  {
    img: "https://storage.googleapis.com/gigi_pics_public/Screenshot%202024-08-01%20at%204.50.28%E2%80%AFPM.png",
    name: "Samantha",
    info: "27, New York NY",
    review:
      "Finding a dress quickly has never been easier. Gigi narrows down the options so fast, it’s unbelievable!",
  },

  {
    img: "https://storage.googleapis.com/gigi_pics_public/Screenshot%202024-08-01%20at%204.50.45%E2%80%AFPM.png",
    name: "Paige",
    info: "22, Long Island NY",
    review:
      "The level of personalization is out of this world. Gigi recommends outfits that are spot-on every time.",
  },

  {
    img: "https://storage.googleapis.com/gigi_pics_public/Screenshot%202024-08-01%20at%204.49.48%E2%80%AFPM.png",
    name: "Rachel",
    info: "27,New york",
    review:
      "I never knew there were so many cool indie designers out there until I started using Gigi. It's like a fashion treasure hunt!",
  },

  {
    img: "https://storage.googleapis.com/gigi_pics_public/Screenshot%202024-08-01%20at%204.51.09%E2%80%AFPM.png",
    name: "Noa",
    info: "28, Tel Aviv IL",
    review:
      "The chat feature is so intuitive. I can describe what I’m looking for, and it just gets it. Shopping has never been this easy.",
  },

  {
    img: "https://storage.googleapis.com/gigi_pics_public/Screenshot%202024-08-01%20at%204.51.21%E2%80%AFPM.png",
    name: "Arielle",
    info: "29, Miami FL",
    review:
      "Talking about fashion has never been this satisfying. Gigi understands exactly what I'm envisioning.",
  },

  {
    img: "https://storage.googleapis.com/gigi_pics_public/Screenshot%202024-08-01%20at%204.51.33%E2%80%AFPM.png",
    name: "Tania",
    info: "30, Boston",
    review:
      "This app has the best price comparison feature I've seen. I love saving money while finding the perfect outfit.",
  },

  {
    img: "https://storage.googleapis.com/gigi_pics_public/Screenshot%202024-08-01%20at%204.51.52%E2%80%AFPM.png",
    name: "Emily",
    info: "30, Cleveland OH",
    review:
      "Gigi helped me find a dress for a wedding in under 5 minutes. I'm impressed!",
  },

  {
    img: "https://storage.googleapis.com/gigi_pics_public/Screenshot%202024-08-01%20at%204.52.04%E2%80%AFPM.png",
    name: "Olivia",
    info: "19, San Diego CA",
    review:
      "The personalization is next level. It's like the app was made just for me!",
  },

  {
    img: "https://storage.googleapis.com/gigi_pics_public/Screenshot%202024-08-01%20at%204.52.16%E2%80%AFPM.png",
    name: "Sandra",
    info: "30, Los Angeles CA",
    review:
      "I’ve discovered so many unique brands that I wouldn't have found on my own. Gigi is a must-have for any fashion lover.",
  },

  {
    img: "https://storage.googleapis.com/gigi_pics_public/Screenshot%202024-08-01%20at%205.01.03%E2%80%AFPM.png",
    name: "Shelly",
    info: "25, San Diego CA",
    review:
      "The chat just gets what I mean every single time. It’s like having a best friend who’s a fashion expert.",
  },

  // {
  //   img: "https://storage.googleapis.com/gigi_pics_public/Screenshot%202024-08-01%20at%205.17.05%E2%80%AFPM.png",
  //   name: undefined,
  //   info: undefined,
  //   review:
  //     "Finally, a platform where I can talk fashion and actually be understood. Gigi nails it every time.",
  // },

  // {
  //   img: "https://storage.googleapis.com/gigi_pics_public/Screenshot%202024-08-01%20at%205.11.24%E2%80%AFPM.png",
  //   name: undefined,
  //   info: undefined,
  //   review:
  //     "I love how Gigi finds the best prices for the same dress across different sites. It’s a budget-friendly shopper’s dream.",
  // },

  // {
  //   img: "https://storage.googleapis.com/gigi_pics_public/Screenshot%202024-08-01%20at%205.00.25%E2%80%AFPM.png",
  //   name: undefined,
  //   info: undefined,
  //   review:
  //     "I needed a dress for a last-minute event, and Gigi delivered. Quick, stylish, and exactly what I wanted.",
  // },

  // {
  //   img: "https://storage.googleapis.com/gigi_pics_public/Screenshot%202024-08-01%20at%205.01.03%E2%80%AFPM.png",
  //   name: undefined,
  //   info: undefined,
  //   review:
  //     "The way Gigi personalizes recommendations is mind-blowing. I don’t know how I ever shopped without it.",
  // },

  // {
  //   img: "https://storage.googleapis.com/gigi_pics_public/Screenshot%202024-08-01%20at%204.59.50%E2%80%AFPM.png",
  //   name: undefined,
  //   info: undefined,
  //   review:
  //     "I’ve found so many new favorite brands through Gigi. It’s like my personal fashion scout.",
  // },

  // {
  //   img: "https://storage.googleapis.com/gigi_pics_public/Screenshot%202024-08-01%20at%204.55.30%E2%80%AFPM.png",
  //   name: undefined,
  //   info: undefined,
  //   review:
  //     "The chat feature is incredible. I describe what I’m thinking, and Gigi shows me exactly what I need.",
  // },

  // {
  //   img: "https://storage.googleapis.com/gigi_pics_public/Screenshot%202024-08-01%20at%204.52.30%E2%80%AFPM.png",
  //   name: undefined,
  //   info: undefined,
  //   review:
  //     "I can finally talk about my style and have someone (or something) actually understand me. Gigi is a fashionista’s dream!",
  // },

  // {
  //   img: "https://storage.googleapis.com/gigi_pics_public/Screenshot%202024-08-01%20at%204.50.28%E2%80%AFPM.png",
  //   name: undefined,
  //   info: undefined,
  //   review:
  //     "Price comparison on Gigi is top-notch. I’m saving money and still getting the best looks.",
  // },

  // {
  //   img: "https://storage.googleapis.com/gigi_pics_public/Screenshot%202024-08-01%20at%204.50.45%E2%80%AFPM.png",
  //   name: undefined,
  //   info: undefined,
  //   review:
  //     "Finding a dress for any occasion is so fast with Gigi. I’m in and out with exactly what I need.",
  // },

  // {
  //   img: "https://storage.googleapis.com/gigi_pics_public/Screenshot%202024-08-01%20at%204.49.48%E2%80%AFPM.png",
  //   name: undefined,
  //   info: undefined,
  //   review:
  //     "Gigi’s personalization is spot-on. Every outfit recommendation feels tailor-made for me.",
  // },

  // {
  //   img: "https://storage.googleapis.com/gigi_pics_public/Screenshot%202024-08-01%20at%204.51.09%E2%80%AFPM.png",
  //   name: undefined,
  //   info: undefined,
  //   review:
  //     "Brand discovery on Gigi is fantastic. I’ve added so many new labels to my wardrobe!",
  // },

  // {
  //   img: "https://storage.googleapis.com/gigi_pics_public/Screenshot%202024-08-01%20at%204.51.21%E2%80%AFPM.png",
  //   name: undefined,
  //   info: undefined,
  //   review:
  //     "The chat is so smart. It’s like talking to someone who just gets fashion at its core.",
  // },

  // {
  //   img: "https://storage.googleapis.com/gigi_pics_public/Screenshot%202024-08-01%20at%204.51.33%E2%80%AFPM.png",
  //   name: undefined,
  //   info: undefined,
  //   review:
  //     "Finally, an app that understands my fashion needs without me having to explain too much. Gigi is my new go-to",
  // },
];

const Footer: React.FC<{ onClickSignUp: () => void }> = ({ onClickSignUp }) => {
  return (
    <div className={styles.sectionContainer}>
      <div
        style={{ padding: "80px", width: "100%" }}
        className="flex alignCenter spaceBetween"
      >
        <Logo height={50} width={100} />

        <Button size="lg" onClick={onClickSignUp}>
          Sign Up
        </Button>
      </div>
    </div>
  );
};

const JoinScreen: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  initialType?: "sign up" | "download app";
  onOpen: () => void;
}> = ({ onOpen, isOpen, onClose, initialType = "sign up" }) => {
  const [type, setType] = useState(initialType);
  const [email, setEmail] = useState("");
  const {
    mutateAsync: joinWaitingList,
    isLoading,
    isError,
  } = useJoinWaitingList();

  const onSubmit = async () => {
    try {
      await joinWaitingList(email);
      setType("download app");
    } catch (error) {}
  };

  return (
    <>
      <div className={`${styles.joinView} ${isOpen ? styles.open : ""}`}>
        <div className="paddingLg alignCenter justifyEnd">
          <Close onClick={() => onClose()} />
        </div>
        {type === "sign up" ? (
          <CommonJoinScreenContainer title="Join the waiting list">
            <div style={{ width: "380px" }} className="column gapXlg ">
              <div className="column gapMd">
                <span style={{ fontWeight: 600 }} className="inputLabel">
                  Email
                </span>
                <AppTextField
                  placeholder="fashionista@gmail.com"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {isError && <span>Please enter a valid email</span>}
              </div>
              <Button
                isLoading={isLoading}
                onClick={() => onSubmit()}
                size="lg"
              >
                Sign up
              </Button>
            </div>
          </CommonJoinScreenContainer>
        ) : (
          <div
            style={{ textAlign: "center" }}
            className="column gapXlg alignCenter"
          >
            <h2 style={{ lineHeight: 1.6 }}>
              Thank you for signing up.
              <br /> Our app is available for beta.
              <br /> Check us out
            </h2>

            <Link
              to="https://apps.apple.com/us/app/stili/id6499213024"
              target="_blank"
            >
              <div
                style={{ width: "150px", height: "60px" }}
                className="justifyCenter alignCenter"
              >
                <AppleAppStore width={"100%"} />
              </div>
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

const CommonJoinScreenContainer: React.FC<{
  title: string;
  subtitle?: string;
  children: ReactNode;
}> = ({ title, subtitle, children }) => {
  return (
    <div
      style={{ paddingBottom: "180px" }}
      className="flex1 column justifyCenter alignCenter gapXlg"
    >
      {" "}
      <h2
        style={{
          textAlign: "center",
          fontSize: "36px",
          marginBottom: "32px",
          lineHeight: 1.6,
        }}
      >
        {title}
      </h2>
      {children}
    </div>
  );
};
