import { ReactNode, RefObject, useCallback, useRef } from "react";

export const IntersectionObserverCmp: React.FC<{
  className?: string;
  intersectionClassName?: string;
  children: ReactNode;
  root?: RefObject<HTMLElement>;
  isInView?: () => void;
  isOutsideView?: () => void;
}> = ({
  isInView,
  isOutsideView,
  className = "flex",
  intersectionClassName = "flex",
  children,
  root,
}) => {
  const observer = useRef<IntersectionObserver>();
  const containerRef = useRef<HTMLDivElement>(null);
  const intersectionRef = useCallback(
    (node: HTMLElement | null) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(
        ([el]) => {
          if (!containerRef.current) return;

          if (!el.isIntersecting) {
            isOutsideView && isOutsideView();
            containerRef?.current.classList.add(
              intersectionClassName ?? "pinned"
            );
          } else {
            isInView && isInView();
            containerRef?.current.classList.remove(
              intersectionClassName ?? "pinned"
            );
          }
        },
        { threshold: 0 }
      );
      if (node) observer.current.observe(node);
    },
    [intersectionClassName, root?.current, containerRef.current]
  );

  return (
    <div ref={intersectionRef} className={className}>
      <div className={className} ref={containerRef}>
        {children}
      </div>
    </div>
  );
};

export const SentinalForObserver = () => {
  return <div />;
};
