import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { ViewProps } from "../../../../@types/commonUi";
import { BasicQuery } from "../../../../@types/query";
import { AppContext } from "../../../../App";
import { SlidingWindow } from "../../../../components/slidingWindow/SlidingWindow";
import { useAuth } from "../../../../hooks/system/useLogin";
import { useViewCaller } from "../../../../hooks/ui/useWindowCaller";
import { useAppSelector } from "../../../../store";
import { appActions } from "../../../../store/app/slice";
import { ReactComponent as Date } from "../../../../styles/icons/Journey.svg";
import { ReactComponent as Like } from "../../../../styles/icons/Like.svg";
import { ReactComponent as AiIcon } from "../../../../styles/icons/ai.svg";
import s from "./Menu.module.scss";

export const Menu: React.FC<ViewProps> = ({ onClose, isOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { onChat } = useContext(AppContext);
  const [callWindow] = useViewCaller();
  const [logout] = useAuth();
  const user = useAppSelector((s) => s.app.user);

  const goToResults = (
    payload: Pick<BasicQuery, "weights" | "term" | "filters">
  ) => {
    dispatch(appActions.setProductSearchPayload(payload));
    navigate("/search");
  };

  return (
    <SlidingWindow
      onClose={onClose}
      isOpen={isOpen}
      direction="left"
      width="75%"
    >
      <div className={s.menu}>
        <h2 className="paddingLg">{user?.name}</h2>
        {[
          {
            content: (
              <>
                <AiIcon />
                Chat with Gigi
              </>
            ),
            onClick: onChat,
          },
          {
            content: (
              <>
                <Like />
                My collections
              </>
            ),
            onClick: () => navigate("/lists"),
          },
          {
            content: (
              <>
                <Date />
                Find a dress for an event
              </>
            ),
            onClick: () => callWindow({ id: "eventWizard", data: undefined }),
          },
          { content: "|" },
          {
            content: "Formal dresses",
            onClick: () =>
              goToResults({
                term: "Formal dresses",
                filters: [{ key: "tags", value: ["formal"] }],
              }),
          },
          {
            content: "Casual dresses",
            onClick: () =>
              goToResults({
                term: "Casual dresses",
                filters: [{ key: "tags", value: ["casual", "semi-formal"] }],
              }),
          },
          {
            content: "Sundresses",
            onClick: () =>
              goToResults({
                term: "sundresses",
                filters: [{ key: "tags", value: ["casual", "semi-formal"] }],
              }),
          },
          {
            content: "Maxi dresses",
            onClick: () =>
              goToResults({
                term: "maxi dresses",
              }),
          },
          {
            content: "Mini dresses",
            onClick: () =>
              goToResults({
                term: "Mini dresses",
              }),
          },
          {
            content: "Gowns",
            onClick: () =>
              goToResults({
                term: "Evening Gowns",
                filters: [{ key: "length", value: ["maxi", "floor"] }],
              }),
          },
          { content: "|" },
        ].map((item) =>
          item.content === "|" ? (
            <div
              style={{ borderTop: "thin solid #00000010", margin: "10px" }}
            />
          ) : (
            <div
              className={s.item}
              onClick={() => {
                item.onClick && item.onClick();
                onClose();
              }}
            >
              {item.content}
            </div>
          )
        )}

        <div
          onClick={async () => {
            logout();
          }}
          className={`${s.item} ${s.logout}`}
        >
          Log out
        </div>
      </div>
    </SlidingWindow>
  );
};
