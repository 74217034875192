import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { findIndex } from "lodash";
import { ReactNode, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { MiniProduct } from "../../@types/entities/product.types";
import { AppContext } from "../../App";
import { TextBanner } from "../../components/banner/text/TextBanner";
import { Button } from "../../components/button/Button";
import { Carousel } from "../../components/carousels/Carousel";
import { useHeaderActions } from "../../components/header/useHeaderActions";
import { SearchHeader } from "../../components/searchHeader/SearchHeader";
import { SlidingWindowData } from "../../components/slidingWindow/SlidingWindow";
import { ProductSlidingWindow } from "../../components/slidingWindow/types/product/ProductSlidingWindow";
import { useHomePage } from "../../hooks/query/home/useHomePage";
import { useViewCaller } from "../../hooks/ui/useWindowCaller";
import { socketService } from "../../services/mock/socket";
import { ReactComponent as Arrow } from "../../styles/icons/ArrowRight.svg";
import { LookalikeWrapper } from "../Lookalike/LookalikeProducts";
import s from "./HomePage.module.scss";
import { Menu } from "./cmps/menu/Menu";
import { BrandsView } from "./views/brands/BrandsView";
import { CategoriesView } from "./views/categories/Categories";
import { CelebsView } from "./views/celebs/CelebsView";
import { CollectionsView } from "./views/collections/CollectionsView";
import { SuggestedBrands } from "./views/home/BrandsPreview";
import { CollectionsPreview } from "./views/home/CollectionsPreview";
import { PersonalFeedPreview } from "./views/home/FeedPreview";
import { GigiPreview } from "./views/home/GigiPreview";
import { LookbooksPreview } from "./views/home/LookbooksPreview";
import { RecentlyViewed } from "./views/home/RecentlyViewed";
import { NewIn } from "./views/newIn/NewIn";
enum WindowNames {
  products = "products",
  menu = "menu",
}

export const HOME_BANNERS = [
  "https://creative.prf.hn/source/camref:1011l45XLD/creativeref:1011l94842",
  "https://creative.prf.hn/source/camref:1011l45XLD/creativeref:1100l99001",
  "https://creative.prf.hn/source/camref:1011l45XLD/creativeref:1101l102785",
];

export const HomePage: React.FC = () => {
  const [windowData, setWindowData] = useState<SlidingWindowData | null>(null);
  const [isSearch, setIsSearch] = useState(false);
  const { onSearch } = useHeaderActions();
  const searchRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { data, isLoading } = useHomePage();
  const [selectedPage, setSelectedPage] = useState<Page>("HOME");
  useEffect(() => {
    if (isSearch) {
      searchRef.current?.focus();
    }
  }, [isSearch]);

  const onSearchHeader = (term: string) => {
    onSearch(term);
  };

  return (
    <div style={{ paddingBottom: "96px" }} className="column flex1">
      <SearchHeader
        onSearch={onSearchHeader}
        onBack={() => {}}
        isSearchMode={false}
        withLogo
      />

      <NavigationBar
        selectedPage={selectedPage}
        onSelectPage={(p) => setSelectedPage(p)}
      />
      <Views
        view={selectedPage}
        onOpenView={(view: Page) => setSelectedPage(view)}
      />
    </div>
  );
};

const Views: React.FC<{ view: Page; onOpenView: (v: Page) => void }> = ({
  view,
  onOpenView,
}) => {
  switch (view) {
    case "BRANDS":
      return <BrandsView />;
    case "INSPO":
      return <CelebsView />;
    case "COLLECTIONS":
      return <CollectionsView />;
    case "CATEGORIES":
      return <CategoriesView />;
    case "HOME":
      return <HomeView onOpenView={onOpenView} />;
    default:
      return <></>;
  }
};

const HomeView: React.FC<{ onOpenView: (p: Page) => void }> = ({
  onOpenView,
}) => {
  const [windowData, setWindowData] = useState<SlidingWindowData | null>(null);
  const { data, isLoading } = useHomePage();
  const navigate = useNavigate();
  return (
    <div className="flex1 column">
      {/* <div>
        <Swiper
          slidesPerView={1}
          className={appStyles.swiper}
          modules={[Pagination]}
          pagination={{ enabled: true }}
          spaceBetween={8}
        >
          <SwiperSlide>
            <LookalikeBanner />
          </SwiperSlide>
          <SwiperSlide>
            <EventBanner />
          </SwiperSlide>
        </Swiper>
      </div> */}
      <Carousel
        items={HOME_BANNERS.map((src) => ({
          element: (
            <div
              style={{
                width: "100vw",
                aspectRatio: 4 / 5,
                position: "relative",
                display: "flex",
              }}
            >
              <img
                style={{ aspectRatio: 4 / 5, objectFit: "cover" }}
                src={src}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  height: "30%",
                  width: "100%",
                  background:
                    "linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.6))",
                }}
              >
                <div style={{ marginBottom: "24px" }} className="column gapLg">
                  <p
                    style={{
                      color: "white",
                      textAlign: "center",
                      margin: 0,
                      fontSize: "13px",
                      fontWeight: 300,
                    }}
                  >
                    Shop the collection
                  </p>
                  <h3 style={{ color: "white", textAlign: "center" }}>
                    Winter Finds
                  </h3>
                </div>
              </div>
            </div>
          ),
        }))}
      />
      <div className={s.feed}>
        <PersonalFeedPreview
          products={data?.personalFeed.items ?? []}
          onClickProduct={(p) => {
            socketService.emit("windowOpen", "product", p);
          }}
          onOpenView={() => navigate("/feed")}
        />
        <GigiPreview />
        <NewIn />

        <CollectionsPreview />
        <TextBanner
          bg="lavender"
          cta="Shop Sale"
          onClick={() => {}}
          text="SALE!"
          description="Shop Up to 75% off"
        />
        <LookbooksPreview />
        <SuggestedBrands
          onOpenView={() => onOpenView("BRANDS")}
          brands={data?.suggestedBrands.items ?? []}
        />
        {/* CATEGORIES */}
        <RecentlyViewed
          onClickProduct={(p) => socketService.emit("windowOpen", "product", p)}
          products={data?.recentlyViewed.items}
        />
      </div>
      <ProductSlidingWindow
        product={windowData?.data}
        isOpen={windowData?.name === WindowNames.products}
        onClose={() => setWindowData(null)}
        direction="right"
      />
      <Menu
        onClose={() => setWindowData(null)}
        isOpen={windowData?.name === WindowNames.menu}
      />
    </div>
  );
};

type Page =
  | "HOME"
  | "INSPO"
  | "COLLECTIONS"
  | "BRANDS"
  | "FEED"
  | "FORMAL"
  | "OFFICE"
  | "CASUAL"
  | "EVENTS"
  | "SALE"
  | "NEW"
  | "CATEGORIES";
const PAGE_CODES: Page[] = [
  "HOME",
  "INSPO",
  "BRANDS",
  "COLLECTIONS",
  "EVENTS",
  "CATEGORIES",
  "SALE",
  "NEW",
  // "FORMAL",
  // "OFFICE",
  // "CASUAL",
];

const NavigationBar: React.FC<{
  onSelectPage: (page: Page) => void;
  selectedPage: Page;
}> = ({ onSelectPage, selectedPage }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef<SwiperClass>();

  useEffect(() => {
    const idx = findIndex(PAGE_CODES, (p) => p === selectedPage);
    if (idx !== activeIndex) {
      setActiveIndex(idx);
      if (swiperRef.current) {
        swiperRef.current.slideToLoop(idx);
      }
    }
  }, [selectedPage]);

  const handleSlideChange = (swiper: SwiperClass) => {
    setActiveIndex(swiper.realIndex);
    onSelectPage(PAGE_CODES[swiper.realIndex]);
  };
  const handleSlideClick = (index: number) => {
    setActiveIndex(index);
    if (swiperRef.current) {
      swiperRef.current.slideToLoop(index);
    }
    onSelectPage(PAGE_CODES[index]);
  };
  return (
    <div>
      <div
        style={{ padding: "0px 16px  0px 16px" }}
        className="alignCenter gapLg "
      >
        <Swiper
          onSlideChange={handleSlideChange}
          centeredSlides={true}
          onSwiper={(swiper: SwiperClass) => (swiperRef.current = swiper)}
          loop
          slidesPerView={"auto"}
          spaceBetween={12}
        >
          {PAGE_CODES.map((t, index) => (
            <SwiperSlide
              style={{
                width: "auto",
                borderBottom: "3px solid transparent",
                borderColor: activeIndex === index ? "black" : "transparent",
              }}
              onClick={() => handleSlideClick(index)}
            >
              <div
                style={{
                  fontSize: "12px",
                  fontWeight: 400,
                  color: activeIndex === index ? "black" : "#a5a5a5",
                  marginBottom: "12px",
                }}
              >
                {t}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

const EventBanner = () => {
  const [callView] = useViewCaller();
  return (
    <GradientBackground>
      <div className={s.welcomeSection}>
        <h1
          style={{
            fontSize: "18px",
            fontWeight: 400,
            textAlign: "center",
            lineHeight: 1.5,
          }}
        >
          HAVE AN EVENT? <br /> LET GIGI HELP YOU OUT
        </h1>
        <div className="justifyCenter">
          <Button
            onClick={() => callView({ id: "eventWizard", data: undefined })}
          >
            Find a dress
          </Button>
        </div>
      </div>
    </GradientBackground>
  );
};

const LookalikeBanner = () => {
  const [callView] = useViewCaller();
  return (
    <GradientBackground>
      <div className={s.welcomeSection}>
        <h1
          style={{
            fontSize: "18px",
            fontWeight: 400,
            textAlign: "center",
            lineHeight: 1.5,
          }}
        >
          HAVE A STYLE IN MIND? <br />
          FIND LOOKALIKE PRODUCTS
        </h1>
        <div className="flex justifyCenter alignCenter">
          <div className="relative">
            <div>
              <Button onClick={() => {}}>
                <div className=" alignCenter gapMd">
                  <CameraAltOutlinedIcon sx={{ color: "white" }} />
                  Find my style
                </div>
              </Button>
            </div>
            <LookalikeWrapper
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            >
              <></>
            </LookalikeWrapper>
          </div>
        </div>
      </div>
    </GradientBackground>
  );
};

export const TrendingSearches: React.FC<{
  terms: string[];
  onClickProduct: (p: MiniProduct) => void;
}> = ({ terms, onClickProduct }) => {
  return (
    <div className={s.feedTitleContainer}>
      <div>
        <div className={s.feedTitle}>Trending Searches</div>
      </div>
      <Arrow />
    </div>
  );
};
export const GradientBackground: React.FC<{
  children: ReactNode | ReactNode[];
}> = ({ children }) => {
  const { isMobile } = useContext(AppContext);

  return <div className={`${s.welcomeContainer}`}>{children}</div>;
};
