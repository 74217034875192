import { useCallback, useRef } from "react";

interface InfiniteScrollerProps {
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  bottom?: number;
}

export const InfiniteScroller: React.FC<InfiniteScrollerProps> = ({
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
  bottom,
}) => {
  console.log({ isFetchingNextPage, hasNextPage });

  const observer = useRef<IntersectionObserver>();
  const loadMoreRef = useCallback(
    (node: HTMLElement | null) => {
      if (isFetchingNextPage) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          console.log("Here");

          fetchNextPage();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage]
  );

  return (
    <div
      // style={bottom ? { position: "absolute", bottom: bottom } : {}}
      ref={loadMoreRef}
    />
  );
};
