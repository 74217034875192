import { FC, HTMLAttributes, ReactNode } from "react";
import { Loader } from "../loader/Loader";
import styles from "./button.module.scss";

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  onClick: () => void;
  type?: "secondary" | "primary" | "tertiary" | "cta";
  theme?: "light" | "dark";
  icon?: string;
  disabled?: boolean;
  isLoading?: boolean;
  children: ReactNode;
  form?: boolean;
  className?: string;
  size?: "md" | "lg";
}
export const Button: FC<ButtonProps> = ({
  type = "primary",
  disabled,
  onClick,
  children,
  theme = "dark",
  isLoading,
  form,
  size = "md",
  className,
  ...props
}) => {
  return (
    <button
      {...props}
      onClick={!disabled ? onClick : undefined}
      className={`${styles.button} ${styles[theme]} ${styles[type]} ${
        styles[size]
      } ${disabled ? styles.disabled : ""} ${className ?? ""}

      `}
      type={form ? "submit" : "button"}
    >
      {isLoading ? (
        <div
          style={{
            // color: "#000000",
            color: type === "primary" ? "#ffffff" : "#191a1b",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader size={14} />
        </div>
      ) : (
        <>{children}</>
      )}
    </button>
  );
};
