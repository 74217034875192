//  pages
// 1 - event + name. 2 - budget. 3 - time of day. 4- how formal?. 5 - how conservaitve?. 6- tinder(later). generate list for event

import { Close } from "@mui/icons-material";
import { produce } from "immer";
import { get, isEmpty } from "lodash";
import { useState } from "react";
import { BasicQuery } from "../../@types/query";
import {
  TransitionContainer,
  TransitionPage,
} from "../../components/TransitionContainer/TransitionContainer";
import { Back } from "../../components/back/Back";
import { Button } from "../../components/button/Button";
import { FloatingFooter } from "../../components/footer/floatingFooter/FloatingFooter";
import { LIMIT } from "../../params";
import { ProgressBar } from "../onboarding/OnboardPage";
import s from "./common/commonEventWizard.module.scss";
import { EventInfo } from "./steps/EventInfo";
import { EventTime } from "./steps/EventTime";
import { PriceRange } from "./steps/PriceRange";
import { ResultsView } from "./steps/ResultsView";
import { StyleAdjuster } from "./steps/StyleAdjuster";

export interface EventWizardFormStep {
  onChange: (value: any) => void;
  value: any;
}

export interface EventWizardForm {
  eventName: string;
  daytime: "night" | "day";
  priceRange: {
    lt: number;
    gt: number;
  };
  weights: {
    formal: number;
    sexy: number;
  };
  likedProducts: string[];
}

const InitialForm: EventWizardForm = {
  eventName: "",
  daytime: "night",
  priceRange: {
    lt: 1000000,
    gt: 0,
  },
  weights: {
    formal: 0,
    sexy: 0,
  },
  likedProducts: [],
};

export const EventWizard: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [step, setStep] = useState(0);
  const [form, setForm] = useState<EventWizardForm>(InitialForm);

  const onChangeForm = (key: keyof EventWizardForm, value: any) => {
    setForm((prev) => ({ ...prev, [key]: value }));
  };

  const buildCurrentQuery = (limit: number = 10) => {
    const query = {
      term: `${form.daytime} ${form.eventName}`,
      filters: [{ key: "price", value: form.priceRange as any }],
      weights: form.weights,
      limit: limit,
    } as BasicQuery;

    const tags = [
      ...buildTagsFromQuery({ weights: form.weights }, "sexy", [
        "conservative",
        "sexy",
      ]),
      ...buildTagsFromQuery({ weights: form.weights }, "formal", [
        "casual",
        "formal",
      ]),
    ];

    if (tags.length > 0) {
      query.filters?.push({
        key: "tags",
        value: tags,
      });
    }
    return query;
  };
  return (
    <>
      <div className={s.header}>
        {step > 0 && <Back onClick={() => setStep((prev) => prev - 1)} />}

        <div className="flex1 column">
          <ProgressBar variant="determinate" value={(step / 5) * 100} />
        </div>

        <div
          style={{ fontSize: "24px", display: "flex" }}
          onClick={() => onClose()}
        >
          <Close fontSize="inherit" sx={{ color: "#1F2029" }} />
        </div>
      </div>
      <TransitionContainer step={step}>
        <TransitionPage>
          <EventInfo
            onChange={(value) => onChangeForm("eventName", value)}
            value={form.eventName}
          />
        </TransitionPage>
        <TransitionPage>
          <EventTime
            onChange={(value) => onChangeForm("daytime", value)}
            value={form.daytime}
          />
        </TransitionPage>
        <TransitionPage>
          <PriceRange
            priceRange={form.priceRange}
            onChange={(value) => onChangeForm("priceRange", value)}
          />
        </TransitionPage>
        <TransitionPage>
          <StyleAdjuster
            title="How Formal is the event?"
            specificKey="formal"
            query={buildCurrentQuery()}
            extremeValuesForTags={["casual", "formal"]}
            onChangeWeight={(key, value) => {
              setForm(
                produce((draft) => {
                  draft.weights = { ...draft.weights, [key]: value };
                })
              );
            }}
          />
        </TransitionPage>
        <TransitionPage>
          <StyleAdjuster
            title="Feeling Sexy?"
            specificKey="sexy"
            query={buildCurrentQuery()}
            onChangeWeight={(key, value) =>
              setForm(
                produce((draft) => {
                  draft.weights = { ...draft.weights, [key]: value };
                })
              )
            }
          />
        </TransitionPage>
        <TransitionPage>
          <ResultsView query={{ ...buildCurrentQuery(LIMIT) }} />
        </TransitionPage>
      </TransitionContainer>
      {step <= 4 && (
        <FloatingFooter>
          <Button size="lg" onClick={() => setStep((prev) => prev + 1)}>
            Continue
          </Button>
        </FloatingFooter>
      )}
    </>
  );
};

const buildTagsFromQuery = (
  query: BasicQuery,
  styleKey: string,
  extremes?: [string, string]
) => {
  if (!styleKey || !extremes) return [];
  const tags = [];
  if (get(query.weights, styleKey, 0) > 25) {
    tags.push(extremes[1]);
  }
  if (get(query.weights, styleKey, 0) < -25) {
    tags.push(extremes[0]);
  }
  if (isEmpty(tags)) return [];
  return tags;
};
