import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Skeleton } from "@mui/material";
import { noop } from "lodash";
import React, { ReactNode, useState } from "react";
import { Search } from "../../../Input/Search/Search";
import { Button } from "../../../button/Button";
import { InfiniteScroller } from "../../../infiniteScroller/InfiniteScroller";
import { Pill } from "../../../pill/Pill";
import s from "./LargeListValues.module.scss";

export const LargeListValues: React.FC<{
  onSearch: (value: string) => void;
  list: { id: string; label: ReactNode }[];
  selectedIds: string[];
  onSelectItem: (id: string) => void;
  placeholder: string;
  isLoading?: boolean;
  tags?: string[];
  onSelectTag?: (t: string) => void;
  onFinish: () => void;
  selectedTags: string[];
  fetchNext?: () => void;
  isFetchingNext?: boolean;
  hasNext?: boolean;
}> = ({
  list,
  selectedIds,
  onSelectItem: onSelectList,
  placeholder,
  isLoading,
  onSearch,
  tags,
  onSelectTag,
  onFinish,
  selectedTags,
  isFetchingNext,
  fetchNext,
  hasNext,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    onSearch(value);
  };

  return (
    <div className="paddingMd column flex1 gapLg overflowHidden">
      <div className="alignCenter gapLg">
        <Search
          theme="light"
          onChange={handleSearch}
          value={searchTerm}
          placeholder={placeholder}
        />{" "}
        {searchTerm && (
          <div style={{ fontSize: "14px" }} onClick={() => handleSearch("")}>
            Cancel
          </div>
        )}
      </div>
      {!searchTerm && tags && (
        <div className={s.tags}>
          {tags.map((t) => (
            <Pill
              size="sm"
              isSelected={selectedTags.includes(t)}
              onClick={() => onSelectTag && onSelectTag(t)}
            >
              {t}
            </Pill>
          ))}
        </div>
      )}
      <div className={s.listContainer}>
        {!isLoading &&
          list.map((l, idx) => (
            <div
              className={`${s.listItem} `}
              onClick={() => onSelectList(l.id)}
            >
              {l.label}
              <div className={s.bullet}>
                {selectedIds.includes(l.id) && (
                  <CheckCircleIcon fontSize="inherit" />
                )}
              </div>
            </div>
          ))}
        {isLoading && (
          <div className="column gapXl">
            {[...new Array(7)].map((_) => (
              <Skeleton
                sx={{
                  height: "25px",
                  width: `${100 + 30 * Math.random()}px`,
                  borderRadius: "10px",
                }}
              />
            ))}
          </div>
        )}
        <div style={{ padding: "10px" }}>
          <InfiniteScroller
            isFetchingNextPage={!!isFetchingNext}
            fetchNextPage={fetchNext ?? noop}
            hasNextPage={!!hasNext}
          />
        </div>
      </div>
      <div className="paddingMd justifyEnd">
        <Button type="primary" onClick={() => onFinish()}>
          Done {!!selectedIds.length && `(${selectedIds?.length})`}
        </Button>
      </div>
    </div>
  );
};
