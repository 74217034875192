import { Slider, SliderProps, styled } from "@mui/material";
import { debounce, isNumber, orderBy, round, values } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { FilterValuesProps } from "./types";

import s from "./FilterCmps.module.scss";

const getPricesFromOptions = (options: any[]): [number, number] => {
  if (options.length !== 2) return [0, 100];
  return options.map((option, idx) => {
    try {
      return parseFloat(option);
    } catch (error) {
      return idx ? 100 : 0;
    }
  }) as [number, number];
};

export const PriceRangeValues: React.FC<FilterValuesProps> = ({
  onChange,
  options,
  selectedOptions,
}) => {
  const initialPrices = getMinMax(options);
  const [value, setValue] = useState<number[]>(
    getInitialState(initialPrices, selectedOptions)
  );

  useEffect(() => {
    setValue(getInitialState(initialPrices, selectedOptions));
  }, [selectedOptions]);

  const debounced = useCallback(
    debounce((value) => {
      onChange(value);
    }, 400),
    []
  );

  const handleChange = (event: Event, newValue: number | number[]) => {
    const range = newValue as number[];
    setValue(range);
    debounced({
      lt: round((range[1] / 100) * initialPrices[1]),
      gt: round((range[0] / 100) * initialPrices[1] + initialPrices[0]),
    });
  };

  return (
    <div className={s.prices}>
      <span className={s.price}>
        ${round((value[0] / 100) * initialPrices[1] + initialPrices[0])}
      </span>
      <AppSlider value={value} onChange={handleChange} />
      <span className={s.price}>
        ${round((value[1] / 100) * initialPrices[1])}+
      </span>
    </div>
  );
};

const getPriceRatio = (value: number, min: number, max: number) => {
  return ((value - min) / (max - min)) * 100;
};

const getInitialState = (
  initialPrices: [number, number],
  selectedOptions?: any
) => {
  if (!selectedOptions) return [0, 100];
  const minMaxSelected = getMinMax(
    orderBy(values(selectedOptions), (num) => num, "asc")
  );
  console.log({ minMaxSelected });

  return minMaxSelected.map((num) =>
    getPriceRatio(num, initialPrices[0], initialPrices[1])
  );
};

const getMinMax = (options?: any[]): [number, number] => {
  if (!options || options.length !== 2) return [0, 100];
  if (options.every((opt) => isNumber(opt)))
    return [options[0], options[1] > 1000 ? 1000 : options[1]] as [
      number,
      number
    ];
  return [0, 100];
};
export const AppSlider = styled(Slider)<SliderProps>(({ theme }) => ({
  "&.MuiSlider-root": {
    color: "#191a1b",
    padding: "10px 0px",
    // marging for the circle when at the edge
    margin: "0px 24px",
  },
  "& .MuiSlider-thumb": {
    backgroundColor: "#fff",
    boxShadow: "0 0 2px 0px rgba(0, 0, 0, 0.1)",
    "&:focus, &:hover, &.Mui-active": {
      boxShadow: "0px 0px 3px 1px rgba(0, 0, 0, 0.1)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: "none",
      },
    },
  },
}));
