import React from "react";
import { ReactComponent as ArrowLeft } from "../../styles/icons/ArrowLeft.svg";

export const Back: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ArrowLeft width="16px" height="16px" />
    </div>
  );
};
