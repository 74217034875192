import { ViewProps } from "../../@types/commonUi";
import { SwipeableWindow } from "../slidingWindow/SwipeableWindow";
import { FilterProps, Filters } from "./Filters";

export interface FiltersViewProps extends FilterProps, ViewProps {}

export const FiltersView: React.FC<FiltersViewProps> = ({
  onSubmit,
  ...viewProps
}) => {
  const onSubmitSideEffect = () => {
    viewProps.onClose();
    onSubmit();
  };
  return (
    <SwipeableWindow {...viewProps} direction="bottom">
      <Filters onSubmit={onSubmitSideEffect} />
    </SwipeableWindow>
  );
};
