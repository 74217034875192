import QueryString from "qs";
import { Brand, BrandSuggestion } from "../../../@types/entities/brand";
import { InfiniteQuery } from "../../../@types/query";
import { api, baseUrl } from "../api";

export interface BrandFilters extends InfiniteQuery {
  term?: string;
  tags: string[];
}

export const searchBrands = async (
  filters: BrandFilters
): Promise<{ items: Brand[] }> => {
  const stringified = QueryString.stringify(filters);
  try {
    const { data } = await api.calls.get(
      `${baseUrl}/api/brands/?${stringified}`
    );
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getSuggestedBrandsForUser = async (
  query: InfiniteQuery
): Promise<{
  items: BrandSuggestion[];
}> => {
  const stringified = QueryString.stringify(query);
  try {
    const { data } = await api.calls.get(
      `${baseUrl}/api/brands/suggested?${stringified}`
    );
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getSuggestedBrandsById = async (id: string) => {
  // try {
  //   const { data } = await api.calls.get(
  //     `${baseUrl}/api/users/wizard/brands?term=${term}`
  //   );
  //   return data;
  // } catch (error) {
  //   console.error(error);
  //   throw error;
  // }
};
