import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
} from "@mui/material";
import { get, keys } from "lodash";
import { useState } from "react";
import { queryClient } from "../../..";
import { MiniProduct } from "../../../@types/entities/product.types";
import { Button } from "../../../components/button/Button";
import { InfiniteScroller } from "../../../components/infiniteScroller/InfiniteScroller";
import { Modal } from "../../../components/Modal/Modal";
import { useAdminProductsForParsing } from "../../../hooks/admin/productparsing/useAdminProductsForParsing";
import { useProductParsingAttributes } from "../../../hooks/admin/productparsing/useAttributes";
import { useFlattenInfiniteQuery } from "../../../hooks/query/useFlattenInfinite";
import { updateAdminProductsParsing } from "../../../services/api/admin/products.admin";
import s from "./ProductParsing.module.scss";

const displayAttributesMap = {
  neckline_group: ["neckline_group", "neckline", "strap", "sleeve"],
  neckline: ["neckline", "strap", "sleeve_length"],
  strap_group: ["strap_group", "strap", "neckline", "sleeve"],
  skirt_group: ["skirt_group", "skirt", "length"],
  surface_type: ["surface_type", "pattern_style"],
  length_group: ["length_group", "length"],
  pattern_style: ["pattern_style", "pattern", "pattern_fallback"],
};

export const ProductParsing = () => {
  const [filters, setFilters] = useState<Record<string, string>>({});
  const { data: productAttributes } = useProductParsingAttributes();
  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useAdminProductsForParsing(filters);
  const products = useFlattenInfiniteQuery(data);
  const columnDisplay = get(displayAttributesMap, keys(filters)[0], []);
  const [isModal, setIsModal] = useState(false);
  const [idsForUpload, setIdsForUpdate] = useState("");
  const [updateForm, setUpdaForm] = useState({ field: "", value: "" });

  const onUpdateIds = async () => {
    const ids = idsForUpload.split(",");
    const updateMap = ids.reduce((acc, val) => {
      acc = {
        ...acc,
        [val]: {
          [updateForm.field]: updateForm.value,
        },
      };
      return acc;
    }, {});
    await updateAdminProductsParsing(updateMap);
    queryClient.invalidateQueries({ queryKey: ["admin", "products"] });
    setIsModal(false);
  };

  return (
    <div className="column gapXl overflowHidden">
      <Button onClick={() => setIsModal(true)}>Bulk Update</Button>
      <div className={s.filters}>
        {!!productAttributes &&
          keys(productAttributes).map((key) => {
            return (
              <FormControl fullWidth>
                <InputLabel id={key}>{key}</InputLabel>
                <Select
                  labelId={"tagging-status"}
                  id={"tagging-status"}
                  value={filters[key] ?? ""}
                  style={{ minWidth: "150px" }}
                  label={"tagging status"}
                  onChange={(ev) =>
                    setFilters((prev) => ({
                      [key]: ev.target.value,
                    }))
                  }
                >
                  <MenuItem value={""}>{""}</MenuItem>
                  {productAttributes[key].map((val: string) => (
                    <MenuItem value={val}>{val}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          })}
      </div>
      <div className={s.productGridContainer}>
        <h3>Products</h3>
        <div
          style={{
            gridTemplateColumns: ` 250px repeat(${columnDisplay.length},1fr)`,
          }}
          className={s.attrsGrid}
        >
          <div>Image</div>
          {columnDisplay.map((key) => (
            <div>{key}</div>
          ))}
          {products &&
            products?.map((p) => (
              <>
                <div>
                  <img src={p.imgUrls[0]} width={"250px"} />
                </div>
                {columnDisplay.map((key) => (
                  <div>{p[key as keyof MiniProduct] as string}</div>
                ))}
              </>
            ))}
          <InfiniteScroller
            isFetchingNextPage={isFetchingNextPage}
            fetchNextPage={fetchNextPage}
            hasNextPage={!!hasNextPage}
          />
        </div>
      </div>
      <Modal open={isModal} onClose={() => setIsModal(false)}>
        <div className="column flex1">
          <div className="flex1 gapXlg">
            <TextareaAutosize
              minRows={50}
              value={idsForUpload}
              onChange={(ev) => {
                setIdsForUpdate(() => ev.target.value.replaceAll("\n", ","));
              }}
            />
            <div className="column gapXlg">
              <FormControl fullWidth>
                <InputLabel id={"field_update"}>{"Field to update"}</InputLabel>
                <Select
                  labelId={"tagging-status"}
                  id={"tagging-status"}
                  value={updateForm.field}
                  style={{ minWidth: "150px" }}
                  label={"tagging status"}
                  onChange={(ev) =>
                    setUpdaForm((prev) => ({
                      value: "",
                      field: ev.target.value,
                    }))
                  }
                >
                  {keys(productAttributes).map((val: string) => (
                    <MenuItem value={val}>{val}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {updateForm.field && (
                <FormControl fullWidth>
                  <InputLabel id={"update_value"}>
                    {"value For Update"}
                  </InputLabel>
                  <Select
                    labelId={"update_value"}
                    id={"update_value"}
                    value={updateForm.value}
                    style={{ minWidth: "150px" }}
                    label={"update_value"}
                    onChange={(ev) =>
                      setUpdaForm((prev) => ({
                        ...prev,
                        value: ev.target.value,
                      }))
                    }
                  >
                    {productAttributes[updateForm.field]?.map((val: string) => (
                      <MenuItem value={val}>{val}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {updateForm.value && (
                <Button onClick={() => onUpdateIds()}>Update</Button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
