import { map } from "lodash";
import { ReactNode } from "react";
import { DropdownOption } from "../../../components/popover/Dropdown/types";
import s from "./commonEventWizard.module.scss";

export const WizardStepTitle: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return <div className={s.title}>{children}</div>;
};

export const WizardOption: React.FC<{
  children: ReactNode;
  isSelected?: boolean;
  onClick: () => void;
}> = ({ children, isSelected, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`${s.option} ${isSelected ? s.selected : ""}`}
    >
      {children}
    </div>
  );
};

export const WizardOptionsView: React.FC<{
  onSelect: (value: string) => void;
  options: DropdownOption[];
  value: string[];
  title: string;
}> = ({ options, onSelect, value, title }) => {
  return (
    <div className="column gapXlg">
      <WizardStepTitle>{title} </WizardStepTitle>
      <div className="column gapLg">
        {map(options, "value").map((option, idx) => (
          <WizardOption
            isSelected={value.includes(option)}
            onClick={() => onSelect(option)}
          >
            {options[idx].label}
          </WizardOption>
        ))}
      </div>
    </div>
  );
};
