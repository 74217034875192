import { Close } from "@mui/icons-material";
import { SwipeableDrawer } from "@mui/material";
import React, { ReactElement, ReactNode, useContext } from "react";
import { AppContext } from "../../App";
import { Back } from "../back/Back";
import s from "./SlidingWindow.module.scss";
export interface SlidingWindowProps {
  width?: string;
  height?: string;
  direction?: "left" | "right" | "bottom" | "top";
  children: ReactNode;
  isOpen: boolean;
  title?: ReactNode;
  isCenteredTitle?: boolean;
  onClose: () => void;
  isCloseDisabled?: boolean;
  key?: string;
  keepMounted?: boolean;
  customHeader?: ReactElement;
  onExit?: () => void;
  shouldMove?: boolean;
}

export interface SlidingWindowData {
  name: string;
  data: any;
}

const transition = {
  appear: 0,
  enter: 300,
  exit: 300,
};

const modalProps = {
  keepMounted: true,
};

const paperProps = {
  style: { width: "100%", height: "100%", boxShadow: "none" },
};

export const SlidingWindow: React.FC<SlidingWindowProps> = ({
  onExit,
  title,
  shouldMove,
  width,
  height,
  keepMounted = true,
  direction = "bottom",
  isOpen,
  children,
  onClose,
  isCloseDisabled,
  customHeader,
  key,
  isCenteredTitle = true,
}) => {
  const { isMobile, isApp } = useContext(AppContext);

  return (
    <>
      <SwipeableDrawer
        // SlideProps={{ in: true }}
        anchor={direction}
        // disablePortal
        onOpen={() => {}}
        key={key}
        open={isOpen}
        variant="temporary"
        hideBackdrop={direction !== "bottom" && !width}
        transitionDuration={transition}
        SlideProps={{ easing: "cubic-bezier(0.15, 0.05, 0.45, 0.85)" }}
        onClose={() => (isCloseDisabled ? null : onExit ? onExit() : onClose())}
        ModalProps={{
          keepMounted: keepMounted ?? true,
        }}
        PaperProps={{
          style: {
            width: width ?? "100%",
            height: direction === "bottom" ? "95%" : "100%",
            boxShadow: "none",
            borderTopLeftRadius: direction === "bottom" ? "16px" : "0px",
            borderTopRightRadius: direction === "bottom" ? "16px" : "0px",
          },
        }}
      >
        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: "1",
            transition: "transform 0.5s",
            transform: shouldMove ? "translateX(-25%)" : "none",
          }}
        > */}
        {title && !customHeader && (
          <>
            <div
              className={`${s.appSpace} ${
                isMobile && direction !== "bottom" ? s.app : ""
              }`}
            />
            <div className={s.title}>
              <div
                className="flex alignCenter gapLg flex1"
                onClick={() => (isCloseDisabled ? null : onClose())}
              >
                {direction !== "bottom" && <Back />}
                {!isCenteredTitle && title && title}
              </div>
              {isCenteredTitle && title && title}
              {direction !== "bottom" && <div className="flex1" />}
              {direction === "bottom" && (
                <div
                  onClick={() => (isCloseDisabled ? null : onClose())}
                  className="flex1 justifyEnd"
                >
                  <Close />
                </div>
              )}
            </div>
          </>
        )}
        {!title && !customHeader && (
          <div
            onClick={() => (isCloseDisabled ? null : onClose())}
            className={`${s.floatingBack} ${isMobile ? s.app : ""}`}
          >
            <Back />
          </div>
        )}
        {customHeader && customHeader}
        {children}
        {isMobile || (isApp && <div className="mobileFooterSpacer" />)}
        {/* </div> */}
      </SwipeableDrawer>
    </>
  );
};
