import { COLOR_MAP } from "../../consts";
import { FilterValuesProps } from "../types";
import s from "./ColorValues.module.scss";

export const ColorValues: React.FC<FilterValuesProps> = ({
  onChange,
  options,
  selectedOptions,
}) => {
  return (
    <div className="column flex1 scrollAuto">
      <div className={s.wrapperColors}>
        {options.map((option) => (
          <div className={s.container}>
            <div
              onClick={() => onChange(option)}
              className={`${s.colorValue} ${
                option === "multi" ? s.multi : ""
              } ${
                selectedOptions && selectedOptions.includes(option)
                  ? s.selected
                  : ""
              }`}
              style={{
                background: COLOR_MAP[option as keyof typeof COLOR_MAP] ?? "",
              }}
            />

            <span className={s.colorName}>{option}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
