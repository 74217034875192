import QueryString from "qs";
import { MiniProduct } from "../../../@types/entities/product.types";
import { api } from "../api";
import { adminApi } from "./common";

export const getLookalikeParsings = async (
  filters: any
): Promise<{ items: any[] }> => {
  const stringified = QueryString.stringify(filters);
  try {
    const { data } = await api.calls.get(
      `${adminApi}/lookbooks/lookalikes?${stringified}`
    );
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getLookalikeParsingValues = async (): Promise<{ values: any }> => {
  try {
    const { data } = await api.calls.get(
      `${adminApi}/lookbooks/lookalikes/parsing_values`
    );
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getLookalikeParsingFilterOptions = async (): Promise<{
  lookbooks: { code: string }[];
}> => {
  try {
    const { data } = await api.calls.get(
      `${adminApi}/lookbooks/lookalikes/parsing_filters`
    );
    return data.items;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getLookalikeProducts = async (
  id: string
): Promise<MiniProduct[]> => {
  try {
    const { data } = await api.calls.get(
      `${adminApi}/lookbooks/lookalikes/${id}/products`
    );
    return data.items;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateLookalikeParsing = async (id: string, form: any) => {
  try {
    const res = await api.calls.put(`${adminApi}/lookbooks/lookalikes/${id}`, {
      ...form,
    });
  } catch (error) {
    console.error(error);
  }
};
