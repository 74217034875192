import { ChevronRight } from "@mui/icons-material";
import { get } from "lodash";
import s from "./ListPreview.module.scss";

export const ListPreview: React.FC<{
  list: { title: string; subTitle?: string; imgs: string[] };
  onClick: () => void;
}> = ({ list, onClick }) => {
  return (
    <div onClick={() => onClick()} className={s.listContainer}>
      <div className={s.header}>
        <span>{list.title}</span>
        <ChevronRight />
      </div>
      <div className={s.thumbnailsContainer}>
        {[...new Array(4)].map((_, idx) => (
          <div className={s.imgThumbnailContainer} key={idx}>
            {get(list.imgs, idx) ? (
              <img src={list.imgs[idx]} alt="dress" />
            ) : (
              <div className={s.placeholder} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
