import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { queryClient } from "../../..";
import { useSnackbar } from "../../../contexts/snackbar";
import { analytics } from "../../../services/api/analytics";
import {
  addProductsToList,
  removeProductsFromList,
} from "../../../services/api/lists/lists.api";
import { useAppSelector } from "../../../store";
import { appActions } from "../../../store/app/slice";
import { useViewCaller } from "../../ui/useWindowCaller";

export const useToggleToList = () => {
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: async (payload: {
      listId: string;
      productIds: string[];
      action: "remove" | "add";
    }) => {
      dispatch(appActions.setRecentSavedCollectionId(payload.listId));
      if (payload.action === "add") {
        analytics.productLike(payload.productIds[0]);
        return await addProductsToList(payload.listId, payload.productIds);
      }
      return await removeProductsFromList(payload.listId, payload.productIds);
    },
    mutationKey: ["AddProductToList"],
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["lists"] }),
  });
};

export const useAutoToggleToList = () => {
  const { openSnackbar } = useSnackbar();
  const [windowCaller] = useViewCaller();
  const { recentSavedCollectionId, likedProducts } = useAppSelector(
    (store) => store.app
  );
  const { mutateAsync: toggleInListOptimisticWithoutAwaitingForSmoothUI } =
    useToggleToList();
  const onToggle = async (productId: string) => {
    if (recentSavedCollectionId) {
      toggleInListOptimisticWithoutAwaitingForSmoothUI({
        listId: recentSavedCollectionId,
        productIds: [productId],
        action: "add",
      });
    }
    windowCaller({
      id: "SaveToList",
      data: {
        productId,
      },
    });
  };
  return [onToggle] as const;
};
