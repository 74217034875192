import { get, isEmpty, noop, truncate } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { ChatMsg, Chat as ChatType } from "../../../../@types/entities/chat";
import {
  MiniProduct,
  ProductDetails,
} from "../../../../@types/entities/product.types";
import { User } from "../../../../@types/entities/user";
import { Modal } from "../../../../components/Modal/Modal";
import { TypingIndicator } from "../../../../components/TypingIndicator/TypingIndicator";
import { Button } from "../../../../components/button/Button";
import { Loader } from "../../../../components/loader/Loader";
import {
  NotTouchScreenMedia,
  TouchScreenMedia,
} from "../../../../components/media/MediaQueries";
import { Pill } from "../../../../components/pill/Pill";
import { socketService } from "../../../../services/mock/socket";
import { useAppSelector } from "../../../../store";
import { ReactComponent as Gigi } from "../../../../styles/icons/Gigi.svg";
import { ProductCard } from "../../../shop/cmps/productCard/ProductCard";
import styles from "./Chat.module.scss";

interface Props {
  chat: ChatType;
  isLoading: boolean;
  goToResults: (id: string) => void;
  onClickProduct?: (product: MiniProduct) => void;
  type: "gpt" | "mock";
  chatId: string;
}

export const Chat: React.FC<Props> = ({
  goToResults,
  isLoading,
  chat,
  type,
  onClickProduct,
  chatId,
}) => {
  const scrollerRef = useRef<HTMLDivElement>(null);
  const user = useAppSelector((app) => app.app.user);
  const [debugProductModalData, setDebugProductModalData] =
    useState<ProductDetails | null>(null);

  useEffect(() => {
    if (chat?.messages.length) {
      scrollToBottom();
    }
  }, [chat?.messages]);

  const scrollToBottom = () => {
    if (scrollerRef.current) {
      scrollerRef.current.scrollTo({
        top: scrollerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const onReceiveProducts = () => {
    scrollToBottom();
  };

  return (
    <div className={styles.container} ref={scrollerRef}>
      {chat?.messages &&
        chat.messages.map((msg) => (
          <div
            className={`${styles.msgContainer} ${
              msg.sender === "user" ? styles.user : ""
            }`}
          >
            <DynamicChatContent
              msg={msg}
              onGoToResults={goToResults}
              onClickProductCard={
                onClickProduct
                  ? (product) => {
                      // only admins
                      // setDebugProductModalData(product);
                      onClickProduct(product);
                    }
                  : undefined
              }
              onReceiveProducts={onReceiveProducts}
              type={type}
              chatId={chatId}
            />
          </div>
        ))}
      {isLoading && (
        <div className={`${styles.msgContainer}`}>
          <div className={styles.msg}>
            <TypingIndicator />
          </div>
        </div>
      )}
      {isEmpty(chat?.messages) && <ChatIntroMessages id={chatId} />}
      {debugProductModalData && (
        <Modal open onClose={() => setDebugProductModalData(null)}>
          <div className={styles.debugModal}>
            {get(
              debugProductModalData,
              "text_description",
              debugProductModalData.description
            )
              ?.split("\n")
              .filter((d) => !!d)
              .map((d) => (
                <>
                  {d.split(":").map((c, idx) => (
                    <span className={!idx ? styles.feature : ""}>
                      {!idx ? truncate(c, { length: 18 }) : c}
                    </span>
                  ))}
                </>
              ))}
          </div>
        </Modal>
      )}
    </div>
  );
};

interface ChatContentProps {
  msg: ChatMsg;
  onGoToResults: (msgId: string) => void;
  onClickProductCard?: (product: ProductDetails) => void;
  onReceiveProducts?: () => void;
  chatId: string;
}

export const ChatContent: React.FC<ChatContentProps> = ({
  msg,
  onGoToResults,
  onClickProductCard,
  onReceiveProducts,
}) => {
  const [productsWithVisibleImgs, setProductsWithVisibleImgs] = useState(
    msg?.data?.products
  );

  const doesHaveContent = msg.content || msg.data?.products?.length;
  return (
    <>
      {msg.sender === "user" && doesHaveContent && (
        <div className={styles.msg}>
          {msg.data?.products ? (
            <ProductCard
              product={msg.data.products[0]}
              className={styles.userProductQuery}
              disablePreviewSwipe
            />
          ) : (
            msg.content
          )}
        </div>
      )}
      {msg.sender === "chat" && msg.data?.products?.length === 0 && (
        <div className={styles.msg}>
          {msg.content ??
            "I am sorry, i could not find results. Raphael needs to work harder and smarter..."}
        </div>
      )}
      {msg.sender === "chat" && (
        <div className={styles.chatSenderContainer}>
          {msg.content && (
            <div
              style={{ gap: "12px", marginBottom: "10px" }}
              className="alignEnd"
            >
              <ChatSenderThumbnail sender="chat" />

              <div className={`${styles.msg} ${styles.chatContent}`}>
                {msg.content}
              </div>
            </div>
          )}

          {!!msg.data?.products?.length && (
            <>
              <div className={styles.productsMsgContainer}>
                <div className={styles.productsContainer}>
                  <TouchScreenMedia>
                    <div
                      style={{
                        overflowX: "auto",
                        display: "flex",
                        gap: "15px",
                        alignItems: "center",
                        overflowY: "hidden",
                      }}
                    >
                      {msg.data?.products.map((product, index) => (
                        <div
                          style={{ animationDelay: `${index * 0.05}s` }}
                          className={styles.chatProductContainer}
                        >
                          <ProductCard
                            size="sm"
                            key={index}
                            product={product}
                            onClick={() =>
                              onClickProductCard && onClickProductCard(product)
                            }
                            disablePreviewSwipe
                            onFailedImg={() =>
                              setProductsWithVisibleImgs((prev) =>
                                prev?.filter((p) => p._id !== product._id)
                              )
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </TouchScreenMedia>

                  <NotTouchScreenMedia>
                    {msg.data?.products.map((product, index) => (
                      <div
                        style={{ animationDelay: `${index * 0.05}s` }}
                        className={styles.chatProductContainer}
                      >
                        <ProductCard
                          key={index}
                          product={product}
                          onClick={() =>
                            onClickProductCard && onClickProductCard(product)
                          }
                          disablePreviewSwipe
                          className={styles.chatProduct}
                        />
                      </div>
                    ))}
                  </NotTouchScreenMedia>
                </div>
                {msg.sender === "chat" && (
                  <div className={styles.populateSearch}>
                    <Button
                      type="primary"
                      onClick={() => onGoToResults(msg.data?.responseId!)}
                    >
                      See more results
                    </Button>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export const ChatSenderThumbnail: React.FC<{
  sender: ChatMsg["sender"];
  user?: User;
}> = ({ user, sender }) => {
  if (sender === "user") return <></>;
  return <Gigi width={"32px"} height={"32px"} />;
};

export const MockChatContent: React.FC<ChatContentProps> = ({
  msg,
  chatId,
  onGoToResults,
}) => {
  return (
    <div className={styles.sender}>
      <div className={styles.contentContainer}>
        {/* <ChatSenderThumbnail sender={msg.sender} user={user} /> */}
        <div className={styles.msgContent}>{msg.content}</div>
      </div>

      {msg.data?.products && (
        <div className={styles.productsContainer}>
          {msg.data.products.map((product) => (
            <ProductCard
              product={product}
              onClick={noop}
              className={styles.chatProduct}
              disablePreviewSwipe
            />
          ))}
          {msg.sender === "chat" && (
            <div className={styles.populateSearch}>
              <Button type="cta" onClick={() => onGoToResults("")}>
                See more results
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const ChatIntroMessages: React.FC<{ id: string }> = ({ id }) => {
  return (
    <>
      {[`Hi there!💫  How can i help you shop today?`].map((msg) => (
        <div className={`${styles.msgContainer} `}>
          <DynamicChatContent
            onGoToResults={noop}
            chatId=""
            type="gpt"
            msg={{ sender: "chat", content: msg }}
          />
        </div>
      ))}
      <div className="flex column gapLg sideMargin">
        <div>
          <h4>Things I can help you with:</h4>
        </div>
        {[
          "Find me a cute dress for a vacation in Mykonos",
          "I want a formal yet sexy dress for an event",
          "show me some office attire dresses",
        ].map((opt) => (
          <Pill
            onClick={() => {
              socketService.emit("userMessage", id, {
                data: {
                  sender: "user",
                  content: opt,
                  timestamp: Date.now(),
                },
                type: "gpt",
              });
            }}
          >
            {opt}
          </Pill>
        ))}
      </div>
    </>
  );
};

export const DynamicChatContent: React.FC<
  ChatContentProps & {
    type: Props["type"];
    isLoading?: boolean;
  }
> = ({ type, ...props }) => {
  return (
    <>
      {type === "mock" && !props.isLoading && <MockChatContent {...props} />}
      {type === "gpt" && !props.isLoading && <ChatContent {...props} />}
      {props.isLoading && (
        <div className={styles.msg}>
          <Loader />
        </div>
      )}
    </>
  );
};
