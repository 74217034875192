import React, {
  CSSProperties,
  ChangeEventHandler,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ViewProps } from "../../@types/commonUi";
import {
  MiniProduct,
  ProductDetails,
} from "../../@types/entities/product.types";
import { AppContext } from "../../App";
import { ProductsGrid } from "../../components/ProductsGrid/ProductsGrid";
import { Back } from "../../components/back/Back";
import {
  IntersectionObserverCmp,
  SentinalForObserver,
} from "../../components/intersector/Intersector";
import { SlidingWindow } from "../../components/slidingWindow/SlidingWindow";
import { useViewCaller } from "../../hooks/ui/useWindowCaller";
import {
  getLookalikeProducts,
  parseLookalike,
} from "../../services/api/products/lookalike/lookalike.api";
import s from "./LookalikeProducts.module.scss";

export const BaseLookalikeProducts: React.FC<{
  lookalikeImg?: string;
  products: ProductDetails[];
  isLoading?: boolean;
  onClose?: () => void;
  onClickProduct?: (p: MiniProduct) => void;
  filters?: ReactElement;
}> = ({
  lookalikeImg,
  products,
  isLoading,
  onClose,
  onClickProduct,
  filters,
}) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const summaryRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  return (
    <>
      <div style={{ position: "relative" }} className="column flex1 gapLg">
        <img
          src={lookalikeImg}
          alt="user upload"
          width={"100%"}
          height={"100%"}
          style={{
            maxHeight: "550px",
            objectFit: "contain",
            objectPosition: "top",
          }}
          ref={imgRef}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          className="column gapMd"
        >
          <div ref={scrollRef} style={{ height: "100%", overflow: "auto" }}>
            <div
              style={{
                paddingTop: `${
                  (imgRef.current?.getBoundingClientRect().height ?? 0) - 20
                }px`,
              }}
            />
            <div
              style={{ borderRadius: "16px", backgroundColor: "white" }}
              className="column paddingMd"
            >
              <IntersectionObserverCmp
                isInView={() => {
                  summaryRef.current?.classList.remove(s.pinned);
                  summaryRef.current?.classList.add(s.inView);
                }}
                isOutsideView={() => {
                  summaryRef.current?.classList.add(s.pinned);
                  summaryRef.current?.classList.remove(s.inView);
                }}
              >
                <SentinalForObserver />
              </IntersectionObserverCmp>
              <div
                style={{
                  position: "sticky",
                  top: 0,
                  textAlign: "center",
                  padding: "16px 0px",
                  background: "white",
                  zIndex: 1,
                  display: "flex",
                  alignContent: "center",
                  overflow: "hidden",
                }}
              >
                {onClose && (
                  <div className="flex1">
                    <Back onClick={() => onClose && onClose()} />
                  </div>
                )}
                <div className="column gapLg overflowHidden">
                  <h4 style={{ padding: "0 16px" }}>Similar Results</h4>

                  <div className="gapSm alignCenter scrollAuto">
                    {filters && filters}
                  </div>
                </div>
                {onClose && <div className="flex1" />}
              </div>
              <ProductsGrid
                products={products ?? []}
                isLoading={!!isLoading}
                fetchNextPage={() => {}}
                isFetchingNextPage={false}
                hasNextPage={false}
                notifyClickProduct={onClickProduct}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={() =>
          scrollRef.current?.scroll({ top: 0, behavior: "smooth" })
        }
        className={s.summaryLookalike}
        ref={summaryRef}
      >
        <img src={lookalikeImg} alt="summary" />
      </div>
    </>
  );
};

export const LookalikeUploadView: React.FC<
  {
    lookalikeImg: string;
    parseId: string;
  } & ViewProps
> = ({ parseId, lookalikeImg, ...viewProps }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState<ProductDetails[]>();
  const { socket } = useContext(AppContext);

  useEffect(() => {
    if (!socket.current) return;
    socket.current.on("lookalikeJobDone", async (data: any) => {
      console.log(data.parse_id);
      if (data.parse_id === parseId) {
        const res = await getLookalikeProducts(data.parse_id);
        setProducts(res.items);
        setIsLoading(false);
      }
    });
    return () => {
      socket.current.off("lookalikeJobDone");
    };
  }, [parseId]);

  return (
    <SlidingWindow {...viewProps}>
      <BaseLookalikeProducts
        products={products ?? []}
        isLoading={isLoading}
        lookalikeImg={lookalikeImg}
        onClose={() => viewProps.onClose()}
      />
    </SlidingWindow>
  );
};

export const UploadWrapper: React.FC<{
  onUploadFile: ChangeEventHandler<HTMLInputElement>;
  children: ReactNode;
  style?: CSSProperties;
}> = ({ onUploadFile, children, style }) => {
  return (
    <div className="column" style={style}>
      <input
        type="file"
        accept="image/*"
        id="fileUploadEmptyView"
        onChange={onUploadFile}
        style={{ visibility: "hidden", maxHeight: 0, maxWidth: 0 }}
      />
      <label htmlFor="fileUploadEmptyView" className="flex1 alignCenter">
        {children}
      </label>
    </div>
  );
};

export const LookalikeWrapper: React.FC<{
  children: ReactNode;
  style?: CSSProperties;
}> = ({ children, style }) => {
  const [callWindow] = useViewCaller();
  const handleFileChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!event.target.files?.length) return;
    handleUpload(event.target.files[0]);
  };

  const handleUpload = async (file: File) => {
    const formData = new FormData();
    formData.append("image", file);
    const res = await parseLookalike({ formData });
    callWindow({
      id: "imgLookalikeView",
      data: {
        parseId: res.parseId,
        lookalikeImg: res.path,
      },
      direction: "right",
    });
  };

  return (
    <UploadWrapper style={style} onUploadFile={handleFileChange}>
      {children}
    </UploadWrapper>
  );
};
