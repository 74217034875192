const CATEGORIES = [
  "bodycon",
  "slip",
  "beach",
  "sweater",
  "denim",
  "velvet",
  "sequin",
  "mini black",
  "kaftan",
  "blazer dress",
  "plunge",
  "wrap",
  "floral",
  "striped",
  "polka dot",
];

export const CategoriesView = () => {
  return (
    <div
      style={{
        padding: "16px",
        gap: "60px 16px",
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
      }}
    >
      {CATEGORIES.map((c) => (
        <div className="column gapLg">
          <div className="alignCenter">
            <img
              src={`https://storage.googleapis.com/gigi_pics_public/categories/${c}.jpg`}
              style={{ borderRadius: "6px", aspectRatio: 3 / 4 }}
            />
          </div>
          <h4 className="justifyCenter">{c}</h4>
        </div>
      ))}
    </div>
  );
};
