import { useMemo, useState } from "react";
import {
  FilterUnit,
  ProductDetails,
} from "../../../../@types/entities/product.types";
import { ProductsGrid } from "../../../../components/ProductsGrid/ProductsGrid";
import { BASIC_PRODUCT_FILTERS } from "../../../../components/filters/Filters";
import { useSimilarProducts } from "../../../../hooks/query/product/useProduct";
import { useFilterActions } from "../../../../hooks/system/useFilterActions";
import {
  ActionOnProductProps,
  ActionsOnProduct,
} from "../ActionsOnProduct/ActionsOnProduct";
export const SimilarProducts: React.FC<{
  product: ProductDetails;
  onQueryOnProduct: () => void;
  filters: FilterUnit[];
  onChangeFilters: (filters: FilterUnit[]) => void;
  actionProps: ActionOnProductProps;
}> = ({ product, onQueryOnProduct, filters, onChangeFilters, actionProps }) => {
  const { fetchNextPage, isFetchingNextPage, isLoading, data, hasNextPage } =
    useSimilarProducts(product._id!, filters);
  const [selectedAction, setSelectedAction] = useState("");
  const { filtersOptions, updateFilter } = useFilterActions();

  const products = useMemo(() => {
    if (!data) return [];
    console.log(data.pages);

    return data?.pages.flatMap((products) => products);
  }, [data]);

  const onClickAction = (action: string, filters: FilterUnit[]) => {
    if (action !== selectedAction) {
      onChangeFilters(filters);
      setSelectedAction(action);
      return;
    }
    onChangeFilters([]);
    setSelectedAction("");
  };

  return (
    <>
      <h3 style={{ marginTop: "12px" }}>Similar For you</h3>
      <div className="alignCenter gapMd overflowX no-swiping">
        {/* <FiltersPreview
          options={[
            ...get(filtersOptions?.features, "filters", []),
            ...get(filtersOptions?.metadata, "filters", []),
          ]}
          activeFilters={keyBy(localFilters, "key")}
          onChange={onChangeLocalFilter}
        /> */}

        <ActionsOnProduct {...actionProps} />
      </div>
      <ProductsGrid
        isFetchingNextPage={isFetchingNextPage}
        isLoading={isLoading}
        hasNextPage={!!hasNextPage}
        fetchNextPage={fetchNextPage}
        products={data?.pages.flatMap((products) => products) ?? []}
      />
    </>
  );
};

const convertProductToFilters = (product: ProductDetails): FilterUnit[] => {
  return BASIC_PRODUCT_FILTERS.map((f) => ({
    key: f,
    value: [product[f as keyof ProductDetails]],
  })) as FilterUnit[];
};
