import { CSSProperties, ReactNode } from "react";
import s from "./Blur.module.scss";
export const BlurWrapper: React.FC<{
  children: ReactNode;
  style?: CSSProperties;
  onClick: () => void;
  cta: ReactNode;
}> = ({ children, style, onClick, cta }) => {
  return (
    <div
      style={{ position: "relative", flex: 1, ...style }}
      onClick={() => onClick()}
    >
      {children}
      <div
        className={s.blur}
        style={{
          ...style,
        }}
      >
        {cta}
      </div>
    </div>
  );
};
