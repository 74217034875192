import { useEffect, useRef } from "react";
import { Button } from "../../../../components/button/Button";
import { Pill } from "../../../../components/pill/Pill";
import { ReactComponent as Stars } from "../../../../styles/icons/ai.svg";
import { ReactComponent as Gigi } from "../../../../styles/icons/Gigi.svg";

export const GigiPreview = () => {
  return (
    <div
      style={{
        overflow: "hidden",
        maxWidth: "100vw",
        backgroundColor: "#f4f6ff",
      }}
      className="column gapMd"
    >
      <div
        style={{ padding: "32px" }}
        className="column gapLg flex1 alignCenter"
      >
        <h2 style={{ textAlign: "center", color: "#2d2d2d" }}>
          Your Personal Shopper
        </h2>
        <p style={{ fontSize: 13, lineHeight: 1.25, textAlign: "center" }}>
          Gigi helps you find exactly what you want and tailors your fashion
          journey with every interaction
        </p>

        <Gigi width={80} height={80} />
      </div>

      <div className="column gapMd">
        <div
          style={{
            fontSize: 13,
            fontWeight: 600,
            paddingInlineStart: "16px",
            color: "#2d2d2d",
            textAlign: "center",
          }}
        >
          Kickstart your chat
        </div>
        <div className="alignCenter flex1 ">
          <div
            style={{ paddingInlineStart: "16px" }}
            className="alignCenter gapMd flex1 scrollAuto"
          >
            {[
              "comfortable day dress",
              "edgy black dress for a party",
              "dramatic dress for a gala",
              "casual satin dress for an event",
            ].map((p) => (
              <Pill size="sm" type="naked" onClick={() => {}}>
                <div className="icon withFill dark">
                  <Stars width={14} height={14} />
                </div>
                {p}
              </Pill>
            ))}
          </div>
        </div>
      </div>
      <div style={{ padding: "32px 16px" }} className="column">
        <Button onClick={() => {}}>
          <div className="alignCenter gapSm">
            Shop with Gigi{" "}
            <div className="icon withFill light">
              <Stars width={16} height={16} />
            </div>
          </div>
        </Button>
      </div>
    </div>
  );
};

export const RevolvingImages = ({
  urls,
  max,
}: {
  urls: string[];
  max?: string;
}) => {
  const imgRef = useRef<HTMLImageElement>(null);
  useEffect(() => {
    let idx = 0;
    const rotateImgs = () => {
      if (idx == urls.length - 1) {
        idx = 0;
      } else {
        idx++;
      }
      if (imgRef.current) {
        // imgRef.current.style.transform = `rotate(${
        //   idx % 2 == 0 ? "-" : ""
        // }${random(0, 5)}deg)`;
        imgRef.current.src = urls[idx];
      }
    };
    let interval = setInterval(rotateImgs, 800);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="column alignCenter">
      <div style={{ maxWidth: max ?? "100%" }} className="column justifyCenter">
        <img
          src={urls[0]}
          // ref={imgRef}
          style={{
            borderRadius: "8px",
            aspectRatio: 2 / 3,
            objectFit: "cover",
          }}
        />
      </div>
    </div>
  );
};
