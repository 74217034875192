import { isEmpty, last } from "lodash";
import { useMemo } from "react";
import { ChatMsg } from "../../../@types/entities/chat";

export const useChatOptions = (messages?: ChatMsg[]) => {
  // const { data, isLoading } = useQuery({
  //   queryFn: getChatOptions,
  //   queryKey: ["chatOptions"],
  //   enabled: !isEmpty(messages),
  //   cacheTime: 1000 * 60 * 5,
  //   staleTime: 1000 * 60 * 5,
  // });

  const options = useMemo(() => {
    // if (isEmpty(messages) && data) {
    //   return data.items;
    // }
    if (!isEmpty(last(messages)?.data?.options)) {
      return last(messages)?.data?.options;
    }
    return [];
  }, [messages]);

  return { options };
};
