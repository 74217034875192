import { Skeleton } from "@mui/material";
import { useDebounce } from "@uidotdev/usehooks";
import { find, last } from "lodash";
import { useMemo, useState } from "react";
import { Brand } from "../../../../@types/entities/brand";
import { Search } from "../../../../components/Input/Search/Search";
import { Pill } from "../../../../components/pill/Pill";
import {
  useWizardBrands,
  useWizardSuggestedBrands,
} from "../../../../hooks/query/product/useProductFilters";
import { toggleInList } from "../../../../utils/array";
import { UserProfileBuilderForm } from "../../OnboardPage";
import s from "./BrandSelection.module.scss";

export const BrandSelection: React.FC<{
  onChange: (brands: UserProfileBuilderForm["brands"]) => void;
  selectedBrands: Brand[];
}> = ({ selectedBrands, onChange }) => {
  const [term, setTerm] = useState("");
  const debouncedValue = useDebounce(term, 400);
  const { data: brands, isLoading, isError } = useWizardBrands(debouncedValue);
  const { data: suggested, isLoading: isLoadingSuggested } =
    useWizardSuggestedBrands(last(selectedBrands)?._id);

  const displayedBrands = useMemo(() => {
    if (!term && selectedBrands.length) return suggested;

    return brands?.filter((b) => b.count > 10);
  }, [brands, suggested, selectedBrands, term]);

  const toggleBrand = (brand: Brand) => {
    setTerm("");
    onChange(toggleInList(selectedBrands, brand));
  };

  const isLoadingBrands =
    (!term && selectedBrands.length && isLoadingSuggested) || isLoading;

  return (
    <div className={s.container}>
      <div className={s.search}>
        <Search
          onChange={(value) => setTerm(value)}
          value={term}
          placeholder="Free People, Norma Kamali, Cult Gaia..."
          theme="light"
        />
      </div>
      {!!selectedBrands.length && (
        <>
          <div className={s.selectedSection}>
            <div className={s.list}>
              {selectedBrands.map((brand) => (
                <Pill
                  size="sm"
                  type="secondary"
                  onClick={() => toggleBrand(brand)}
                >
                  {brand.name}
                </Pill>
              ))}
            </div>
          </div>
          <div className={s.separator} />
        </>
      )}
      <div className={s.brandContainer}>
        <div className={s.list}>
          {displayedBrands && !isLoadingBrands && (
            <>
              {displayedBrands
                .filter(
                  (brand) =>
                    !find(selectedBrands, ({ _id }) => _id === brand._id)
                )
                .map((brand) => (
                  <Pill size="sm" onClick={() => toggleBrand(brand)}>
                    {brand.name}
                  </Pill>
                ))}
            </>
          )}
          {isLoadingBrands &&
            [...new Array(10)].map((_) => (
              <Skeleton
                sx={{ height: "60px", width: "90px", borderRadius: "8px" }}
              />
            ))}
        </div>
      </div>
    </div>
  );
};
