import { useEffect, useState } from "react";

const usePreloadImages = (imageUrls: string[]) => {
  const [loadedAllImages, setLoaded] = useState(false);
  const [failedUrls, setFailed] = useState<string[]>([]);

  useEffect(() => {
    if (!imageUrls || imageUrls.length === 0) {
      setLoaded(true);
      return;
    }
    const preloadImage = (
      url: string
    ): Promise<{ url: string; failed: boolean }> => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;

        img.onload = () => resolve({ url, failed: false });
        img.onerror = () => resolve({ failed: true, url });
      });
    };
    const preloadAllImages = async () => {
      try {
        const results = await Promise.all(imageUrls.map(preloadImage));
        const failedUrls = results
          .filter((result) => result.failed)
          .map((result) => result.url);

        setFailed(failedUrls);
      } catch (error) {
        console.error("Unexpected error during image preloading", error);
      } finally {
        setLoaded(true);
      }
    };

    preloadAllImages();
  }, [JSON.stringify(imageUrls)]);

  return [loadedAllImages, failedUrls];
};

export default usePreloadImages;
