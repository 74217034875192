import { Outlet } from "react-router";
import { AdminMenu } from "./menu/AdminMenu";

export const Admin = () => {
  return (
    <div style={{ width: "100vw", height: "100vh" }} className="flex">
      <AdminMenu />
      <div className="flex flex1 column">
        <h1>Admin</h1>
        <Outlet />
      </div>
    </div>
  );
};
