import { useInfiniteQuery } from "react-query";
import { LIMIT } from "../../../params";
import { getListSuggestions } from "../../../services/api/lists/lists.api";
import { getNextSkip } from "../../../utils/reactQuery";

export const useListSuggestions = (id?: string) =>
  useInfiniteQuery({
    queryFn: async ({ pageParam }) => {
      const res = await getListSuggestions(id!, {
        limit: LIMIT,
        skip: pageParam,
      });
      return res.items;
    },
    queryKey: ["ListSuggestions", id],
    getNextPageParam: getNextSkip,
    enabled: !!id,
  });
