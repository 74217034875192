import { isString, keyBy } from "lodash";
import { useMemo } from "react";
import { FilterUnit } from "../../@types/entities/product.types";
import { FilterService } from "../../services/domains/filter.service";
import { useAppDispatch, useAppSelector } from "../../store";
import { appActions } from "../../store/app/slice";
import { useProductFeatureFilters } from "../query/product/useProductFilters";

export const useFilterActions = () => {
  const { filters } = useAppSelector((store) => store.app);
  const dispatch = useAppDispatch();
  const {
    data: filtersOptions,
    isLoading: isLoadingOptions,
    isError: isErrorOptions,
  } = useProductFeatureFilters();

  const adaptedStoreActiveFilters = useMemo(() => {
    return keyBy(filters, "key");
  }, [filters]);

  const updateFilter = (
    key: string,
    value: any,
    localFilters?: FilterUnit[]
  ) => {
    console.log(key, value);

    const payload: [FilterUnit[], string, any] = [
      localFilters ?? filters,
      key,
      value,
    ];

    if (isString(value)) {
      const updatedFilters = FilterService.updateSingleValue(...payload);
      return updatedFilters;
    }
    const updatedFilters = FilterService.replaceFilterValue(...payload);
    return updatedFilters;
  };

  const setStoreFilters = (filters: FilterUnit[]) => {
    dispatch(appActions.setFilters({ filters: filters }));
  };

  return {
    filtersOptions,
    isLoadingOptions,
    isErrorOptions,
    adaptedStoreActiveFilters,
    updateFilter,
    setStoreFilters,
  };
};
