import { noop } from "lodash";
import { Carousel } from "../../../../components/carousels/Carousel";
import { ReactComponent as Arrow } from "../../../../styles/icons/ArrowRight.svg";
import { COLLECTIONS } from "../collections/consts";
import s from "./HomeView.module.scss";
export const CollectionsPreview = () => {
  return (
    <div className="column gapLg">
      <div className={s.feedTitleContainer}>
        <div className={s.feedTitle}>Collections For You</div>
        <div className="alignCenter justifyCenter" onClick={noop}>
          <Arrow />
        </div>
      </div>
      <Carousel
        settings={{ perView: 1.4 }}
        items={COLLECTIONS.slice(4).map((c) => ({
          title: c.title,
          element: <Preview url={c.src} />,
        }))}
      />
    </div>
  );
};

const Preview: React.FC<{ url: string }> = ({ url }) => {
  return (
    <div style={{ padding: "0 16px" }} className="flex justifyCenter">
      <div>
        <img
          src={url}
          alt=""
          style={{
            borderRadius: "6px",
            aspectRatio: 9 / 10,
            objectFit: "cover",
          }}
        />
      </div>
    </div>
  );
};
