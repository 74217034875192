import { get } from "lodash";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Button } from "../../components/button/Button";
import { Password } from "../../components/Input/Password/Password";
import { Loader } from "../../components/loader/Loader";
import { PageWrapper } from "../../components/pageWrapper/PageWrapper";
import {
  resetPassword,
  verifyResetToken,
} from "../../services/api/users/users.api";

export const ResetPassword = () => {
  const params = useParams<{ token: string }>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [isReset, setIsReset] = useState(false);
  const { isLoading, isError, data } = useQuery({
    queryFn: async () => {
      try {
        const data = await verifyResetToken(params.token);
        return data;
      } catch (error) {
        return {
          error: get(
            error,
            "response.data.error",
            "An Authentication error occured"
          ),
        };
      }
    },
    queryKey: ["resetPassword", params.token],
  });

  const {
    mutateAsync: resetUserPassword,
    isError: isErrorReset,
    isLoading: isResetting,
  } = useMutation({
    mutationFn: async (updatedPassword: string) => {
      try {
        const res = await resetPassword(updatedPassword, params.token!);
      } catch (error) {
        return {
          error: get(
            error,
            "response.data.error",
            "An Authentication error occured"
          ),
        };
      }
    },
  });

  const onReset = async () => {
    try {
      await resetUserPassword(newPassword);
      setIsReset(true);
    } catch (error) {}
  };

  if (isLoading)
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    );

  if (isReset) {
    return (
      <PageWrapper>
        <div className="column flex1 gapLg paddingLg">
          <h4>your password has been updated</h4>
          <Button onClick={() => navigate("/login")}>Log in</Button>
        </div>
      </PageWrapper>
    );
  }
  return (
    <PageWrapper>
      <div className="column gapXlg paddingLg justifyCenter flex1">
        {data?.email ? (
          <>
            <h3>Reset password for {data.email}</h3>
            <Password
              placeholder="New password"
              value={newPassword}
              onChange={(value) => setNewPassword(value)}
            />

            <Button isLoading={isResetting} onClick={() => onReset()}>
              Reset Password
            </Button>
          </>
        ) : (
          <>
            <div style={{ textAlign: "center" }}>{data.error}</div>
            <Button onClick={() => navigate(`/${searchParams.toString()}`)}>
              Back to home screen
            </Button>
          </>
        )}
      </div>
    </PageWrapper>
  );
};
