import { isNil, toInteger } from "lodash";
import {
  forwardRef,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { AppContext } from "../../App";
import appStyles from "../../App.module.scss";
import { Button } from "../../components/button/Button";
import { PageWrapper } from "../../components/pageWrapper/PageWrapper";
import { LandingPage } from "./LandingPage";
import s from "./LandingPageV2.module.scss";
import Background from "./assets/bg.svg";
import bella from "./assets/celebs/bella.jpg";
import beyonce from "./assets/celebs/beyonce.jpg";
import gigi from "./assets/celebs/gigi.jpg";
import hailey from "./assets/celebs/hailey.jpg";
import kendell from "./assets/celebs/kendell.jpg";
import kylie from "./assets/celebs/kylie.jpg";

export const LandingV2 = () => {
  const { isMobile } = useContext(AppContext);
  if (isMobile) {
    return <LandingTouchScreenApp />;
  }
  return <LandingPage />;
};

const LandingTouchScreenApp = () => {
  const navigate = useNavigate();
  const swiperRef = useRef<SwiperClass>();
  return (
    <div style={{ height: "100%" }} className="column flex1">
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 1,
        }}
      >
        <img
          style={{ objectFit: "cover" }}
          src={Background}
          width={"100%"}
          height={"100%"}
        />
      </div>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 2,
        }}
      >
        <PageWrapper>
          <div
            style={{ padding: "0px 16px 0px 16px" }}
            className="column flex1 gapLg overflowHidden"
          >
            <h1 className={s.title}>Gigi.</h1>
            <div className="flex1 column relative overflowHidden">
              <div className="flex flex1 overflowHidden">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={32}
                  modules={[Pagination]}
                  pagination={{ enabled: true }}
                  className={`${appStyles.swiper} overflowHidden `}
                  onSwiper={(swiper: SwiperClass) =>
                    (swiperRef.current = swiper)
                  }
                >
                  <SwiperSlide className={"column overflowHidden"}>
                    <OpenerStep />
                  </SwiperSlide>
                  <SwiperSlide className="column overflowHidden">
                    <CelebsStep />
                  </SwiperSlide>

                  <SwiperSlide className="column overflowHidden">
                    <SearchStepView />
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>

            <div style={{ paddingBottom: "16px" }} className="column gapLg">
              <Button
                type="primary"
                size="lg"
                onClick={() => {
                  navigate("/onboard");
                }}
              >
                Create account
              </Button>

              <Link
                style={{ fontSize: "14px", textAlign: "center" }}
                className="link"
                to={`/login`}
              >
                Already have an account? log in here.
              </Link>
            </div>
          </div>
        </PageWrapper>
      </div>
    </div>
  );
};

export const LandingTemplate: React.FC<{
  subtitle: string;
  children: ReactNode;
}> = ({ subtitle, children }) => {
  return (
    <div className="flex1 column gapLg relative overflowHidden">
      <h2 className={s.subtitle}>{subtitle}</h2>

      <div className="flex1 column overflowHidden">{children}</div>
    </div>
  );
};

export const CelebsStep = () => {
  return (
    <LandingTemplate subtitle="Follow the stars' style.">
      <div className={`${s.celebs} overflowHidden flex1`}>
        <div />
        <img
          style={{
            gridRow: "2/4",
            objectPosition: "top",
          }}
          src={kendell}
          alt="celeb"
        />
        <img
          style={{
            gridColumn: "2/-1",
            gridRow: "1/3",
            objectPosition: "top",
          }}
          src={hailey}
          alt="celeb"
        />
        <img
          style={{
            gridColumn: "2/3",
            gridRow: "3/4",
          }}
          src={kylie}
          alt="celeb"
        />
        <img src={beyonce} alt="celeb" />
        <img
          style={{
            gridColumn: "1/3",
            alignSelf: "baseline",
          }}
          src={gigi}
          alt="celeb"
        />
        <img src={bella} alt="celeb" />
      </div>
    </LandingTemplate>
  );
};

const placeholders = [
  "Marilyn Monroe vibes dress",
  "A dress that will make my ex jealous",
  "Fashion statement dress for a gala",
  "Dress for a beach lounge cocktail",
];
const productBank = [
  [
    {
      brand: "Saints & Secrets",
      price: 96,
      src: "https://cdn.shopify.com/s/files/1/0414/2622/3270/products/CM6080D_20OYSTER-1_large.jpg?v=1684896229",
      randMove: toInteger(Math.random() * 20),
    },
    {
      brand: "Reformation",
      price: 756,
      src: "https://media.thereformation.com/image/list/fn_select:jq:first(.%5B%5D%7Cif%20has(%22metadata%22)%20then%20select(any(.metadata%5B%5D;%20.external_id%20==%20%22sfcc-is-main%22%20and%20.value.value%20==%20%22True%22))%20else%20empty%20end)/f_auto,q_auto,dpr_auto/w_600/1313889-IVO.json?_s=RAABAB0%20600w",
      randMove: toInteger(Math.random() * 20),
    },
    {
      brand: "Michael Lo Sordo",
      price: 1160,
      src: "https://is4.revolveassets.com/images/p4/n/z/MILO-WD31_V1.jpg",
      randMove: toInteger(Math.random() * 20),
    },
    {
      brand: "MAJORELLE",
      price: 278,
      src: "https://is4.revolveassets.com/images/p4/n/z/MALR-WD1414_V1.jpg",
      randMove: toInteger(Math.random() * 20),
    },
  ],
  [
    {
      brand: "Angel Biba",
      price: 76,
      src: "https://cdn.shopify.com/s/files/1/0414/2622/3270/files/ST3381D_20FIRE_20RED-1_large.jpg?v=1693377835",
      randMove: toInteger(Math.random() * 20),
    },
    {
      brand: "Hello Molly",
      price: 75,
      src: "https://www.hellomolly.com/cdn/shop/files/39A6599_1704349318_ed9050c0-0194-4a0f-b03b-c992c9a992b2_1800x1800.jpg?v=1713850168",
      randMove: toInteger(Math.random() * 20),
    },
    {
      brand: "Superdown",
      price: 65,
      src: "https://is4.revolveassets.com/images/p4/n/z/SPDW-WD1910_V1.jpg",
      randMove: toInteger(Math.random() * 20),
    },
    {
      brand: "Amor Mia",
      price: 296,
      src: "https://is4.revolveassets.com/images/p4/n/z/AMIA-WD5_V1.jpg",
      randMove: toInteger(Math.random() * 20),
    },
  ],

  [
    {
      brand: "Alexander McQueen",
      price: 4500,
      src: "https://img.mytheresa.com/652/738/100/jpeg/catalog/product/7c/P00874721_b1.jpg",
      randMove: toInteger(Math.random() * 20),
    },
    {
      brand: "Zimmerman",
      price: 1765,
      src: "https://cdn.modaoperandi.com/assets/images/products/980142/621023/c2/medium_zimmermann-multi-natura-draped-linen-silk-midi-dress.jpg?_v=1712342233",
      randMove: toInteger(Math.random() * 20),
    },
    {
      brand: "Alex Perry",
      price: 1450,
      src: "https://cdn.modaoperandi.com/assets/images/products/977768/618407/c2/medium_alex-perry-blue-sequined-satin-crepe-midi-dress.jpg?_v=1712863352",
      randMove: toInteger(Math.random() * 20),
    },
    {
      brand: "Mugler",
      price: 2465,
      src: "https://img.mytheresa.com/652/738/100/jpeg/catalog/product/1c/P00912515_b1.jpg",
      randMove: toInteger(Math.random() * 20),
    },
  ],
  [
    {
      brand: "Cult Gaia",
      price: 660,
      src: "https://cdn.modaoperandi.com/assets/images/products/969819/609750/c2/medium_cult-gaia-off-white-floreana-knit-dress.jpg?_v=1712755881",
      randMove: toInteger(Math.random() * 20),
    },
    {
      brand: "Fortunate One",
      price: 86,
      src: "https://www.fortunateone.com/cdn/shop/products/a58a8299_2978aac4-d264-49b8-9063-9eb97775a0c9_520x.jpg?v=1692621658",
      randMove: toInteger(Math.random() * 20),
    },
    {
      brand: "Antica Positano",
      price: 115,
      src: "https://anticasartoriapositano.it/wp-content/uploads/2024/04/Antica-Sartoria-Positano-ABITO-DONNA-Colore-BIANCO-DV164.jpg",
      randMove: toInteger(Math.random() * 20),
    },
    {
      brand: "Free People",
      price: 148,
      src: "https://is4.revolveassets.com/images/p4/n/z/FREE-WD2258_V1.jpg",
      randMove: toInteger(Math.random() * 20),
    },
  ],
];

export const OpenerStep = () => {
  const placeholders = ["Your Personal Fashion Shopper"];
  // const title = useRef<HTMLSpanElement>(null);
  // const blinker = useRef<HTMLDivElement>(null);
  // useEffect(() => {
  //   let intervalId: NodeJS.Timer;

  //   if (title.current) {
  //     let index = 0;
  //     intervalId = setInterval(() => {
  //       let currentText = placeholders[index];
  //       let currentIndex = 0;
  //       let innerIntervalId = setInterval(() => {
  //         if (isNil(title.current)) return;
  //         if (currentIndex < currentText.length) {
  //           blinker.current?.classList.add(s.show);
  //           title.current.textContent = currentText.substring(
  //             0,
  //             currentIndex + 4
  //           );
  //           currentIndex = currentIndex + 1;
  //         } else {
  //           blinker.current?.classList.remove(s.show);
  //           clearInterval(innerIntervalId);
  //         }
  //       }, 40);
  //       index = index < placeholders.length - 1 ? index + 1 : 0;
  //     }, 6000);
  //   }

  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);

  return (
    <LandingTemplate subtitle="">
      <div
        style={{
          lineHeight: 1.25,
          fontSize: "54px",
          fontWeight: "bold",
          paddingInlineStart: "16px",
          paddingTop: "32px",
        }}
      >
        <span>
          Your
          <br /> Personal <br /> Fashion <br /> Shopper.
        </span>
        <span
          style={{ height: "50px", display: "inline" }}
          className={s.blinker}
        />
      </div>
    </LandingTemplate>
  );
};

export const SearchStep: React.FC<{
  styles?: {
    containerClassName?: string;
    productClassName?: string;
    searchClassName?: string;
    blinkerClassName?: string;
  };
}> = ({ styles }) => {
  const search = useRef<HTMLSpanElement>(null);
  const blinker = useRef<HTMLSpanElement>(null);
  const [products, setProducts] = useState<
    {
      brand: string;
      price: number;
      src: string;
      randMove: number;
    }[]
  >(productBank[0]);

  useEffect(() => {
    let intervalId: NodeJS.Timer;

    if (search.current) {
      search.current.textContent = placeholders[0];
      let index = 1;
      intervalId = setInterval(() => {
        setProducts(productBank[index]);
        let currentText = placeholders[index];
        let currentIndex = 0;
        let innerIntervalId = setInterval(() => {
          console.log("here second");

          if (isNil(search.current)) return;
          if (currentIndex < currentText.length) {
            blinker.current?.classList.add(s.show);
            search.current.textContent = currentText.substring(
              0,
              currentIndex + 4
            );
            currentIndex = currentIndex + 1;
          } else {
            blinker.current?.classList.remove(s.show);
            clearInterval(innerIntervalId);
          }
        }, 40);
        index = index < placeholders.length - 1 ? index + 1 : 0;
      }, 6000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className={`column flex1 ${styles?.containerClassName ?? ""}`}>
      <div className="column alignCenter gapSm justifyCenter  paddingMd">
        <div className="alignCenter">
          {/* <Search width={22} height={22} /> */}
          <span
            className={` ${s.search} ${styles?.searchClassName ?? ""}`}
            ref={search}
          />
          <Blinker className={styles?.blinkerClassName ?? ""} ref={blinker} />
        </div>
      </div>
      <div
        style={{
          flexWrap: "wrap",
          justifyContent: "space-around",
          paddingTop: "35px",
        }}
        className="relative flex1 gapMd "
      >
        {products.map((p, index) => (
          <SearchContextProductThumbnail
            productClassName={styles?.productClassName ?? ""}
            loc={index}
            item={p}
          />
        ))}
      </div>
    </div>
  );
};

export const Blinker = forwardRef<HTMLSpanElement, { className?: string }>(
  ({ className }, ref) => {
    return <span ref={ref} className={`${s.blinker} ${className ?? ""}`} />;
  }
);

export const SearchContextProductThumbnail: React.FC<{
  loc: number;
  item: { brand: string; price: number; src: string };
  productClassName?: string;
}> = ({ loc, item, productClassName }) => {
  return (
    <div
      style={{
        animationDelay: `0.${loc * 2}s`,
        // animationName: `move${Math.floor(Math.random() * 4)}`,
      }}
      key={`${loc}_${Math.random()}`}
      className={`${s.product}
    ${s[`move${Math.floor(Math.random() * 3 + 1)}`]}
    `}
    >
      <div
        style={{
          animationDelay: `${loc / 2}s`,
        }}
        className={`${s.productContainer} ${productClassName}`}
      >
        <img src={item.src} alt="" />
        <div className={s.overlayContent}>
          <span>{item.brand}</span>
          <span>${item.price}</span>
        </div>
      </div>
    </div>
  );
};

const SearchStepView = () => {
  return (
    <LandingTemplate subtitle="Express your fashion desires.">
      <SearchStep />
    </LandingTemplate>
  );
};
