import { MiniProduct } from "../../../../@types/entities/product.types";
import { ProductCard } from "../../../shop/cmps/productCard/ProductCard";
import s from "./HomeView.module.scss";

export const RecentlyViewed: React.FC<{
  onClickProduct: (p: MiniProduct) => void;
  products: MiniProduct[];
}> = ({ products, onClickProduct }) => {
  return (
    <>
      <div>
        <div className={s.feedTitleContainer}>
          <div>
            <div className={s.feedTitle}>Recently viewed</div>
          </div>
          {/* <Arrow /> */}
        </div>
        <div className={s.feedContentScroller}>
          {!!products &&
            products
              .slice(0, 5)
              .map((p) => (
                <ProductCard product={p} onClick={onClickProduct} hideLike />
              ))}
        </div>
      </div>
      {/* <SlidingWindow
        isOpen={!!windowName}
        onClose={() => setWindow("")}
        title={"My Curated Feed"}
        direction="right"
      >
        <FeedV2 />
      </SlidingWindow> */}
    </>
  );
};
