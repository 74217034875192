import { map } from "lodash";
import { useNavigate } from "react-router";
import { Button } from "../../components/button/Button";
import { useHeaderActions } from "../../components/header/useHeaderActions";
import { PageWrapper } from "../../components/pageWrapper/PageWrapper";
import { SearchHeader } from "../../components/searchHeader/SearchHeader";
import { SlidingWindow } from "../../components/slidingWindow/SlidingWindow";
import { useCreateList } from "../../hooks/query/lists/useCreateList";
import { useLists } from "../../hooks/query/lists/useLists";
import { useDelayedClose } from "../../hooks/ui/useDelayedClose";
import { ReactComponent as AIStyle } from "../../styles/icons/ai.svg";
import s from "./Lists.module.scss";
import { CreateList } from "./cmps/createList/CreateList";
import { ListDetails } from "./cmps/listDetails/ListDetails";
import { ListPreview } from "./cmps/listPreview/ListPreview";

export const ListsPage: React.FC = () => {
  const { data, isLoading } = useLists();
  const { mutateAsync: createList } = useCreateList();
  const navigate = useNavigate();
  const { onSearch } = useHeaderActions();
  const { data: selectedList, onOpen, onClose, isOpen } = useDelayedClose();
  const {
    onOpen: onOpenCreate,
    onClose: onCloseCreate,
    isOpen: isCreateOpen,
  } = useDelayedClose();

  const onSubmitCreate = (title: string) => {
    createList({ title: title, savedProducts: [] });
    onCloseCreate();
  };

  const onClickList = (id: string) => {
    const list = data?.items.find((l) => l._id === id);
    if (!list) return;
    onOpen(list);
  };

  return (
    <>
      <SearchHeader
        onSearch={(s: string) => {
          navigate("/search");
          onSearch(s);
        }}
        local
        onBack={() => {}}
        isSearchMode={false}
      />
      <PageWrapper>
        <div className={s.page}>
          <div className="column gapLg">
            <div className={s.titleContainer}>
              <h3>My lists</h3>
              <div className={s.middleBalancer} />
              <div className={s.iconContainer}>
                <Button onClick={() => onOpenCreate("create")}>
                  Create a list
                </Button>
              </div>
            </div>
            <h5 className={s.subTitle}>
              Create lists and receive tailored suggestions for each list
            </h5>
          </div>
          <div className={s.listsContainer}>
            {data?.items.map((list, index) => (
              <div className="column gapLg">
                <ListPreview
                  key={index}
                  list={{
                    title: list.title,
                    imgs: map(list.savedProducts, "thumbnail"),
                  }}
                  onClick={() => onClickList(list._id)}
                />
                <div className={s.listRecs}>
                  <AIStyle width={16} height={16} />
                  <span onClick={() => onClickList(list._id)}>
                    Get Gigi's recommendations
                  </span>
                </div>
              </div>
            ))}
          </div>

          {data?.items && data.items.length === 0 && (
            <Button onClick={() => onOpenCreate("create")}>CREATE</Button>
          )}
          <CreateList
            onClose={onCloseCreate}
            isOpen={isCreateOpen}
            onSubmit={onSubmitCreate}
          />
        </div>
        <SlidingWindow
          height="100%"
          isOpen={isOpen}
          title={selectedList?.title}
          onClose={onClose}
          direction="right"
        >
          <ListDetails list={selectedList} initialTab="suggested" />
        </SlidingWindow>
      </PageWrapper>
    </>
  );
};
