import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { getSuggestedBrandsForUser } from "../../../services/api/brands/brand.api";
import { getLookbooks } from "../../../services/api/lookbook/lookbook.api";
import {
  getRecentlyViewedProducts,
  getSuggestedProductsForUser,
} from "../../../services/api/products/products.api";
import { store, useAppSelector } from "../../../store";

export const useHomePage = () => {
  const dispatch = useDispatch();
  const { didLoadHome } = useAppSelector((store) => store.app);
  return useQuery({
    queryFn: async () => {
      const [lookbooks, personalFeed, recentlyViewed, suggestedBrands] =
        await Promise.all([
          getLookbooks(),
          getSuggestedProductsForUser([], { limit: 10 }),
          getRecentlyViewedProducts({ limit: 10 }),
          getSuggestedBrandsForUser({ limit: 10 }),
        ]);
      return { lookbooks, personalFeed, recentlyViewed, suggestedBrands };
    },
    queryKey: ["homeFeed", store.getState().app.user?._id],
    cacheTime: 10 * 60 * 1000,
    staleTime: 30 * 1000,
    keepPreviousData: true,
    enabled: !!store.getState().app.user?._id,
  });
};
