import { SlidingWindow } from "../../../../components/slidingWindow/SlidingWindow";
import { useLookbooks } from "../../../../hooks/query/lookbook/useLookbook";
import { useDelayedClose } from "../../../../hooks/ui/useDelayedClose";
import { useViewCaller } from "../../../../hooks/ui/useWindowCaller";
import { LookbookDetailsPage } from "../../../lookbook/LookbookDetails/LookbookDetails";
import { CelebThumbnail } from "../home/lookbooks";
import s from "./CelebsView.module.scss";
export const CelebsView = () => {
  const { data: lookbooks } = useLookbooks();
  const [callView] = useViewCaller();
  const { onOpen, onClose, isOpen, data } = useDelayedClose();
  // const { data: feed } = useLookbooksFeed();
  // const { onOpen, onClose, data: lookalikeData, isOpen } = useDelayedClose();
  // const { data: products, isLoading: isLoadingProducts } = useLookalikeProducts(
  //   lookalikeData?._id
  // );

  return (
    <>
      {/* <div className={s.feed}>
        {feed?.items.map((item, idx) => (
          <img
            onClick={() => onOpen(item)}
            style={{ animationDelay: `0.0${idx}s` }}
            src={item.path}
          />
        ))}
      </div> */}

      <div className={s.container}>
        {lookbooks?.items.map((l, index) => (
          <div
            className={s.thumbnail}
            style={{ animationDelay: `${index / 30}s` }}
          >
            <CelebThumbnail lookbook={l} onOpen={onOpen} />
          </div>
        ))}
      </div>
      {/* <BaseLookalikeProducts
        onClose={onClose}
        isOpen={!!isOpen}
        lookalikeImg={lookalikeData?.path}
        products={products}
        isLoading={isLoadingProducts}
        direction="right"
      /> */}
      <SlidingWindow
        onClose={() => onClose()}
        direction="right"
        title={""}
        isOpen={isOpen && !!data}
        keepMounted={false}
      >
        <LookbookDetailsPage lookbook={data!} />
      </SlidingWindow>
    </>
  );
};
