import { FC } from "react";
import { BaseStyleScenarioStep } from "./BaseStyleScenarioStep";

export class DateStyleScenarioType extends BaseStyleScenarioStep {
  name = "DateScenarioStep";
  constructor() {
    super();
  }

  getAdditionalProps = () => ({
    options: [
      {
        imgUrl: "https://is4.revolveassets.com/images/p4/n/z/LANS-WK15_V1.jpg",
        id: "1",
        title: "",
      },
      {
        imgUrl:
          "https://seestarz.com/cdn/shop/files/017A6432.jpg?v=1693413537&width=1080",
        id: "3",
        title: "",
      },
      {
        imgUrl: "https://is4.fwrdassets.com/images/p/fw/z/NOMF-WS59_V1.jpg",
        id: "4",
        title: "",
      },
      {
        imgUrl:
          "https://cdn-images.kooding.com/productDetailImage/487681-2/abd7581d167dbf1bc6c097ee9603fa15e055b787.jpeg",
        id: "5",
        title: "",
      },
      {
        imgUrl:
          "https://is4.revolveassets.com/images/p4/n/z/FREE-WD2027_V1.jpg",
        id: "6",
        title: "",
      },
      {
        imgUrl: "https://is4.revolveassets.com/images/p4/n/z/ELLI-WD179_V1.jpg",
        id: "7",
        title: "",
      },
      {
        imgUrl: "https://is4.revolveassets.com/images/p4/n/z/NKAM-WC99_V1.jpg",
        id: "8",
        title: "",
      },
    ],
    prompt:
      "You are going to a dinner date in a fancy restaurant. Which style would you consider?  ",
  });
}
