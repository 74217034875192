import { ReactNode } from "react";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import appStyles from "../../App.module.scss";
import s from "./Carousel.module.scss";
interface CarouselProps {
  items: Item[];
  settings?: { perView?: number };
}

interface Item {
  element: ReactNode;
  title?: string;
  description?: string;
}

export const Carousel: React.FC<CarouselProps> = ({ items, settings = {} }) => {
  return (
    <div>
      <Swiper
        slidesPerView={settings.perView ?? 1}
        className={appStyles.swiper}
        modules={[Pagination]}
        pagination={{ enabled: true }}
      >
        {items.map((item) => (
          <SwiperSlide
            style={{
              flexDirection: "column",
              display: "flex",
              alignItems: "center",
            }}
          >
            {item.element}
            <div
              style={{ margin: "16px 16px 32px 16px" }}
              className="column alignCenter gapLg"
            >
              {(item.title || item.description) && (
                <div className="column gapLg">
                  {item.title && (
                    <h3 style={{ textAlign: "center" }}>{item.title}</h3>
                  )}
                  {item.description && (
                    <p className={s.description}>{item.description}</p>
                  )}
                </div>
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
