import { CircularProgress } from "@mui/material";
import React from "react";
import s from "./Loader.module.scss";

export const Loader: React.FC<{ size?: number; text?: string }> = ({
  size,
  text,
}) => {
  return (
    <div className={s.loader}>
      <CircularProgress size={size ?? 16} color="inherit" />
      {text && <div className={s.text}>{text}</div>}
    </div>
  );
};
