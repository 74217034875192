import { orderBy } from "lodash";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { List } from "../../../../@types/entities/list";
import { ProductsGrid } from "../../../../components/ProductsGrid/ProductsGrid";
import { Button } from "../../../../components/button/Button";
import { useListProducts } from "../../../../hooks/query/lists/useListProducts";
import { useListSuggestions } from "../../../../hooks/query/lists/useListSuggestions";
import s from "./ListDetails.module.scss";

export const ListDetails: React.FC<{
  list: List | null;
  initialTab?: "saved" | "suggested";
}> = ({ list, initialTab }) => {
  const [view, setView] = useState<"saved" | "suggested">(
    initialTab ?? "saved"
  );
  const {
    data: products,
    isLoading: isLoadingProducts,
    hasNextPage: hasNextProducts,
    isFetching: isFetchingProducts,
    fetchNextPage: fetchNextProducts,
  } = useListProducts(list?._id);
  const {
    data: suggestions,
    isLoading: isLoadingSuggestions,
    hasNextPage: hasNextSuggestions,
    isFetching: isFetchingSuggestions,
    fetchNextPage: fetchNextSuggestions,
  } = useListSuggestions(list?._id);

  const productsDisplay = useMemo(() => {
    if (!products) return [];
    return orderBy(
      products.pages.flatMap((page) => page),
      ({ savedAt }) => savedAt,
      "desc"
    );
  }, [products]);

  const suggestionsDisplay = useMemo(() => {
    if (!suggestions) return [];
    return suggestions.pages.flatMap((page) => page);
  }, [suggestions]);

  return (
    <div className={s.container}>
      <div className={s.tabs}>
        <div
          onClick={() => {
            setView("saved");
          }}
          className={`${s.tab} ${view === "saved" ? s.selected : ""}`}
        >
          <span>Saved</span>
        </div>
        <div
          onClick={() => setView("suggested")}
          className={`${s.tab} ${view === "suggested" ? s.selected : ""}`}
        >
          <span>Suggested</span>
        </div>
      </div>

      {view === "saved" &&
        (isLoadingProducts || productsDisplay.length ? (
          <ProductsGrid
            hasNextPage={!!hasNextProducts}
            fetchNextPage={fetchNextProducts}
            isFetchingNextPage={isFetchingProducts}
            products={productsDisplay}
            isLoading={isLoadingProducts}
          />
        ) : (
          <EmptyMessage msg="Add dresses to your list and they will appear here." />
        ))}
      {view === "suggested" &&
        (isLoadingSuggestions || suggestionsDisplay.length ? (
          <ProductsGrid
            hasNextPage={!!hasNextSuggestions}
            fetchNextPage={fetchNextSuggestions}
            isFetchingNextPage={isFetchingSuggestions}
            products={suggestionsDisplay}
            isLoading={isLoadingSuggestions}
            // TODO:SHCUNA! MANAGE BETTER
            invalidateKeyOnDislike={["ListSuggestions", list?._id]}
          />
        ) : (
          <EmptyMessage msg="Add dresses to your list and receive tailored suggestions!" />
        ))}
    </div>
  );
};

const EmptyMessage: React.FC<{ msg: string }> = ({ msg }) => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        padding: "100px 20px",
        display: "flex",
        flexDirection: "column",
        gap: "60px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "15px",
        }}
      >
        <h3>Woops, you didn't save any dresses!</h3>

        <h5 className={s.subTitle}>{msg}</h5>
      </div>

      <Button onClick={() => navigate("/search")} type="primary">
        Let's find dresses
      </Button>
    </div>
  );
};
