// import io from 'socket.io-client'
// import {httpService} from './httpService'
import { get } from "lodash";
export const SOCKET_EMIT_USER_WATCH = "user-watch";
export const SOCKET_EVENT_USER_UPDATED = "user-updated";
export const SOCKET_EVENT_REVIEW_ADDED = "review-added";

const baseUrl = process.env.NODE_ENV === "production" ? "" : "//localhost:3030";
// export const socketService = createSocketService()
export const socketService = createDummySocketService();
// @ts-ignore
window.socketService = socketService;

var socketIsReady = false;
// socketService.setup()

// function createSocketService() {
//   let socket = null;
//   const socketService = {
//     async setup() {
//       if (socket) return
//       await httpService.get('setup-session')
//       socket = io(baseUrl, { reconnection: false})
//       socketIsReady = true;
//     },
//     async on(eventName, cb) {
//       if (!socket) await socketService.setup()
//       socket.on(eventName, cb)
//     },
//     async off(eventName, cb=null) {
//       if (!socket) await socketService.setup()
//       if (!cb) socket.removeAllListeners(eventName)
//       else socket.off(eventName, cb)
//     },
//     async emit(eventName, data) {
//       if (!socket) await socketService.setup()
//       socket.emit(eventName, data)
//     },
//     terminate() {
//       socket = null
//       socketIsReady = false
//     }
//   }
//   return socketService
// }

// eslint-disable-next-line
function createDummySocketService() {
  var listenersMap: Record<string, Record<string, Function[]>> = {};
  const socketService = {
    listenersMap,
    setup() {
      listenersMap = {};
    },
    terminate() {
      this.setup();
    },
    on(eventName: string, id: string, cb: Function) {
      listenersMap = {
        ...listenersMap,
        [eventName]: {
          ...get(listenersMap, `${eventName}`, {}),
          [id]: [cb],
        },
      };
    },
    off(eventName: string, id: string) {
      if (!get(listenersMap, `${eventName}.${id}`)) return;
      if (!cb) delete listenersMap[eventName][id];
      else listenersMap[eventName][id] = [];
    },
    emit(eventName: string, id: string, data: any) {
      if (!get(listenersMap, `${eventName}.${id}`)) return;
      console.log("Dello", eventName, id, listenersMap);
      listenersMap[eventName][id].forEach((listener) => {
        listener(data);
      });
    },
    debugMsg() {
      this.emit("chat addMsg", "test", {
        from: "Someone",
        txt: "Aha it worked!",
      });
    },
  };
  return socketService;
}

// Basic Tests
function cb(x: string) {
  console.log(x);
}
