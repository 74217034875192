/* @ts-ignore */
/* eslint-disable */
import { createSlice } from "@reduxjs/toolkit";
// import { loadApp } from "./actions";
import { uniq } from "lodash";
import { queryClient } from "../..";
import { CommonFilter, FilterUnit } from "../../@types/entities/product.types";
import { BasicQuery } from "../../@types/query";
import { FilterService } from "../../services/domains/filter.service";
import { AppState } from "./types";

const initialState: AppState = {
  isLoading: false,
  searchTerm: "",
  filters: [],
  initialChat: null,
  didVisitHomeFeed: false,
  user: null,
  isApp: false,
  likedProducts: [],
  didLoadHome: false,
  recentSavedCollectionId: null,
  deviceId: null,
  searchHistory: [],
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setDidLoadHome: (state) => {
      state.didLoadHome = true;
    },
    setSearchTerm: (state, { payload }) => {
      state.searchTerm = payload;
      state.searchHistory = uniq([payload, ...state.searchHistory]);
    },
    setChat: (state, { payload }) => {
      state.initialChat = payload;
    },
    onCreateHomeFeed: (state) => {
      state.didVisitHomeFeed = true;
    },
    onSelectUser: (state, { payload }) => {
      queryClient.removeQueries({ queryKey: [] });
      state.user = payload;
    },
    updateUser: (state, { payload }) => {
      state.user = payload;
    },
    updateFilters: (
      state,
      { payload }: { payload: { key: string; filter: string } }
    ) => {
      state.filters = FilterService.updateSingleValue(
        state.filters,
        payload.key,
        payload.filter
      );
    },
    setFilters: (
      state,
      { payload }: { payload: { filters: FilterUnit[] } }
    ) => {
      state.filters = payload.filters;
    },
    setIsApp: (state, { payload }: { payload: { isApp: boolean } }) => {
      state.isApp = payload.isApp;
    },
    setLikedProducts: (state, { payload }: { payload: { ids: string[] } }) => {
      state.likedProducts = payload.ids;
    },
    setRecentSavedCollectionId: (state, { payload }: { payload: string }) => {
      state.recentSavedCollectionId = payload;
    },
    setDeviceId: (state, { payload }: { payload: string | null }) => {
      state.deviceId = payload;
    },

    setProductSearchPayload: (
      state,
      { payload }: { payload: Pick<BasicQuery, "filters" | "weights" | "term"> }
    ) => {
      state.filters = (payload.filters as CommonFilter[]) ?? state.filters;
      state.searchTerm = payload.term ?? state.searchTerm;
    },
  },
});

export const { actions: appActions, reducer: appReducer } = appSlice;
