import { SwipeableDrawer } from "@mui/material";
import React, { ReactElement } from "react";
import { FooterSpacerMobileApp } from "../spacers/FooterSpacer";
import s from "./SlidingWindow.module.scss";
export const SwipeableWindow: React.FC<{
  isOpen: boolean;
  children: ReactElement;
  onClose: () => void;
  height?: string;
  title?: string;
  direction?: "bottom" | "right";
}> = ({ isOpen, children, onClose, height, title, direction = "bottom" }) => {
  return (
    <SwipeableDrawer
      anchor={direction ?? "bottom"}
      open={isOpen}
      // disablePortal
      onClose={onClose}
      onOpen={() => console.log("hello")}
      PaperProps={{
        style: {
          width: "100%",
          minHeight: height ?? "95%",
          maxHeight: "95%",
          paddingTop: "30px",
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
        },
      }}
      transitionDuration={{
        appear: 0,
        enter: 400,
        exit: 250,
      }}
      keepMounted={false}
      disableScrollLock
    >
      {direction === "bottom" && (
        <div className={s.swipeableSignal}>
          <SwipeableSignal />
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        {title && <h3 className={s.swipeableTitle}>{title}</h3>}
        {children}
        <FooterSpacerMobileApp />
      </div>
    </SwipeableDrawer>
  );
};

export const SwipeableSignal = () => {
  return (
    <div
      style={{
        background: "#c0c0c0",
        padding: "3px",
        borderRadius: "8px",
        width: "50px",
      }}
    />
  );
};
