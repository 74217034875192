import { useState } from "react";

type UseDelayedClose = <T extends any = any>() => {
  data: T | null;
  onClose: () => void;
  onOpen: (data: T) => void;
  isOpen: boolean;
};

export const useDelayedClose: UseDelayedClose = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);

  return {
    onOpen(data) {
      setData(data);
      setIsOpen(true);
    },
    onClose() {
      setIsOpen(false);
      setTimeout(() => {
        setData(null);
      }, 400);
    },
    data,
    isOpen,
  };
};
