import React from "react";
import { ReactComponent as StylistAvatar } from "../../../../styles/icons/Gigi.svg";

export const ChatPageTitle: React.FC = () => {
  return (
    <div className="alignCenter gapMd">
      <StylistAvatar width={42} height={42} />
      <div className="column gapSm">
        <span style={{ fontSize: "16px" }}>Gigi</span>
        <span style={{ fontSize: "12px", fontWeight: 300 }}>
          @personalShopper
        </span>
      </div>
    </div>
  );
};
