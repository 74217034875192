import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import { useState } from "react";
import { SearchPills } from "../../../../pages/search/views/searchView/SearchView";
import { useAppSelector } from "../../../../store";
import s from "./SearchSuggestions.module.scss";

type SelectedExplore =
  | "Wedding"
  | "Basic"
  | "Work"
  | "Party"
  | "Vacation"
  | "Celebrities";

const EXPLORE_OPTIONS: SelectedExplore[] = [
  "Wedding",
  "Basic",
  "Work",
  "Party",
  "Vacation",
  "Celebrities",
];
export const SearchSuggestions: React.FC<{
  onSearch: (s: string) => void;
  isOpen: boolean;
}> = ({ onSearch, isOpen }) => {
  const [selectedExplore, setSelectedExplore] =
    useState<SelectedExplore>("Wedding");
  const { searchHistory } = useAppSelector((s) => s.app);

  return (
    <div className={`${s.searchOptions} `}>
      <div className="column gapXlg">
        {isOpen && <SearchPills onSearch={onSearch} />}
        <div className={s.suggested}>
          <div className={s.title}>
            <div className={s.icon}>
              <AccessTimeOutlinedIcon fontSize="inherit" />
            </div>
            Recent
          </div>
          <SearchList list={searchHistory.slice(0, 5)} onSearch={onSearch} />
        </div>

        {/* <div className={s.explore}>
        <div className={s.title}>
          <div className={s.icon}>
            <ViewCarouselRoundedIcon fontSize={"inherit"} />
          </div>
          Explore
        </div>
        <div className={s.cards}>
          {EXPLORE_OPTIONS.map((label) => (
            <div
              className={`${s.card} ${
                selectedExplore === label ? s.selected : ""
              }`}
              onClick={() => setSelectedExplore(label)}
            >
              {label}
            </div>
          ))}
        </div>
      </div> */}
        {/*
      <div>
        <div className={s.title}>
          <div className={s.icon}>
            <TrendingUpRoundedIcon fontSize="inherit" />
          </div>
          You might be searching for...
        </div>
        <SearchList
          list={searchOptionsByExplore[selectedExplore]}
          onSearch={onSearch}
        />
      </div> */}
      </div>
    </div>
  );
};

export const SearchList: React.FC<{
  list: string[];
  onSearch: (o: string) => void;
}> = ({ list, onSearch }) => {
  return (
    <>
      {list.map((o) => (
        <div
          className={s.option}
          onClick={() => {
            onSearch(o);
          }}
        >
          <span>{o}</span>
        </div>
      ))}
    </>
  );
};

export const SEARCH_OPTIONS = [
  "Fun dress for a pool party",
  "sexy dress for clubbing ",
  "cute wedding guest dress with a hint of sexy",
  "70's style dress",
  "dramatic dress for a gala",
  "marilyn monroe vibes ",
  "afternoon cocktail mini dress",
  "tight long dress for curvy hips",
  "dress that beyonce could wear for a gala",
  "dress for an afternoon wedding by the beach",
  "bridesmaid dress",
  "dress for a casual afternoon date",
  "cute mini day dress",
  "flowing sundress for a party",
  "long sexy dress for a wedding guest",
  "tight mini black dress",
  "dress with techno party vibes",
  "Jackie kennedy vibes",
  "LA party scene vibes",
];

const searchOptionsByExplore: Record<SelectedExplore, string[]> = {
  Basic: [
    "casual day dress for errands",
    "simple casual mini dress",
    "vibrant yellow simple day dress",
    "dress fot an afternoon date by the park",
    "cute dress for church",
  ],
  Celebrities: [
    "Kate middleton style dress",
    "dress for beyonce body type",
    "cool day dress Hailey Bieber would wear",
    "Jackie Kennedy vibes evening dress",
  ],
  Party: [
    "Sexy dress for clubbing in LA",
    "cute black mini dress",
    "Cocktail party long dress with a slit",
    "open back silver dress with glitter",
    "Dress for a techno festival",
    "pool party cover-up dress",
    "summer party dress",
  ],
  Vacation: [
    "Parisian vibes dress",
    "long flowing dress for a vacation in Mykonos",
    "tight mini dress for Ibiza parties",
    "Tropical light dress for vacation",
    "cut out flower print dress with deep cleavage",
  ],
  Wedding: [
    "pastel colors dress with slit for wedding guest",
    "beach wedding guest dress",
    "daytime wedding guest mini dress",
    "elegant evening dress for wedding guest",
    "bridesmaid dress",
    "flowing dress summer wedding",
    "plunge v-neck dress for wedding guest",
    "formal wedding guest dress for black tie event",
  ],
  Work: [
    "sophisticated dress for a lunch meeting",
    "dress to exude power for a business meeting",
    "feminine yet professional dress for work",
    "conservative dress for work",
  ],
};
