import { noop } from "lodash";
import { Carousel } from "../../../../components/carousels/Carousel";
import { ReactComponent as Arrow } from "../../../../styles/icons/ArrowRight.svg";
import s from "./HomeView.module.scss";
export const LookbooksPreview = () => {
  return (
    <div className="column gapLg">
      <div className={s.feedTitleContainer}>
        <div className={s.feedTitle}>Inspo from the stars</div>
        <div className="alignCenter justifyCenter" onClick={noop}>
          <Arrow />
        </div>
      </div>
      <Carousel
        settings={{ perView: 1.2 }}
        items={[
          {
            title: "Bella Hadid",
            description:
              "Bella Hadid's style is the epitome of contemporary cool, blending high fashion with a rebellious edge.",
            element: (
              <LookbookBanner url="https://imgix.bustle.com/uploads/getty/2024/5/8/f7dc3684-35bc-4ecb-9dd6-5c1ee4100ccb-getty-2151443412.jpg?w=374&h=249&fit=crop&crop=faces&q=50&dpr=2" />
            ),
          },
          {
            title: "Hailey Bieber",
            description:
              "Bella Hadid's style is the epitome of contemporary cool, blending high fashion with a rebellious edge.",
            element: (
              <LookbookBanner url="https://storage.googleapis.com/lookalike_imgs/396841571_18417301864037292_197538001744369039_n.jpg" />
            ),
          },
          {
            title: "Zendaya",
            description:
              "Bella Hadid's style is the epitome of contemporary cool, blending high fashion with a rebellious edge.",
            element: (
              <LookbookBanner url="https://storage.googleapis.com/lookalike_imgs/zendaya_main_1722192461180_20af7f7b-cfad-42c0-b082-57df8678b7a3" />
            ),
          },
        ]}
      />
    </div>
  );
};

const LookbookBanner: React.FC<{ url: string }> = ({ url }) => {
  return (
    <div style={{ padding: "0 16px" }} className="flex justifyCenter">
      <div>
        <img
          src={url}
          alt=""
          style={{
            borderRadius: "6px",
            aspectRatio: 9 / 10,
            objectFit: "cover",
          }}
        />
      </div>
    </div>
  );
};
