import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import React, { ReactNode } from "react";
import s from "./AccordionSelection.module.scss";
export interface AccordionGroup {
  items: { title: ReactNode; content: ReactNode; id: string }[];
}

export const AccordionItem: React.FC<{
  title: string;
  content: ReactNode;
  isOpen: boolean;
  onToggle: () => void;
}> = ({ title, content, isOpen, onToggle }) => {
  return (
    <div className={`${s.item} ${isOpen ? s.open : ""}`}>
      <div className={s.header} onClick={onToggle}>
        <span>{title}</span>
        <div className={s.arrow}>
          <ArrowForwardIosRoundedIcon fontSize="inherit" />
        </div>
      </div>
      <div className={s.content}>{content}</div>
    </div>
  );
};
