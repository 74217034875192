import { MiniProduct } from "../../../../@types/entities/product.types";
import { Button } from "../../../../components/button/Button";
import { ProductCard } from "../../../shop/cmps/productCard/ProductCard";
import s from "./NewIn.module.scss";
export const NewIn = () => {
  return (
    <div className={s.container}>
      <div className={s.details}>
        <p className={s.productsCount}>### products</p>
        <h3>New In</h3>
        <span className={s.description}>
          Get the latest drops from 3,000 brands,
          <br /> tailored to your style
        </span>
      </div>

      <div className={s.products}>
        {[...new Array(10)]
          .map(
            (_) =>
              ({
                title: "Placeholder2",
                brand: "Gucci",
                price: 99.99,
                imgUrls: [
                  "https://media.thereformation.com/image/list/fn_select:jq:first(.%5B%5D%7Cif%20has(%22metadata%22)%20then%20select(any(.metadata%5B%5D;%20.external_id%20==%20%22sfcc-is-main%22%20and%20.value.value%20==%20%22True%22))%20else%20empty%20end)/f_auto,q_auto,dpr_auto/w_600/1310924-MAL.json?_s=RAABAB0%20600w",
                ],
              } as any)
          )
          .map((p) => (
            <ProductCard sumVersion product={p as MiniProduct} />
          ))}
      </div>
      <div className={s.cta}>
        <Button className="flex1" onClick={() => {}}>
          Shop New In
        </Button>
      </div>
    </div>
  );
};
