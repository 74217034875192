import { keys } from "lodash";
import { useEffect, useRef, useState } from "react";
import { BasicQuery } from "../../../@types/query";
import { ProductsGrid } from "../../../components/ProductsGrid/ProductsGrid";
import { Button } from "../../../components/button/Button";
import {
  IntersectionObserverCmp,
  SentinalForObserver,
} from "../../../components/intersector/Intersector";
import { useInfiniteProductSearch } from "../../../hooks/query/product/useProuctsSearch";
import { useFlattenInfiniteQuery } from "../../../hooks/query/useFlattenInfinite";
import { useViewCaller } from "../../../hooks/ui/useWindowCaller";
import { WizardStepTitle } from "../common/commonEventWizard";
import s from "../common/commonEventWizard.module.scss";

export const ResultsView: React.FC<{ query: BasicQuery }> = ({ query }) => {
  const [currentQuery, setQuery] = useState({ ...query });
  const { data, isLoading, isFetching, hasNextPage, fetchNextPage } =
    useInfiniteProductSearch(currentQuery);

  useEffect(() => {
    setQuery(query);
  }, [query]);
  const flattened = useFlattenInfiniteQuery(data);
  const footerRef = useRef<HTMLDivElement>(null);
  const [callView] = useViewCaller();

  const PersonalizeButton = () => {
    return (
      <Button
        onClick={() => {
          callView({
            id: "SearchWeights",
            data: {
              onChange: (w) => setQuery((prev) => ({ ...prev, weights: w })),
              weights: currentQuery.weights,
              weightList: keys(currentQuery),
              payload: {
                ...currentQuery,
              },
            },
          });
        }}
      >
        Personalize
      </Button>
    );
  };

  return (
    <>
      <div className="relative column flex1 overflowHidden">
        <div className="scrollerContainer">
          <div className="column gapLg">
            <WizardStepTitle>Your event's curated feed...</WizardStepTitle>
            <PersonalizeButton />
            <IntersectionObserverCmp
              isInView={() =>
                footerRef.current?.classList.remove(s.stickeyFooter)
              }
              isOutsideView={() =>
                footerRef.current?.classList.add(s.stickeyFooter)
              }
              className="column"
            >
              <SentinalForObserver />
            </IntersectionObserverCmp>
            <div ref={footerRef} className={s.footer}>
              <PersonalizeButton />
            </div>
            <ProductsGrid
              products={flattened}
              isLoading={isLoading}
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetching}
              hasNextPage={hasNextPage!}
            />
          </div>
        </div>
      </div>
    </>
  );
};
