import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import { InputProps } from "../Input";
import { AppTextField } from "../TextField/TextField";

export const Password: React.FC<Omit<InputProps, "type" | "iconEnd">> = ({
  onChange,
  ...props
}) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <AppTextField
      {...props}
      onChange={(ev) => onChange(ev.target.value)}
      type={isVisible ? "text" : "password"}
      theme="light"
      iconEnd={
        <div onClick={() => setIsVisible(!isVisible)}>
          {isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </div>
      }
    />
  );
};
