import React, { useState, useEffect, FC } from "react";
import styles from "./typingDisplay.module.scss";
export const TypingDisplay: FC<{ text: string }> = ({ text }) => {
  const [displayedText, setDisplayedText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setCurrentIndex(0);
  }, [text]);

  useEffect(() => {
    const typingTimer = setInterval(() => {
      if (currentIndex < text.length) {
        setDisplayedText(text.substring(0, currentIndex + 4));
        setCurrentIndex(currentIndex + 4);
      } else {
        clearInterval(typingTimer);
      }
    }, 30);

    return () => clearInterval(typingTimer);
  }, [currentIndex]);

  return (
    <div className={styles.typingDisplay}>
      {displayedText}
      {currentIndex < text.length && "|"}
    </div>
  );
};
