export const mockApi = <T extends any>(data: T, timeout = 500) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(data);
    }, timeout);
  }) as Promise<T>;
};

export const wait = async (timeout: number) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve("");
    }, timeout);
  });
};
