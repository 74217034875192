import { keys } from "lodash";
import {
  Chat,
  ChatIntro,
  ChatMsg,
  MiniChat,
  MockInitialChat,
} from "../../../@types/entities/chat";
import { mockChat, resolveMockMessages } from "../../mock/chat/chat";
import { OpenAIChatMsg } from "../../mock/chat/openAI";
import { api, baseUrl } from "../api";
import { mockApi } from "../utils";

export const getChatById = async (id: string) => {
  return await mockApi<Chat>(mockChat);
};

export const getChatList = async () => {
  return await mockApi<MiniChat[]>(
    [...new Array(10)].map((_, index) => ({
      title: `chat_${index}`,
      id: index + "",
      createdAt: Date.now(),
    }))
  );
};

export const createChat = async (chatMsg: MockInitialChat) => {
  return await mockApi<Chat>({
    createdAt: Date.now(),
    id: Math.random() + "".slice(0, 3),
    lastUpdate: Date.now(),
    title: chatMsg.type,
    messages: resolveMockMessages(chatMsg),
  });
};

export const getChatIntro = (type: ChatIntro) => {
  switch (type) {
    case "fineTuneResult":
      return "";
    case "getSimilar":
      return "find similar products for";
    case "matchLook":
      return "Match products for";
    case "searchProduct":
      return "";
    case "gpt":
      return "";
  }
};

export const postMessage = async (payload: {
  history: OpenAIChatMsg[];
  chatId?: string;
  content: string;
  msgData: any;
}): Promise<ChatMsg> => {
  try {
    const res = await api.calls.post(`${baseUrl}/api/chat/msg`, payload);
    return res.data;
  } catch (error) {
    return {
      sender: "chat",
      content: "Oh no, I encountered a problem! but feel free to try again. ",
    };
  }
};

export const submitChatForTraining = async (
  history: OpenAIChatMsg[]
): Promise<void> => {
  const res = await api.calls.post(`${baseUrl}/api/chat/training_data`, {
    history,
  });
  return res.data;
};

export const getChatOptions = async (): Promise<{ items: string[] }> => {
  try {
    const res = await api.calls.get(`${baseUrl}/api/chat/options`);
    return res.data;
  } catch (error) {
    console.error(error);
    return { items: [] };
  }
};

export const getKeysForSimilarProduct = () => {
  const keysForSimilarProduct = [
    // "back",
    // "bodice",
    // "category",
    // "collar",
    // "cut",
    // "fit",
    // "neckline",
    // "hemline",
    "length",
    // "strap",
    // "silhouette",
    "main_color",
    // "skirt",
    // "sleeve",
    // "texture",
  ];
  return keysForSimilarProduct;
};

export const convertToFeatureSentence = (subject: Record<string, any>) => {
  return keys(subject)
    .filter((key) => !!subject[key])
    .map((key) => `${key.toUpperCase()}:${subject[key].toString()}`)
    .join(" | ");
};
