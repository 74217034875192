import { noop } from "lodash";
import React, { useState } from "react";
import { FormSubmitEvent } from "../../../../@types/events";
import { AppTextField } from "../../../../components/Input/TextField/TextField";
import { Button } from "../../../../components/button/Button";
import { SwipeableWindow } from "../../../../components/slidingWindow/SwipeableWindow";
import s from "./CreateList.module.scss";
export const CreateList: React.FC<{
  isOpen: boolean;
  onSubmit: (title: string) => void;
  onClose: () => void;
}> = ({ isOpen, onSubmit, onClose }) => {
  const [localTerm, setLocalTerm] = useState("");

  const onSubmitCreate: FormSubmitEvent = (ev) => {
    ev.preventDefault();
    if (!localTerm) return;
    onSubmit(localTerm);
    setLocalTerm("");
  };

  return (
    <SwipeableWindow isOpen={isOpen} onClose={onClose} height="100%">
      <div>
        <form action="" className="column gapLg" onSubmit={onSubmitCreate}>
          <div className={s.headerCreate}>
            <span className="flex1" onClick={onClose}>
              Cancel
            </span>
            <h4>New collection</h4>
            <div className="flex1 justifyEnd">
              <Button disabled={!localTerm} form type="tertiary" onClick={noop}>
                Save
              </Button>
            </div>
          </div>
          <div className="column gapSm paddingMd">
            <label className="inputLabel" htmlFor="">
              Name{" "}
            </label>
            <AppTextField
              value={localTerm}
              placeholder="May's Wedding"
              onChange={(ev) => setLocalTerm(ev.target.value)}
            />
          </div>
        </form>
      </div>
    </SwipeableWindow>
  );
};
