import { get, isEmpty, pick } from "lodash";
import { BasicQuery } from "../../../@types/query";
import { ProductSkeleton } from "../../../components/skeleton/ProductSkeleton";
import { useProductsSearch } from "../../../hooks/query/product/useProuctsSearch";
import { PersonalizedWeights } from "../../search/cmps/personalizedWeights/PersonalizedWeights";
import { WizardStepTitle } from "../common/commonEventWizard";
import s from "./StyleAdjuster.module.scss";

const KEYS_FOR_TAG_ENHANCEMENT = [];

const buildFiltersFromQuery = (
  query: BasicQuery,
  styleKey: string,
  extremes?: [string, string]
) => {
  if (!styleKey || !extremes) return query.filters;
  const tags = [];
  if (get(query.weights, styleKey, 0) > 25) {
    tags.push(extremes[1]);
  }
  if (get(query.weights, styleKey, 0) < -25) {
    tags.push(extremes[0]);
  }
  if (isEmpty(tags)) return query.filters;
  const filters = [...(query.filters?.filter((f) => f.key !== "tags") ?? [])];
  filters.push({ key: "tags", value: tags });
  return filters;
};
export const StyleAdjuster: React.FC<{
  query: BasicQuery;
  specificKey: string;
  title?: string;
  extremeValuesForTags?: [string, string];
  onChangeWeight: (key: string, value: number) => void;
}> = ({ query, specificKey, title, onChangeWeight, extremeValuesForTags }) => {
  const { data, isLoading } = useProductsSearch({
    ...query,
    filters: buildFiltersFromQuery(query, specificKey, extremeValuesForTags),
  });
  return (
    <>
      <WizardStepTitle>{title}</WizardStepTitle>
      <div className="scrollerContainer">
        <div className="flex1 column gapXlg justifyCenter">
          <PersonalizedWeights
            onChange={onChangeWeight}
            weightList={[specificKey]}
            weights={pick(query.weights, specificKey)}
            // hideWeight
          />
          <ImgsPreviewsColumns
            isLoading={isLoading}
            imgs={!data ? [] : data.map((p) => p.imgUrls[0])}
            title="Preview"
          />
        </div>
      </div>
    </>
  );
};

export const ImgsPreviewsColumns: React.FC<{
  isLoading: boolean;
  imgs: string[];
  title?: string;
  onClick?: (src: string) => void;
}> = ({ imgs, isLoading, title, onClick }) => {
  return (
    <div className="flex">
      <div className="column gapMd flex1">
        {title && <span style={{ fontSize: "14px" }}>{title}</span>}

        <div
          style={{
            columns: 3,
            breakInside: "avoid-column",
            columnGap: "1em",
          }}
        >
          {isLoading && <ProductSkeleton sumVersion repeat={3} />}
          {!!imgs.length &&
            imgs.map((p, index) => (
              <img
                style={{
                  animationDelay: `0.${index % 3}s`,
                }}
                onClick={() => onClick && onClick(p)}
                className={s.img}
                loading="lazy"
                src={p}
                alt="product"
              />
            ))}
        </div>
      </div>
    </div>
  );
};
