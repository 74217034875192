import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { joinWaitingList, logout } from "../../services/api/users/users.api";
import { appActions } from "../../store/app/slice";

export const useAuth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logoutUser = async () => {
    await logout();
    dispatch(appActions.onSelectUser(null));
    navigate("/");
  };
  return [logoutUser] as const;
};

export const useJoinWaitingList = () => {
  return useMutation({
    mutationFn: async (email: string) => {
      try {
        await joinWaitingList(email);
      } catch (error) {
        throw new Error("Please use a valid email");
      }
    },
    mutationKey: ["join waiting list"],
    retry: false,
  });
};
