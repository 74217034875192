import { FC, useState } from "react";
import { useNavigate } from "react-router";
import { Button } from "../../components/button/Button";
import { ChatSnippet } from "../../components/chatSnippet/ChatSnippet";
import { FormSteps } from "../../components/formSteps/FormSteps";
import { BrandSelectionStep } from "../../models/wizard/styleProfileWizard/steps/BrandSelectionStep";
import { ConnectAccountsStep } from "../../models/wizard/styleProfileWizard/steps/ConnectAccountsStep";
import { UserBasicDataStep } from "../../models/wizard/styleProfileWizard/steps/UserBasicDataStep";
import { DateStyleScenarioType } from "../../models/wizard/styleProfileWizard/steps/styleScenarios/DateStyleScenarioStep";
import { WorkStyleScenarioType } from "../../models/wizard/styleProfileWizard/steps/styleScenarios/WorkStyleScenarioStep";
import styles from "./wizard.module.scss";

export const WizardPage: FC = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState<any>({});
  const [steps, setSteps] = useState([
    new BrandSelectionStep(),
    new UserBasicDataStep(),
    new DateStyleScenarioType(),
    new WorkStyleScenarioType(),
    new ConnectAccountsStep(),
  ]);
  const [selectedStepIndex, setSelectedStepIndex] = useState(0);

  const selectedStep = steps[selectedStepIndex];
  const CMP = selectedStep.Content;

  const onNextStep = () => {
    // TODO: if not disabled
    if (selectedStepIndex === steps.length - 1) {
      navigate("../shop");
    } else {
      setSelectedStepIndex(selectedStepIndex + 1);
    }
  };

  return (
    // TODO: UNACCEPTABLE. CREATE A withPage Pure component
    <div className={styles.container}>
      <div className={styles.stepsContainer}>
        <FormSteps stepsCount={steps.length} currentStep={selectedStepIndex} />
      </div>
      <div className={styles.formBody}>
        <div className={styles.snippetContainer}>
          <ChatSnippet
            text={`${
              !selectedStepIndex
                ? "Hi, My name is Jennny. I am an AI powered search engine and i am here to provide a personalized shopping experience.I will ask you a few questions to understand you a bit better. "
                : " "
            }${selectedStep.getIntro()}`}
          />
        </div>
        <div className="column flex1">
          <CMP
            data={form[selectedStep.name] ?? selectedStep.getInitialForm()}
            onChange={(data) => setForm({ ...form, [selectedStep.name]: data })}
            {...selectedStep.getAdditionalProps()}
          />
        </div>
      </div>
      <div className={styles.navigation}>
        <Button
          type="secondary"
          onClick={() => setSelectedStepIndex(selectedStepIndex - 1)}
          disabled={selectedStepIndex === 0}
        >
          Back
        </Button>
        <Button
          // disabled={!selectedStep.isStepComplete(form[selectedStep.name])}
          onClick={onNextStep}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
