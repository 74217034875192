import { CSSProperties, useRef } from "react";

export const useWindowUiManager = () => {
  const style = useRef<CSSProperties>({ transition: "0.4s" });
  const onOpen = () => {
    style.current = {
      ...style.current,
      transform: `translateX(-100px)`,
    };
  };

  const onClose = () => {
    style.current = {
      ...style.current,
      transform: `translateX(0px)`,
    };
  };

  return [style, onOpen, onClose] as const;
};
