import { useMutation } from "react-query";
import { queryClient } from "../../..";
import { List } from "../../../@types/entities/list";
import { createList } from "../../../services/api/lists/lists.api";

export const useCreateList = () =>
  useMutation({
    mutationFn: async (list: Omit<List, "_id">) => createList(list),
    mutationKey: ["createList"],
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["lists"] }),
  });
