import {
  MouseEventHandler,
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AppContext } from "../../App";
import { ReactComponent as Logo } from "../../styles/icons/logo.svg";
import { Search } from "../Input/Search/Search";
import { Back } from "../back/Back";
import { useHeaderActions } from "../header/useHeaderActions";
import {
  IntersectionObserverCmp,
  SentinalForObserver,
} from "../intersector/Intersector";
import s from "./SearchHeader.module.scss";
import { SearchSuggestions } from "./cmps/searchSuggestions/SearchSuggestions";

export interface SearchHeaderProps {
  isSearchMode: boolean;
  onSearch: (s: string) => void;
  onBack: () => void;
  local?: boolean;
  fullMode?: boolean;
  displaySuggestions?: boolean;
  withLogo?: boolean;
  withoutStickyHeader?: boolean;
}

export const SearchHeader = forwardRef<HTMLInputElement, SearchHeaderProps>(
  (
    {
      onSearch,
      onBack,
      local,
      isSearchMode,
      fullMode,
      withLogo = false,
      displaySuggestions,
      withoutStickyHeader = false,
    },
    ref
  ) => {
    const [searchInputActive, setSearchInputActive] = useState(false);
    const { searchTerm } = useHeaderActions();
    const [localTerm, setLocalTerm] = useState(local ? "" : searchTerm);
    const { isMobile } = useContext(AppContext);
    const headerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      if (!local) {
        setLocalTerm(searchTerm);
      }
    }, [searchTerm]);

    const handleSearchBackClick: MouseEventHandler = (ev) => {
      ev.stopPropagation();
      if (searchInputActive && localTerm) {
        setSearchInputActive(false);
        setLocalTerm(searchTerm);
      } else {
        setSearchInputActive(false);
        onBack();
      }
    };

    const isOpenSearch = searchInputActive || !!fullMode;
    return (
      <>
        <div
          style={{
            marginTop: isMobile ? "42px" : "0px",
            // position: "relative",
            height: withLogo ? "112px" : "54px",
          }}
        >
          <div
            className={`${s.searchContainer} ${isMobile ? s.app : ""}
        ${searchInputActive || fullMode ? s.full : ""}

        `}
          >
            <div
              style={{
                backgroundColor: "white",
                paddingTop: isMobile ? "42px" : "0px",
              }}
            >
              {withLogo && (
                <div
                  className={`${s.logoHeader} ${isOpenSearch ? s.open : ""}`}
                >
                  <div style={{ paddingTop: "16px" }}>
                    <div className="justifyCenter">
                      <Logo height={25} width={50} />
                    </div>
                  </div>
                </div>
              )}

              <div className={s.inputContainer}>
                <div
                  onClick={handleSearchBackClick}
                  className={`${s.back} ${isSearchMode ? s.active : ""}`}
                >
                  <Back />
                </div>
                <Search
                  ref={ref}
                  onChange={(val) => setLocalTerm(val)}
                  value={localTerm}
                  onSearch={() => {
                    setSearchInputActive(false);
                    onSearch(localTerm);
                  }}
                  onClear={() => setSearchInputActive(false)}
                  onFocus={() => setSearchInputActive(true)}
                  placeholder="How do you want to dress up?"
                  theme="light"
                />
              </div>
            </div>

            <IntersectionObserverCmp
              isInView={() => {
                headerRef.current?.classList.remove("show");
              }}
              isOutsideView={() => {
                headerRef.current?.classList.add("show");
              }}
            >
              <SentinalForObserver />
            </IntersectionObserverCmp>
            <div className="relative flex1">
              <div
                className={`${s.searchSuggestionsContainer} ${
                  !!isOpenSearch ? s.open : ""
                }`}
              >
                <SearchSuggestions
                  // list={SEARCH_OPTIONS}
                  onSearch={(o: string) => {
                    setSearchInputActive(false);
                    onSearch(o);
                  }}
                  isOpen={!!isOpenSearch}
                />
              </div>
            </div>
          </div>
        </div>
        {!withoutStickyHeader && (
          <div
            style={{ backgroundColor: "white" }}
            ref={headerRef}
            className="stickyHeader"
          >
            <SearchHeader
              onSearch={onSearch}
              onBack={onBack}
              local={local}
              isSearchMode={isSearchMode}
              fullMode={fullMode}
              withoutStickyHeader
            />
          </div>
        )}
      </>
    );
  }
);
