import axios from "axios";
import { toLower } from "lodash";
import QueryString from "qs";
import { User } from "../../../@types/entities/user";
import { SignInDetails } from "../../../pages/onboarding/OnboardPage";
import { store } from "../../../store";
import { api, baseUrl } from "../api";

export const avatarProfiles: User[] = [
  {
    id: "65ae01cd405af2d533d77a29",
    name: "Viki",
    job: "Partner in a law firm",
    age: 40,
    location: "Upper east side Manhattan, NYC",
    description:
      "Viki is a lawyer and most of the time she is in meetings and needs to wear formal clothes. she blah blah b",
    img: "https://i.pinimg.com/474x/97/08/7f/97087fd609ac038322947306ff054e75.jpg",
    tag: "avatarViki",
  },
  {
    id: "65adfe1689c175debf8dee08",
    name: "Maya",
    job: "Yoga teacher",
    age: 28,
    location: "Tel aviv",
    description:
      "Maya is a yoga teacher and most of the time she wears athletic and casual clothes during her classes and time outside with friends. blah blah blah ",
    img: "https://i.pinimg.com/280x280_RS/52/4b/d2/524bd2f56a9ab3d456445f0be789c9f1.jpg",
    tag: "avatarMaya",
  },
  {
    id: "avatarHailey",
    name: "Haily",
    job: "Student at UCLA",
    location: "Los Angeles",
    age: 19,
    description:
      "Hailey is a college student and spends most of her time studying or partying. blah blah blah",
    img: "https://i.dailymail.co.uk/1s/2023/01/09/17/66367859-11615217-By_the_time_she_was_nine_she_had_made_it_to_regionals_and_at_age-a-185_1673287027690.jpg",
    tag: "avatarHailey",
  },
];

export const getPresetUsers = async (): Promise<{
  items: User[];
  error?: string;
}> => {
  try {
    const res = await axios.get(`${baseUrl}/api/users/presets`);
    return res.data;
  } catch (e) {
    console.error(e);
    return { error: "failed to fetch users", items: [] };
  }
};
export const getUsers = async (): Promise<{
  items: User[];
  error?: string;
}> => {
  try {
    const res = await axios.get(`${baseUrl}/api/users`);
    return res.data;
  } catch (e) {
    console.error(e);
    return { error: "failed to fetch users", items: [] };
  }
};

export const editUser = (user: User) => {
  return user._id ? updateUser(user) : createUser(user);
};

async function updateUser(user: User): Promise<{ profile: User }> {
  try {
    const res = await axios.put(`${baseUrl}/api/users`, {
      ...user,
    });
    return res.data;
  } catch (e) {
    console.error(e);
    throw new Error("Failed Updating user");
  }
}

async function createUser(user: User): Promise<{ profile: User }> {
  try {
    const res = await axios.post(`${baseUrl}/api/users`, {
      ...user,
    });
    return res.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
}
export async function setCurrentUser(
  userId: string
): Promise<{ error?: string; id: string }> {
  try {
    // TODO: MASSIVE SECURITY HOLE : DO THIS IN LOGIN!!!!!!!!!
    const res = await axios.put(`${baseUrl}/api/users/current_user`, {
      userId,
      deviceId: store.getState().app.deviceId,
    });

    api.setJWT(res.data.token);
    return res.data;
  } catch (e) {
    console.error(e);
    return { error: "failed to update user", id: "" };
  }
}

export const authForDevice = async (deviceId: string) => {
  const res = await axios.get(`${baseUrl}/api/users/device/${deviceId}`);
  return res.data.user;
};
export const setAuthForDevice = async (deviceId: string, userId: string) => {
  const res = await axios.post(`${baseUrl}/api/users/device/${deviceId}`, {
    userId,
  });
  return res.data;
};

export const logout = async () => {
  const res = await api.calls.post(`${baseUrl}/api/users/logout`);
  api.destroyJWT();
  return null;
};

export const deleteAccount = async () => {
  const res = await api.calls.delete(`${baseUrl}/api/users`);
  return res;
};

export const login = async (form: SignInDetails) => {
  const res = await api.calls.post(`${baseUrl}/api/users/login`, {
    form: { ...form, username: toLower(form.username).trim() },
    deviceId: store.getState().app.deviceId,
  });
  api.setJWT(res.data.token);
  return res.data;
};

export const verifyResetToken = async (token?: string) => {
  const res = await api.calls.post(
    `${baseUrl}/api/users/verify_reset_token/${token}`
  );
  return res.data;
};
export const resetPassword = async (password: string, token: string) => {
  const res = await api.calls.post(
    `${baseUrl}/api/users/reset_password/${token}`,
    { password }
  );
  return res.data;
};
export const sendForgotPassword = async (email: string) => {
  const res = await api.calls.post(`${baseUrl}/api/users/forgot_password`, {
    email,
  });
  return res.data;
};

export const joinWaitingList = async (email: string) => {
  const stringified = QueryString.stringify({ email });
  const res = await api.calls.post(
    `${baseUrl}/api/users/waitlist?${stringified}`
  );
};
