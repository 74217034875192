import React, { FC } from "react";
import { BaseFormContentProps } from "../../../../../../@types/form";
import styles from "./connectAccountsView.module.scss";
interface ConnectAccountProps extends BaseFormContentProps {}

export const ConnectAccountsView: FC<ConnectAccountProps> = () => {
  return (
    <div className="column gapLg centerAll">
      {["Google", "Pinterest", "Facebook", "Instagram", "Tiktok"].map(
        (account) => (
          <div className={styles.account}>{account}</div>
        )
      )}
    </div>
  );
};
