import { Link } from "react-router-dom";
import s from "./AdminMenu.module.scss";

export const AdminMenu = () => {
  return (
    <div className={s.menu}>
      <div className={s.item}>
        <Link to="./lookalikes">Lookalikes</Link>
      </div>
      <div className={s.item}>
        <Link to="./product-parsing">Product Parsing</Link>
      </div>
      <div className={s.item}>
        <Link to="./instagram-app">InstagramApp</Link>
      </div>
    </div>
  );
};
