import { useInfiniteQuery, useQuery } from "react-query";
import {
  getLookalikeParsingFilterOptions,
  getLookalikeParsings,
  getLookalikeParsingValues,
  getLookalikeProducts,
} from "../../../services/api/admin/lookalikes.admin";
import { getNextSkip } from "../../../utils/reactQuery";

export const useLookalikesParsing = (filters: any) => {
  return useInfiniteQuery({
    queryKey: ["adminLookalikesParsing", filters],
    queryFn: async ({ pageParam }) => {
      const res = await getLookalikeParsings(filters);
      return res.items;
    },
    getNextPageParam: getNextSkip,
  });
};

export const useLookalikesParsingValues = () =>
  useQuery({
    queryKey: ["lookalikeParsingValues"],
    queryFn: async () => {
      const res = await getLookalikeParsingValues();
      return res.values;
    },
  });
export const useLookalikeParsingFilterOptions = () =>
  useQuery({
    queryKey: ["useLookalikeParsingFilterOptions"],
    queryFn: async () => {
      const res = await getLookalikeParsingFilterOptions();
      return res;
    },
  });
export const useAdminLookalikeProducts = (id: string) =>
  useQuery({
    queryKey: ["adminLookalikeProducts", id],
    queryFn: async () => {
      const res = await getLookalikeProducts(id);
      return res;
    },
    enabled: !!id,
  });
