import React, { FC } from "react";
import { Avatar } from "../avatar/Avatar";
import { TypingDisplay } from "../typingDisplay/TypingDisplay";
import s from "./chatSnippet.module.scss";
import { Button } from "../button/Button";
import { ReactComponent as Gpt } from "../../styles/assets/gpt.svg";
export const ChatSnippet: FC<{
  text: string;
  onOpenChat?: () => void;
  cta?: string;
}> = ({ text, onOpenChat, cta }) => {
  return (
    <div className={s.chatSnippet}>
      <div className={s.content}>
        <Avatar initials="J" />

        <TypingDisplay text={text} />
      </div>
      {onOpenChat && (
        <div className={s.openChat}>
          <Button onClick={onOpenChat} icon="">
            <div className="alignCenter gapSm">
              <Gpt width={16} />
              {cta ?? "refine results"}
            </div>
          </Button>
        </div>
      )}
    </div>
  );
};
