export const COLOR_MAP = {
  white: "white",
  pink: "pink",
  black: "black",
  blue: "#007BFF",
  brown: "#8B4513",
  "light blue": "lightblue",
  green: "#008000",
  coral: "#FF7F50",
  red: "#FF0000",
  yellow: "#FFFF00",
  silver: "#C0C0C0",
  multi: "linear-gradient(45deg, #FF0080, #FF8C00, #40E0D0, #8A2BE2, #FF00FF)",
  "light pink": "#FFB6C1",
  navy: "#000080",
  magenta: "#FF00FF",
  teal: "#008080",
  orange: "#FFA500",
  grey: "#808080",
  purple: "#800080",
  aqua: "#00FFFF",
  mauve: "#E0B0FF",
  maroon: "#800000",
  ivory: "#FFFFF0",
  peachpuff: "#FFDAB9",
  turquoise: "#40E0D0",
  "sage green": "#9DC183",
  "light green": "lightgreen",
  pewter: "#96A8A1",
  multicolor: "multi",
  "hot pink": "#FF69B4",
  "light brown": "#B5651D",
  scarlet: "#FF2400",
  "primary-red": "#D0021B",
  burgundy: "#800020",
  "navy blue": "#001F3F",
  "cobalt blue": "#0047AB",
  "pastel blue": "#AEC6CF",
  emerald: "#50C878",
  olive: "#808000",
  "pastel green": "#77DD77",
  lime: "#A6E22E",
  "mustard yellow": "#FFDB58",
  "lemon yellow": "#FFF44F",
  "pastel yellow": "#FAFAD2",
  gold: "#FFD700",
  "black-metalic": "#43464B",
  "rose-gold": "#B76E79",
  lavender: "#E6E6FA",
  plum: "#8E4585",
  violet: "#8A2BE2",
  "carrot orange": "#ED9121",
  peach: "#FFDAB9",
  fuchsia: "#FF00FF",
  "blush pink": "#FFB6C1",
  "chocolate brown": "#7B3F00",
  beige: "#F5F5DC",
  "charcoal gray": "#36454F",
  "light grey": "#D3D3D3",
  cream: "#FFFDD0",
};

export const COLOR_NAMES = [
  "white",
  "beige",
  "yellow",
  "gold",
  "peach",
  "orange",
  "pink",
  "red",
  "burgundy",
  "maroon",
  "lavender",
  "purple",
  "blue",
  "light blue",
  "navy",
  "aqua",
  "turquoise",
  "green",
  "brown",
  "grey",
  "silver",
  "black",
  "multicolor",
];
