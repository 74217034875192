import { useState } from "react";
import { useNavigate } from "react-router";
import { List } from "../../../../@types/entities/list";
import { Button } from "../../../../components/button/Button";
import { SwipeableWindow } from "../../../../components/slidingWindow/SwipeableWindow";
import { useSnackbar } from "../../../../contexts/snackbar";
import { useCreateList } from "../../../../hooks/query/lists/useCreateList";
import { useLists } from "../../../../hooks/query/lists/useLists";
import { ReactComponent as Checked } from "../../../../styles/icons/checked.svg";
import { CreateList } from "../createList/CreateList";
import s from "./ListSavingSelection.module.scss";

import { last, size } from "lodash";
import { useToggleToList } from "../../../../hooks/query/lists/useAddToList";

export const ListSavingSelection: React.FC<{
  productId: string;
  onClose: () => void;
  isOpen: boolean;
}> = ({ isOpen, productId, onClose }) => {
  const { data: lists, isLoading } = useLists();

  const { openSnackbar } = useSnackbar();
  const [windowName, setWindowName] = useState<"create" | null>(null);
  const onNewList = () => {
    setWindowName("create");
  };
  const navigate = useNavigate();
  const { mutateAsync: createList } = useCreateList();
  const { mutateAsync: toggleToList } = useToggleToList();

  const onSubmitNewList = (title: string) => {
    createList({ title: title, savedProducts: [] });
    setWindowName(null);
  };

  const onToggleSaveToList = async (list: List) => {
    await toggleToList({
      listId: list._id,
      productIds: [productId],
      action: list.savedProducts.some((p) => p.id === productId)
        ? "remove"
        : "add",
    });

    // openSnackbar(
    //   <div
    //     style={{
    //       display: "flex",
    //       justifyContent: "space-between",
    //       alignItems: "center",
    //     }}
    //   >
    //     Saved this product to {list.title}{" "}
    //     <Button
    //       theme="light"
    //       type="tertiary"
    //       onClick={() => navigate("/lists")}
    //     >
    //       View
    //     </Button>{" "}
    //   </div>
    // );
  };

  return (
    <>
      <SwipeableWindow
        isOpen={isOpen}
        onClose={onClose}
        title="Save to a list"
        height={(lists?.items.length ?? 0) > 3 ? "70%" : "40%"}
      >
        <>
          <div className="justifyEnd paddingMd">
            <Button onClick={() => onNewList()}>New list</Button>
          </div>
          <div className="column gapMd paddingMd scrollerContainer">
            {lists?.items.map((list) => (
              <div
                className="alignCener"
                onClick={() => onToggleSaveToList(list)}
              >
                <ListPreview
                  isSelected={list.savedProducts.some(
                    (p) => p.id === productId
                  )}
                  list={list}
                />
              </div>
              // <div
              //   onClick={() => onToggleSaveToList(list)}
              //   className={`paddingMd alignCenter spaceBetween`}
              // >
              //   <span className="weight600">{list.title}</span>
              //   <div className={s.iconContainer}>
              //     <div className="icon">
              //       {list.savedProducts.some((p) => p.id === productId) && (
              //         <CheckCircleIcon />
              //       )}
              //     </div>
              //   </div>
              // </div>
            ))}
          </div>
        </>
      </SwipeableWindow>
      <CreateList
        onSubmit={onSubmitNewList}
        isOpen={windowName === "create"}
        onClose={() => setWindowName(null)}
      />
    </>
  );
};

const ListPreview: React.FC<{ list: List; isSelected: boolean }> = ({
  list,
  isSelected,
}) => {
  return (
    <div className={s.listPreview}>
      <div className="flex gapLg">
        {last(list.savedProducts)?.thumbnail && (
          <img src={last(list.savedProducts)?.thumbnail} alt="product" />
        )}
        <div className="column gapMd ">
          <h4>{list.title}</h4>
          {size(list.savedProducts) > 0 && (
            <span className="small-text">
              {size(list.savedProducts)} item
              {size(list.savedProducts) > 1 ? "s" : ""}
            </span>
          )}
        </div>
      </div>
      {isSelected && <Checked width={24} height={24} />}
    </div>
  );
};
