import { isEmpty } from "lodash";
import { useQuery } from "react-query";
import { getProfilePicks } from "../../../services/api/users/wizard/wizard.api";

export const useProfilePicks = (brandIds: string[], enabled?: boolean) => {
  return useQuery({
    queryFn: async () => {
      const res = await getProfilePicks(brandIds);
      return res.items;
    },
    staleTime: 60 * 1000 * 2,
    cacheTime: 60 * 1000 * 2,
    queryKey: ["profilePicks", ...brandIds],
    enabled: !isEmpty(brandIds) && !!enabled,
  });
};
