import { FC } from "react";
import { BaseStyleProfileStep } from "../../BaseStyleProfileStep";
import { BrandSearchAndRate } from "../components/brandSearchAndRate/BrandSearchAndRate";
import { isEmpty, values } from "lodash";
import { ImgSelection } from "../../../../../components/imgSelection/ImgSelection";

export abstract class BaseStyleScenarioStep extends BaseStyleProfileStep {
  Content = ImgSelection;

  constructor() {
    super();
  }

  isStepComplete = (data: any) => !isEmpty(data);
  getIntro = () =>
    "select the one or more outfits you would consider in the following scenario";
  getInitialForm = () => [];
}
