import { Link } from "react-router-dom";

export const SupportPage = () => {
  return (
    <div className="column gapLg">
      <h3>Contact Support</h3>
      <div className="column gapMd">
        <div>
          <Link style={{ color: "grey" }} to="mailto:raphael@dressify.online">
            raphael@dressify.online
          </Link>
        </div>
        <div>
          <Link style={{ color: "grey" }} to="tel:+1 718 300 7851">
            +1 718 300 7851
          </Link>
        </div>
      </div>
    </div>
  );
};
