import Tag from "@mui/icons-material/LocalOfferOutlined";
import { GigiTitle } from "../../../../components/gigiTitle/GigiTitle";
import { ProductsGrid } from "../../../../components/ProductsGrid/ProductsGrid";
import { SlidingWindow } from "../../../../components/slidingWindow/SlidingWindow";
import { useSuggestedBrands } from "../../../../hooks/query/brands/useBrands";
import { useInfiniteProductSearch } from "../../../../hooks/query/product/useProuctsSearch";
import { useFlattenInfiniteQuery } from "../../../../hooks/query/useFlattenInfinite";
import { useDelayedClose } from "../../../../hooks/ui/useDelayedClose";
import { ReactComponent as Arrow } from "../../../../styles/icons/ArrowRight.svg";
import { ReactComponent as Dollar } from "../../../../styles/icons/Dollar.svg";
import { BrandPreview } from "../home/BrandsPreview";
import s from "./BrandsView.module.scss";

export const BrandsView: React.FC = () => {
  const { data } = useSuggestedBrands({ limit: 25 });
  const { onOpen, onClose, isOpen, data: windowData } = useDelayedClose();
  const brands = useFlattenInfiniteQuery(data);
  const {
    data: productPages,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteProductSearch(
    { filters: [{ key: "brand", value: windowData?.data }], limit: 25 },
    !!windowData?.data
  );
  const products = useFlattenInfiniteQuery(productPages);

  return (
    <>
      <div style={{ padding: "0 16px" }}>
        <GigiTitle
          title="Discover New Brands"
          description="Gigi helps you discover new brands and styles you might love."
        />
      </div>
      <div className={s.container}>
        {brands.map((b, index) => (
          <div style={{ animationDelay: `${index / 30}s` }} className={s.brand}>
            <div className="column gapMd">
              <div className="alignCenter spaceBetween">
                <div className={s.titleHeader}>{b.name}</div>
                <Arrow />
              </div>
              <div
                style={{ color: "#737373", fontSize: "12px" }}
                className={"alignCenter gapSm"}
              >
                <div className="flex alignCenter">
                  <Dollar />
                  {[75, 200, 500].map((limit) =>
                    limit < b.avg ? <Dollar /> : null
                  )}
                </div>
                |
                <div className="alignCenter gapSm">
                  {b.tags &&
                    b.tags.map((t: string) => (
                      <div
                        style={{
                          fontSize: "12px",
                          color: "#737373",
                          gap: "2px",
                          fontWeight: 500,
                        }}
                        className="alignCenter "
                      >
                        <Tag fontSize="inherit" sx={{ color: "#737373" }} />
                        {t}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className={s.preview}>
              <BrandPreview onOpen={onOpen} brand={b} />
            </div>
          </div>
        ))}
      </div>
      <SlidingWindow
        isOpen={isOpen}
        onClose={() => onClose()}
        title={windowData?.name}
        direction="right"
      >
        <div className="sideMargin">
          <ProductsGrid
            products={products}
            isLoading={isLoading}
            isFetchingNextPage={!!isFetchingNextPage}
            fetchNextPage={fetchNextPage}
            hasNextPage={!!hasNextPage}
          />
        </div>
      </SlidingWindow>
    </>
  );
};
