import React from "react";
import styles from "./Logo.module.scss";

export const Logo: React.FC<{
  type?: "header" | "placeholder";
  onClick?: () => void;
}> = ({ type, onClick }) => {
  return (
    <div
      className={`${styles.logo} ${styles[type ?? ""] ?? ""}`}
      onClick={onClick}
    >
      S
    </div>
  );
};
