import { entries, shuffle, omit } from "lodash";
import {
  MiniProduct,
  ProductDetails,
  ProductSection,
} from "../../@types/entities/product.types";
import { VendorOffer } from "../../@types/entities/vendor";

export interface MockMiniProduct extends Omit<MiniProduct, "price"> {
  price: {
    amount?: number;
    currency?: string;
  };
}
export const mockPlaceholders: MockMiniProduct[] = [...new Array(20)].map(
  (_, index) => ({
    id: index + "",
    price: { amount: 74.99, currency: "USD" },
    brand: "Adidas",
    recommendedSize: "M",
    imgUrls: [
      "https://images.asos-media.com/products/lace-beads-exclusive-corset-tulle-maxi-dress-in-pink/204807730-1-pink?$n_640w$&wid=634&fit=constrain",
    ],
    title: "Summer dress cool",
    vendors: [
      {
        origin: "",
        name: "Asos.com",
        thumbnailUrl:
          "https://content.asos-media.com/-/media/images/asos/logo/icon_svg.svg",
        url: "https://www.asos.com/lace-beads/lace-beads-exclusive-corset-tulle-maxi-dress-in-pink/prd/204807730?colourWayId=204807731&SearchQuery=summer+dress",
      },
    ],
  })
);

export const MockWeddingDress: MockMiniProduct[] = [
  {
    title: "ULLE DRESS WITH RUFFLES",
    price: { amount: 89.99, currency: "" },
    vendors: [
      {
        name: "",
        url: "https://www.zara.com/il/en/",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://static.zara.net/photos///2023/I/0/1/p/8936/113/800/2/w/563/8936113800_1_1_1.jpg?ts=1699462774161",
      "https://static.zara.net/photos///2023/I/0/1/p/8936/113/800/2/w/563/8936113800_2_2_1.jpg?ts=1699462774898",
      "https://static.zara.net/photos///2023/I/0/1/p/8936/113/800/2/w/563/8936113800_2_4_1.jpg?ts=1699462774029",
    ],
    brand: "Zara",
    id: "3T67Q",
    recommendedSize: "",
    tags: ["black", "dress", "wedding"],
  },
  {
    title: "LANDSCAPE VIEW MAXI DRESS GREEN",
    price: { amount: 99.99, currency: "" },
    vendors: [
      { name: "", url: "hellomolly.com", thumbnailUrl: "", origin: "" },
    ],
    imgUrls: [
      "https://www.hellomolly.com/cdn/shop/products/220203HM1143_1645050893_1080x.jpg?v=1690961161",
      "https://www.hellomolly.com/cdn/shop/products/220203HM1140_1645050893_1800x1800.jpg?v=1690961161",
      "https://www.hellomolly.com/cdn/shop/products/220203HM1140_1645050893_1800x1800.jpg?v=1690961161",
    ],
    brand: "RalphLauren",
    id: "9LQVK",
    recommendedSize: "",
  },
  {
    title: "x REVOLVE Samantha Dress",
    price: { amount: 180.3275, currency: "" },
    vendors: [
      {
        name: "",
        url: "https://www.onegreyday.com/",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/ONEG-WD11_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/ONEG-WD11_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/ONEG-WD11_V3.jpg",
    ],
    brand: "RalphLauren",
    id: "9WYWK",
    recommendedSize: "",
  },
  {
    title: "DRAPED MIDI DRESS\r\n\r",
    price: { amount: 35.99, currency: "" },
    vendors: [
      {
        name: "",
        url: "https://www.zara.com/il/en/",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://static.zara.net/photos///2023/I/0/1/p/3152/206/706/2/w/563/3152206706_1_1_1.jpg?ts=1699547277806",
      "https://static.zara.net/photos///2023/I/0/1/p/3152/206/706/2/w/563/3152206706_2_1_1.jpg?ts=1699547277662",
      "https://static.zara.net/photos///2023/I/0/1/p/3152/206/706/2/w/563/3152206706_2_1_1.jpg?ts=1699547277662",
    ],
    brand: "Zara",
    id: "UFNIK",
    recommendedSize: "",
  },
  {
    title: "x REVOLVE Alaia Dress\r",
    price: { amount: 182.25, currency: "" },
    vendors: [
      { name: "", url: "https://elliatt.com/", thumbnailUrl: "", origin: "" },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/ELLI-WD473_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/ELLI-WD473_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/ELLI-WD473_V3.jpg",
    ],
    brand: "RalphLauren",
    id: "RL9K4",
    recommendedSize: "",
  },
  {
    title: "Dress 691",
    price: { amount: 189.9175, currency: "" },
    vendors: [
      { name: "", url: "https://elliatt.com/", thumbnailUrl: "", origin: "" },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/LPAR-WD216_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/LPAR-WD216_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/LPAR-WD216_V3.jpg",
    ],
    brand: "Mango",
    id: "IHQQH",
    recommendedSize: "",
  },
  {
    title: "Tara Gown\r",
    price: { amount: 285.75, currency: "" },
    vendors: [
      {
        name: "",
        url: "https://www.revolve.com/content/lp/lpa",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/KATR-WD356_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/KATR-WD356_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/KATR-WD356_V3.jpg",
    ],
    brand: "Zara",
    id: "U4KC9",
    recommendedSize: "",
  },
  {
    title: "x REVOLVE Decker Maxi Dress",
    price: { amount: 166.8975, currency: "" },
    vendors: [
      {
        name: "",
        url: "https://michaelcostello.us/",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/MELR-WD840_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/MELR-WD840_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/MELR-WD840_V3.jpg",
    ],
    brand: "RalphLauren",
    id: "6MX7L",
    recommendedSize: "",
  },
  {
    title: "Ruffled floral print dress",
    price: { amount: 79.99, currency: "" },
    vendors: [{ name: "", url: "", thumbnailUrl: "", origin: "" }],
    imgUrls: [
      "https://st.mngbcn.com/rcs/pics/static/T2/fotos/S20/27027128_70.jpg?ts=1647255505472&imwidth=417&imdensity=2",
      "https://st.mngbcn.com/rcs/pics/static/T2/fotos/outfit/S20/27027128_70-99999999_01.jpg?ts=1647258828347&imwidth=417&imdensity=2",
      "https://st.mngbcn.com/rcs/pics/static/T2/fotos/S20/27027128_70_R.jpg?ts=1647255505472&imwidth=417&imdensity=2",
    ],
    brand: "Mango",
    id: "MQITA",
    recommendedSize: "",
  },
  {
    title: "Rebel Slip\r",
    price: { amount: 99.99, currency: "" },
    vendors: [{ name: "", url: "", thumbnailUrl: "", origin: "" }],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/LOVF-WD1675_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/LOVF-WD1675_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/LOVF-WD1675_V3.jpg",
    ],
    brand: "Sundress",
    id: "Q5OCU",
    recommendedSize: "",
  },
  {
    title: "Juliana Dress",
    price: { amount: 145.795, currency: "" },
    vendors: [{ name: "", url: "", thumbnailUrl: "", origin: "" }],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/SDRE-WD157_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/SDRE-WD157_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/SDRE-WD157_V3.jpg",
    ],
    brand: "Sundress",
    id: "K5BMO",
    recommendedSize: "",
  },
];

export const mockBlackDressSimilar: MockMiniProduct[] = [
  {
    title: "ULLE DRESS WITH RUFFLES",
    price: { amount: 89.99, currency: "" },
    vendors: [
      {
        name: "",
        url: "https://www.zara.com/il/en/",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://static.zara.net/photos///2023/I/0/1/p/8936/113/800/2/w/563/8936113800_1_1_1.jpg?ts=1699462774161",
      "https://static.zara.net/photos///2023/I/0/1/p/8936/113/800/2/w/563/8936113800_2_2_1.jpg?ts=1699462774898",
      "https://static.zara.net/photos///2023/I/0/1/p/8936/113/800/2/w/563/8936113800_2_4_1.jpg?ts=1699462774029",
    ],
    brand: "Zara",
    id: "UJ841",
    recommendedSize: "",
    tags: ["black", "dress", "wedding"],
  },
  {
    title: "Semi-transparent panel ruffle dress",
    price: { amount: 120.0, currency: "" },
    vendors: [
      {
        name: "",
        url: "https://shop.mango.com/gb/home?gad_source=1&gclid=CjwKCAiAgeeqBhBAEiwAoDDhnz5jdVOv5hhmqnPuWAhvw4nmZytXXdk9FwPHH4GX5nCRBnYUfM3o5RoCpGoQAvD_BwE&gclsrc=aw.ds",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://st.mngbcn.com/rcs/pics/static/T5/fotos/S20/57079113_99.jpg?ts=1699371176881&imwidth=417&imdensity=2",
      "https://st.mngbcn.com/rcs/pics/static/T5/fotos/outfit/S20/57079113_99-99999999_01.jpg?ts=1699371176881&imwidth=417&imdensity=2",
      "https://st.mngbcn.com/rcs/pics/static/T5/fotos/S20/57079113_99_R.jpg?ts=1699371176881&imwidth=417&imdensity=2",
    ],
    brand: "Mango",
    id: "HTG5O",
    recommendedSize: "",
    tags: ["black", "dress", "wedding"],
  },
  {
    title: "Asymmetric-hem Slip Dress",
    price: { amount: 67.99, currency: "" },
    vendors: [
      {
        name: "",
        url: "https://www.hm.com/hw_il/index",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://lp2.hm.com/hmgoepprod?set=format%5Bwebp%5D%2Cquality%5B79%5D%2Csource%5B%2F92%2F8e%2F928e80a348ca7a442670ddf00e3c3b9011fd0e3c.jpg%5D%2Corigin%5Bdam%5D%2Ccategory%5Bladies_dresses_maxidresses%5D%2Ctype%5BDESCRIPTIVESTILLLIFE%5D%2Cres%5Bm%5D%2Chmver%5B2%5D&call=url%5Bfile%3A%2Fproduct%2Fmain%5D",
      "https://lp2.hm.com/hmgoepprod?set=format%5Bwebp%5D%2Cquality%5B79%5D%2Csource%5B%2Fa0%2F9c%2Fa09ce9622cc977837a783070c4124e4527a8c260.jpg%5D%2Corigin%5Bdam%5D%2Ccategory%5B%5D%2Ctype%5BLOOKBOOK%5D%2Cres%5Bm%5D%2Chmver%5B1%5D&call=url%5Bfile%3A%2Fproduct%2Fmain%5D",
      "https://lp2.hm.com/hmgoepprod?set=quality%5B79%5D%2Csource%5B%2F10%2F0e%2F100e064422fb6857712432cbd3ad6af47134b46a.jpg%5D%2Corigin%5Bdam%5D%2Ccategory%5B%5D%2Ctype%5BLOOKBOOK%5D%2Cres%5Bm%5D%2Chmver%5B1%5D&call=url[file:/product/main]",
    ],
    brand: "Hm",
    id: "IJ21R",
    recommendedSize: "",
    tags: ["black", "dress", "wedding"],
  },
  {
    title: "Karma Maxi Dress",
    price: { amount: 161.14, currency: "" },
    vendors: [
      {
        name: "",
        url: "https://www.revolve.com/dresses/br/a8e981/?navsrc=main&deeplink=true&gad_source=1&gclid=CjwKCAiAgeeqBhBAEiwAoDDhn0qtgFCJdOkpMDEUdQuG_N-IhSsw7RXNSmlZZX7DP26X7u70p4s6tRoCiTgQAvD_BwE&gclsrc=aw.ds",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/LOVF-WD2570_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/LOVF-WD2570_V3.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/LOVF-WD2570_V2.jpg",
    ],
    brand: "Revolve",
    id: "R7EXD",
    recommendedSize: "",
    tags: ["black", "dress", "wedding"],
  },
  {
    title: "X Revolve Essentials Jennan Dress",
    price: { amount: 84.41, currency: "" },
    vendors: [
      {
        name: "",
        url: "https://www.revolve.com/dresses/br/a8e981/?navsrc=main&deeplink=true&gad_source=1&gclid=CjwKCAiAgeeqBhBAEiwAoDDhn0qtgFCJdOkpMDEUdQuG_N-IhSsw7RXNSmlZZX7DP26X7u70p4s6tRoCiTgQAvD_BwE&gclsrc=aw.ds",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/AFFM-WD281_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/AFFM-WD281_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/AFFM-WD281_V3.jpg",
    ],
    brand: "Revolve",
    id: "B2SCW",
    recommendedSize: "",
    tags: ["black", "dress", "wedding"],
  },
  {
    title: "Erika Midi Dress",
    price: { amount: 300.0, currency: "" },
    vendors: [{ name: "", url: "revolve", thumbnailUrl: "", origin: "" }],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/BARD-WD694_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/BARD-WD694_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/BARD-WD694_V3.jpg",
    ],
    brand: "Bradot",
    id: "VYNB3",
    recommendedSize: "",
    tags: ["black", "dress", "wedding"],
  },
  {
    title: "Willa Midi Dress",
    price: { amount: 275.0, currency: "" },
    vendors: [{ name: "", url: "revolve", thumbnailUrl: "", origin: "" }],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/LOVF-WD3860_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/LOVF-WD3860_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/LOVF-WD3860_V2.jpg",
    ],
    brand: "LoversAndFriends",
    id: "JIE0Y",
    recommendedSize: "",
    tags: ["black", "dress", "wedding"],
  },
  {
    title: "Blake Mini Dress",
    price: { amount: 300.0, currency: "" },
    vendors: [{ name: "", url: "revolve", thumbnailUrl: "", origin: "" }],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/LCDE-WD723_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/LCDE-WD723_V3.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/LCDE-WD723_V2.jpg",
    ],
    brand: "Lacademie",
    id: "S22IM",
    recommendedSize: "",
    tags: ["black", "dress", "wedding"],
  },
  {
    title: "Brooklyn Midi Dress",
    price: { amount: 180.0, currency: "" },
    vendors: [{ name: "", url: "revolve", thumbnailUrl: "", origin: "" }],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/BARD-WD699_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/BARD-WD699_V3.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/BARD-WD699_V2.jpg",
    ],
    brand: "Bradot",
    id: "CXSNS",
    recommendedSize: "",
    tags: ["black", "dress", "wedding"],
  },
  {
    title: "Selina Maxi Dress",
    price: { amount: 300.0, currency: "" },
    vendors: [{ name: "", url: "revolve", thumbnailUrl: "", origin: "" }],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/NBDR-WD3056_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/NBDR-WD3056_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/NBDR-WD3056_V3.jpg",
    ],
    brand: "NBD",
    id: "QJG1J",
    recommendedSize: "",
    tags: ["black", "dress", "wedding"],
  },
  {
    title: "Dion Embellished Maxi Dress",
    price: { amount: 200.0, currency: "" },
    vendors: [{ name: "", url: "revolve", thumbnailUrl: "", origin: "" }],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/SOSR-WD233_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/SOSR-WD233_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/SOSR-WD233_V3.jpg",
    ],
    brand: "SongOfStyle",
    id: "7J5A6",
    recommendedSize: "",
    tags: ["black", "dress", "wedding"],
  },
  {
    title: "Selina Maxi Dress",
    price: { amount: 300.0, currency: "" },
    vendors: [{ name: "", url: "revolve", thumbnailUrl: "", origin: "" }],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/NBDR-WD3056_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/NBDR-WD3056_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/NBDR-WD3056_V3.jpg",
    ],
    brand: "NBD",
    id: "EDB2O",
    recommendedSize: "",
    tags: ["black", "dress", "wedding"],
  },
  {
    title: "KIM DOLCE&GABBANA number-patch floor length dress",
    price: { amount: 800.0, currency: "" },
    vendors: [{ name: "", url: "Farfetch", thumbnailUrl: "", origin: "" }],
    imgUrls: [
      "https://cdn-images.farfetch-contents.com/19/39/03/50/19390350_43840456_1000.jpg",
      "https://cdn-images.farfetch-contents.com/19/39/03/50/19390350_43838793_1000.jpg",
      "https://cdn-images.farfetch-contents.com/19/39/03/50/19390350_43838759_1000.jpg",
    ],
    brand: "DolceGabana",
    id: "R78LG",
    recommendedSize: "",
    tags: ["black", "dress", "wedding"],
  },
  {
    title: "floral-embellished velvet A-line dress",
    price: { amount: 900.0, currency: "" },
    vendors: [{ name: "", url: "Farfetch", thumbnailUrl: "", origin: "" }],
    imgUrls: [
      "https://cdn-images.farfetch-contents.com/21/41/44/62/21414462_51388543_1000.jpg",
      "https://cdn-images.farfetch-contents.com/21/41/44/62/21414462_51388547_1000.jpg",
      "https://cdn-images.farfetch-contents.com/21/41/44/62/21414462_51388544_1000.jpg",
    ],
    brand: "Zimerman",
    id: "47U46",
    recommendedSize: "",
    tags: ["black", "dress", "wedding"],
  },
];

export const MockCargoPants: MockMiniProduct[] = [
  {
    title: "High Rise Utility Trouserin Camo",
    price: { amount: 312.5, currency: "" },
    vendors: [
      {
        name: "frame-store",
        url: "https://frame-store.com/en-il/products/high-rise-utility-trouser-camo?ranEAID=0c5b5rpoqTU&ranMID=41730&ranSiteID=0c5b5rpoqTU-s6n_v0yZ8_IGa9GzO7ilaA&utm_campaign=Vogue&utm_content=1&utm_medium=affiliate&utm_source=linkshare",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://frame.imgix.net/files/HRUTR772-CAMO_V2.jpg?v=1697152261&w=720&auto=format,compress",
      "https://frame.imgix.net/products/HRUTR772_CAMO_FRSU23EC_WOMENS_DAY_05_0821HR.jpg?v=1697152261&w=720?auto=format,compress",
      "https://frame.imgix.net/products/HRUTR772_CAMO_FRSU23EC_WOMENS_DAY_05_0833HR.jpg?v=1697152261&w=720?auto=format,compress",
    ],
    brand: "Frame",
    id: "W243Y",
    recommendedSize: "",
    tags: ["pants", "cargo"],
  },
  {
    title: "Polkas Pleated Cargo Pants",
    price: { amount: 940.1, currency: "" },
    vendors: [
      {
        name: "saksfifthavenue",
        url: "https://www.saksfifthavenue.com/product/dries-van-noten-polkas-pleated-cargo-pants-0400018211816.html?ranMID=13816&ranEAID=0c5b5rpoqTU&ranSiteID=0c5b5rpoqTU-P_diyto4BKDWDtnFOoJ8iw&site_refer=AFF001&mid=13816&siteID=0c5b5rpoqTU-P_diyto4BKDWDtnFOoJ8iw&LSoid=1278460&LSlinkid=10&LScreativeid=1",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://cdn.saksfifthavenue.com/is/image/saks/0400018211816_RAF?wid=968&hei=1292&qlt=90&resMode=sharp2&op_usm=0.9,1.0,8,0",
      "https://cdn.saksfifthavenue.com/is/image/saks/0400018211816_RAF_ASTL?wid=984&hei=1312&qlt=90&resMode=sharp2&op_usm=0.9,1.0,8,0",
      "https://cdn.saksfifthavenue.com/is/image/saks/0400018211816_RAF_A1?wid=968&hei=1292&qlt=90&resMode=sharp2&op_usm=0.9,1.0,8,0",
    ],
    brand: "Dries Van Noten",
    id: "36NLO",
    recommendedSize: "",
    tags: ["pants", "cargo"],
  },
  {
    title: "Pocket cargo jeans",
    price: { amount: 90.0, currency: "" },
    vendors: [
      {
        name: "shop",
        url: "https://shop.mango.com/us/women/jeans-cargo/pocket-cargo-jeans_47065961.html",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://st.mngbcn.com/rcs/pics/static/T4/fotos/S20/47065961_05.jpg?ts=1676034206891&imwidth=417&imdensity=2",
      "https://st.mngbcn.com/rcs/pics/static/T4/fotos/outfit/S20/47065961_05-99999999_01.jpg?ts=1676034206891&imwidth=417&imdensity=2",
      "https://st.mngbcn.com/rcs/pics/static/T4/fotos/S20/47065961_05_R.jpg?ts=1676034206891&imwidth=417&imdensity=2",
    ],
    brand: "Mango",
    id: "GE9KA",
    recommendedSize: "",
    tags: ["pants", "cargo"],
  },
  {
    title: "Relaxed Nylon Cargo Pants",
    price: { amount: 347.825, currency: "" },
    vendors: [
      {
        name: "saksfifthavenue",
        url: "https://www.saksfifthavenue.com/product/Cynthia-Rowley-Relaxed-Nylon-Cargo-Pants-0400018587776.html?ranMID=13816&ranEAID=0c5b5rpoqTU&ranSiteID=0c5b5rpoqTU-oC9G74QQIPjz.jQp3VJirw&site_refer=AFF001&mid=13816&siteID=0c5b5rpoqTU-oC9G74QQIPjz.jQp3VJirw&LSoid=1278460&LSlinkid=10&LScreativeid=1",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://cdn.saksfifthavenue.com/is/image/saks/0400018587776?wid=968&hei=1292&qlt=90&resMode=sharp2&op_usm=0.9,1.0,8,0",
      "https://cdn.saksfifthavenue.com/is/image/saks/0400018587776_ASTL?wid=984&hei=1312&qlt=90&resMode=sharp2&op_usm=0.9,1.0,8,0",
    ],
    brand: "Cynthia Rowley",
    id: "RBBSG",
    recommendedSize: "",
    tags: ["pants", "cargo"],
  },
  {
    title:
      "Women's High Waist Baggy Jeans Flap Pocket Side Relaxed Fit Straight Wide Leg Cargo Jeans",
    price: { amount: 40.0, currency: "" },
    vendors: [
      {
        name: "amazon",
        url: "https://www.amazon.com/stores/page/0EAB36A0-279E-42DA-A23A-81F9E56F2AB7",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://m.media-amazon.com/images/I/71PsUjGszQL._AC_SX569_.jpg",
      "https://m.media-amazon.com/images/I/71gRTr9Rt7L._AC_SX569_.jpg",
      "https://m.media-amazon.com/images/I/8171tFNCFcL._AC_SX569_.jpg",
    ],
    brand: "MUMUBREAL",
    id: "0OE6N",
    recommendedSize: "",
    tags: ["pants", "cargo"],
  },
  {
    title: "Women's Natural Yannic Cargo Pant",
    price: { amount: 297.0, currency: "" },
    vendors: [
      {
        name: "lyst",
        url: "https://www.lyst.com/shop/cargo-pants/?atc_campaign=ROWil-en-Paid-Google-DSA-WEB-Designer&atc_content=ROWil-en-Paid-DSA-WEB-All&atc_country=ROW&atc_grouping=DSA&atc_medium=cpc&atc_source=google&atc_term=&sem_id=A6868931410&atc_label=&gad_source=1&gclid=CjwKCAiAvJarBhA1EiwAGgZl0GyPddMl0rnHRJVDmggH1wMwk5NWOM1BPOQVQbQR8ZKQqefgBlf5hhoCCikQAvD_BwE&paid_session_id=363a52a7-d1f7-4f6e-9753-5140563ff9aa&reason=feed-product-image&link_id=931316190&previous_page_type=feed&previous_page_sub_type=generic_subcategory&origin_feed=%2Fshop%2Fcargo-pants%2F#slug=nili-lotan-yannic-cargo-pant-1",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://cdna.lystit.com/520/650/n/photos/nililotan/05b52c22/nili-lotan--Yannic-Cargo-Pant.jpeg",
    ],
    brand: "Nili Lotan",
    id: "MQPST",
    recommendedSize: "",
    tags: ["pants", "cargo"],
  },
  {
    title: "Women's Natural Fern Low-rise Cargo Pants",
    price: { amount: 900.0, currency: "" },
    vendors: [
      {
        name: "lyst",
        url: "https://www.lyst.com/shop/cargo-pants/?atc_campaign=ROWil-en-Paid-Google-DSA-WEB-Designer&atc_content=ROWil-en-Paid-DSA-WEB-All&atc_country=ROW&atc_grouping=DSA&atc_medium=cpc&atc_source=google&atc_term=&sem_id=A6868931410&atc_label=&gad_source=1&gclid=CjwKCAiAvJarBhA1EiwAGgZl0GyPddMl0rnHRJVDmggH1wMwk5NWOM1BPOQVQbQR8ZKQqefgBlf5hhoCCikQAvD_BwE&paid_session_id=363a52a7-d1f7-4f6e-9753-5140563ff9aa&reason=feed-product-image&link_id=971281681&previous_page_type=feed&previous_page_sub_type=generic_subcategory&origin_feed=%2Fshop%2Fcargo-pants%2F#slug=the-attico-fern-low-rise-cargo-pants-1",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://cdna.lystit.com/520/650/n/photos/mytheresa/02437142/the-attico-neutrals-Fern-Low-rise-Cargo-Pants.jpeg",
      "https://cdna.lystit.com/520/650/n/photos/mytheresa/acf6e3b6/the-attico-white-Fern-Low-rise-Cargo-Pants.jpeg",
      "https://cdna.lystit.com/520/650/n/photos/mytheresa/e690f327/the-attico-white-Fern-Low-rise-Cargo-Pants.jpeg",
    ],
    brand: "The Attico",
    id: "AGKT5",
    recommendedSize: "",
    tags: ["pants", "cargo"],
  },
  {
    title: "Women's Natural Marcelle Low-rise Cotton Twill Cargo Pants",
    price: { amount: 227.0, currency: "" },
    vendors: [
      {
        name: "lyst",
        url: "https://www.lyst.com/shop/cargo-pants/?atc_campaign=ROWil-en-Paid-Google-DSA-WEB-Designer&atc_content=ROWil-en-Paid-DSA-WEB-All&atc_country=ROW&atc_grouping=DSA&atc_medium=cpc&atc_source=google&atc_term=&sem_id=A6868931410&atc_label=&gad_source=1&gclid=CjwKCAiAvJarBhA1EiwAGgZl0GyPddMl0rnHRJVDmggH1wMwk5NWOM1BPOQVQbQR8ZKQqefgBlf5hhoCCikQAvD_BwE&page=2&paid_session_id=363a52a7-d1f7-4f6e-9753-5140563ff9aa&reason=feed-product-image&link_id=977877435&previous_page_type=feed&previous_page_sub_type=generic_subcategory&origin_feed=%2Fshop%2Fcargo-pants%2F#slug=citizens-of-humanity-marcelle-low-rise-cotton-twill-cargo-pants",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://cdna.lystit.com/520/650/n/photos/mytheresa/17e2d8f5/citizens-of-humanity-green-Marcelle-Low-rise-Cotton-Twill-Cargo-Pants.jpeg",
      "https://cdna.lystit.com/520/650/n/photos/mytheresa/df98428f/citizens-of-humanity-green-Marcelle-Low-rise-Cotton-Twill-Cargo-Pants.jpeg",
      "https://cdna.lystit.com/520/650/n/photos/mytheresa/a73735ac/citizens-of-humanity-green-Marcelle-Low-rise-Cotton-Twill-Cargo-Pants.jpeg",
    ],
    brand: "Citizens of Humanity",
    id: "1NW6K",
    recommendedSize: "",
    tags: ["pants", "cargo"],
  },
  {
    title: "Women's Natural Canvas Wide Leg Cargo Pants",
    price: { amount: 658.0, currency: "" },
    vendors: [
      {
        name: "lyst",
        url: "https://www.lyst.com/shop/cargo-pants/?atc_campaign=ROWil-en-Paid-Google-DSA-WEB-Designer&atc_content=ROWil-en-Paid-DSA-WEB-All&atc_country=ROW&atc_grouping=DSA&atc_medium=cpc&atc_source=google&atc_term=&sem_id=A6868931410&atc_label=&gad_source=1&gclid=CjwKCAiAvJarBhA1EiwAGgZl0GyPddMl0rnHRJVDmggH1wMwk5NWOM1BPOQVQbQR8ZKQqefgBlf5hhoCCikQAvD_BwE&page=4&paid_session_id=363a52a7-d1f7-4f6e-9753-5140563ff9aa&reason=feed-product-image&link_id=948996191&previous_page_type=feed&previous_page_sub_type=generic_subcategory&origin_feed=%2Fshop%2Fcargo-pants%2F#slug=coperni-canvas-wide-leg-cargo-pants-1",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://cdna.lystit.com/520/650/n/photos/cettire/7dcf5f70/coperni-Beige-Wide-leg-Cargo-Pants.jpeg",
      "https://cdna.lystit.com/520/650/n/photos/cettire/2b24dea2/coperni-Beige-Wide-leg-Cargo-Pants.jpeg",
      "https://cdna.lystit.com/520/650/n/photos/cettire/02e05523/coperni-Beige-Wide-leg-Cargo-Pants.jpeg",
    ],
    brand: "Coperni",
    id: "H5528",
    recommendedSize: "",
    tags: ["pants", "cargo"],
  },
  {
    title: "Women's Natural Cotton Cargo Pants",
    price: { amount: 1154.0, currency: "" },
    vendors: [
      {
        name: "lyst",
        url: "https://www.lyst.com/shop/cargo-pants/?atc_campaign=ROWil-en-Paid-Google-DSA-WEB-Designer&atc_content=ROWil-en-Paid-DSA-WEB-All&atc_country=ROW&atc_grouping=DSA&atc_medium=cpc&atc_source=google&atc_term=&sem_id=A6868931410&atc_label=&gad_source=1&gclid=CjwKCAiAvJarBhA1EiwAGgZl0GyPddMl0rnHRJVDmggH1wMwk5NWOM1BPOQVQbQR8ZKQqefgBlf5hhoCCikQAvD_BwE&page=6&paid_session_id=363a52a7-d1f7-4f6e-9753-5140563ff9aa&reason=feed-product-image&link_id=936674975&previous_page_type=feed&previous_page_sub_type=generic_subcategory&origin_feed=%2Fshop%2Fcargo-pants%2F#slug=brunello-cucinelli-cotton-cargo-pants-13",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://cdna.lystit.com/520/650/n/photos/mytheresa/072bcc4d/brunello-cucinelli-beige-Cotton-Cargo-Pants.jpeg",
      "https://cdna.lystit.com/520/650/n/photos/mytheresa/5b09e483/brunello-cucinelli-beige-Cotton-Cargo-Pants.jpeg",
      "https://cdna.lystit.com/520/650/n/photos/mytheresa/4534c283/brunello-cucinelli-beige-Cotton-Cargo-Pants.jpeg",
    ],
    brand: "Brunello Cucinelli",
    id: "3FR7B",
    recommendedSize: "",
    tags: ["pants", "cargo"],
  },
];

export const MockMatchingCargoPants: MockMiniProduct[] = [
  {
    title: "Kai printed halterneck crop top",
    price: { amount: 195.0, currency: "" },
    vendors: [
      {
        name: "",
        url: "https://staud.clothing/",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://img.mytheresa.com/1094/1236/90/jpeg/catalog/product/1f/P00795943.jpg",
      "https://img.mytheresa.com/356/402/66/jpeg/catalog/product/1f/P00795943.jpg",
      "https://img.mytheresa.com/1094/1236/90/jpeg/catalog/product/1f/P00795943_b1.jpg",
    ],
    brand: "STAUD",
    id: "E7NR0",
    recommendedSize: "",
    tags: [""],
  },
  {
    title: "Brown Rachel Shoulder Bag",
    price: { amount: 400.0, currency: "" },
    vendors: [
      {
        name: "",
        url: "https://www.byfar.com/collections/bags",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://img.ssensemedia.com/images/b_white,g_center,f_auto,q_auto:best/231289F048107_1/by-far-brown-rachel-shoulder-bag.jpg",
      "https://img.ssensemedia.com/images/b_white,g_center,f_auto,q_auto:best/231289F048107_2/by-far-brown-rachel-shoulder-bag.jpg",
      "https://img.ssensemedia.com/images/b_white,g_center,f_auto,q_auto:best/231289F048107_3/by-far-brown-rachel-shoulder-bag.jpg",
    ],
    brand: "BY FAR",
    id: "RWJ2G",
    recommendedSize: "",
    tags: [""],
  },
  {
    title: "Rectangle Plastic Wrap Sunglasses",
    price: { amount: 450.0, currency: "" },
    vendors: [
      {
        name: "",
        url: "https://www.prada.com/ww/en.html",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://d3vfig6e0r0snz.cloudfront.net/rcYjnYuenaTH5vyDF/images/products/016f709f51ac632835ddff10ed1e3b3f.webp",
      "https://d3vfig6e0r0snz.cloudfront.net/rcYjnYuenaTH5vyDF/images/products/aac83a29887fce603e0af5751761c4ef.webp",
      "https://d3vfig6e0r0snz.cloudfront.net/rcYjnYuenaTH5vyDF/images/products/5d2e2c0369878074413dc23b665e24d9.webp",
    ],
    brand: "PRADA",
    id: "N09Z6",
    recommendedSize: "",
    tags: [""],
  },
  {
    title: "Vans Old Skool sneaker",
    price: { amount: 70.0, currency: "" },
    vendors: [
      {
        name: "",
        url: "https://www.vans.com/en-us?_sr=1",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/VANX-MZ311W_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/VANX-MZ311W_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/VANX-MZ311W_V3.jpg",
    ],
    brand: "VANS",
    id: "JZ1ME",
    recommendedSize: "",
    tags: [""],
  },
  {
    title: "Long-Sleeve Fitted Crewneck Top",
    price: { amount: 135.0, currency: "" },
    vendors: [
      {
        name: "",
        url: "https://www.bergdorfgoodman.com/",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://media.bergdorfgoodman.com/f_auto,q_auto:good,ar_5:7,c_fill,dpr_1.5,w_720/01/bg_4458934_100106_m",
      "https://media.bergdorfgoodman.com/f_auto,q_auto:good,ar_5:7,c_fill,dpr_1.5,w_720/01/bg_4458934_100106_a",
      "https://media.bergdorfgoodman.com/f_auto,q_auto:good,ar_5:7,c_fill,dpr_1.5,w_720/01/bg_4458934_100106_b",
    ],
    brand: "bergdorfgoodman",
    id: "PCFPB",
    recommendedSize: "",
    tags: [""],
  },
  {
    title: "White One Stud Sneakers",
    price: { amount: 980.0, currency: "" },
    vendors: [
      {
        name: "",
        url: "https://www.ssense.com/en-us",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://img.ssensemedia.com/images/b_white,g_center,f_auto,q_auto:best/222807F128021_1/valentino-garavani-white-one-stud-sneakers.jpg",
      "https://img.ssensemedia.com/images/b_white,g_center,f_auto,q_auto:best/222807F128021_2/valentino-garavani-white-one-stud-sneakers.jpg",
      "https://img.ssensemedia.com/images/b_white,g_center,f_auto,q_auto:best/222807F128021_3/valentino-garavani-white-one-stud-sneakers.jpg",
    ],
    brand: "VALENTINO GARAVANI",
    id: "OJTXA",
    recommendedSize: "",
    tags: [""],
  },
  {
    title: "Airweight Crop Top",
    price: { amount: 110.0, currency: "" },
    vendors: [
      {
        name: "",
        url: "https://www.revolve.com/women/?navsrc=logoleft",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/SPLR-WS75_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/SPLR-WS75_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/SPLR-WS75_V3.jpg",
    ],
    brand: "splits59",
    id: "82IB5",
    recommendedSize: "",
    tags: [""],
  },
  {
    title: "Cali Jacket",
    price: { amount: 234.0, currency: "" },
    vendors: [
      {
        name: "",
        url: "https://www.revolve.com/women/?navsrc=logo",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/d/OWIR-WO4_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/d/OWIR-WO4_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/d/OWIR-WO4_V4.jpg",
    ],
    brand: "OW Collection",
    id: "RMQBH",
    recommendedSize: "",
    tags: [""],
  },
  {
    title: "Samba OG Sneaker",
    price: { amount: 93.5, currency: "" },
    vendors: [
      {
        name: "",
        url: "https://www.revolve.com/women/?navsrc=logo",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/AORI-WZ75_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/AORI-WZ75_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/AORI-WZ75_V3.jpg",
    ],
    brand: "adidas Originals",
    id: "0WHDR",
    recommendedSize: "",
    tags: [""],
  },
  {
    title: "Raven Leather Jacket",
    price: { amount: 428.5, currency: "" },
    vendors: [
      {
        name: "",
        url: "https://www.revolve.com/women/?navsrc=logo",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/COEL-WO34_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/COEL-WO34_V3.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/COEL-WO34_V4.jpg",
    ],
    brand: "Camila Coelho",
    id: "11H8X",
    recommendedSize: "",
    tags: [""],
  },
];

export const MockProducts: MockMiniProduct[] = [
  {
    title: "x REVOLVE Tuscany Linen Dress",
    price: { amount: 99.99, currency: "" },
    vendors: [{ name: "", url: "Revolve", thumbnailUrl: "", origin: "" }],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/SDYS-WD83_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/SDYS-WD83_V3.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/SDYS-WD83_V2.jpg",
    ],
    brand: "Revolve",
    id: "BM3JN",
    recommendedSize: "",
    tags: ["pink", "dress", "summer", "linen"],
  },
  {
    title: "Linen Twist Top",
    price: { amount: 99.99, currency: "" },
    vendors: [{ name: "", url: "Revolve", thumbnailUrl: "", origin: "" }],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/HARL-WS976_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/HARL-WS976_V3.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/HARL-WS976_V1.jpg",
    ],
    brand: "Revolve",
    id: "E4FKQ",
    recommendedSize: "",
    tags: ["orange", "linen", "top"],
  },
  {
    title: "Light Weight Linen Shirt",
    price: { amount: 99.99, currency: "" },
    vendors: [{ name: "", url: "Revolve", thumbnailUrl: "", origin: "" }],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/JAME-WS2629_V3.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/JAME-WS2629_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/JAME-WS2629_V1.jpg",
    ],
    brand: "Revolve",
    id: "84SVO",
    recommendedSize: "",
    tags: ["shirt", "linen", "top", "grey", "summer"],
  },
  {
    title: "Theo Linen Double Waist Trouser",
    price: { amount: 99.99, currency: "" },
    vendors: [{ name: "", url: "Revolve", thumbnailUrl: "", origin: "" }],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/MWER-WP46_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/MWER-WP46_V3.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/MWER-WP46_V2.jpg",
    ],
    brand: "Revolve",
    id: "M0HZ3",
    recommendedSize: "",
    tags: ["pants", "white", "linen", "summer"],
  },
  {
    title: "Stretch Linen Bra Top",
    price: { amount: 99.99, currency: "" },
    vendors: [{ name: "", url: "Revolve", thumbnailUrl: "", origin: "" }],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/LCDE-WS945_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/LCDE-WS945_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/LCDE-WS945_V3.jpg",
    ],
    brand: "Revolve",
    id: "D090K",
    recommendedSize: "",
    tags: ["top", "brah", "summer", "beige", "linen"],
  },
  {
    title: "Mare Trousers",
    price: { amount: 99.99, currency: "" },
    vendors: [{ name: "", url: "revolve", thumbnailUrl: "", origin: "" }],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/MSNI-WP2_V3.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/MSNI-WP2_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/MSNI-WP2_V1.jpg",
    ],
    brand: "revolve",
    id: "T6D0B",
    recommendedSize: "",
    tags: ["pants", "summer"],
  },
  {
    title: "Alight Billow Blouse",
    price: { amount: 99.99, currency: "" },
    vendors: [{ name: "", url: "revolve", thumbnailUrl: "", origin: "" }],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/ZIMM-WS121_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/ZIMM-WS121_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/ZIMM-WS121_V3.jpg",
    ],
    brand: "revolve",
    id: "O5ZPO",
    recommendedSize: "",
    tags: ["top", "summer", "flower"],
  },
  {
    title: "Santorini Dress",
    price: { amount: 99.99, currency: "" },
    vendors: [{ name: "", url: "revolve", thumbnailUrl: "", origin: "" }],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/LSPA-WD148_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/LSPA-WD148_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/LSPA-WD148_V3.jpg",
    ],
    brand: "revolve",
    id: "N2I4O",
    recommendedSize: "",
    tags: ["dress", "white", "summer"],
  },
  {
    title: "Allie Crop Top",
    price: { amount: 99.99, currency: "" },
    vendors: [{ name: "", url: "revolve", thumbnailUrl: "", origin: "" }],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/MAAJ-WS48_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/MAAJ-WS48_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/MAAJ-WS48_V3.jpg",
    ],
    brand: "revolve",
    id: "CP5GJ",
    recommendedSize: "",
    tags: ["green", "summer", "top", "crop"],
  },
  {
    title: "Cleo Skirt",
    price: { amount: 99.99, currency: "" },
    vendors: [{ name: "", url: "revolve", thumbnailUrl: "", origin: "" }],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/COEL-WQ102_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/COEL-WQ102_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/COEL-WQ102_V3.jpg",
    ],
    brand: "revolve",
    id: "FYFJA",
    recommendedSize: "",
    tags: ["black", "skirt", "date", "night", "mesh", "summer"],
  },
  {
    title: "Sienna Strappy Maxi Dress",
    price: { amount: 99.99, currency: "" },
    vendors: [{ name: "", url: "revolve", thumbnailUrl: "", origin: "" }],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/SPEL-WD313_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/SPEL-WD313_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/SPEL-WD313_V1.jpg",
    ],
    brand: "revolve",
    id: "DCHGR",
    recommendedSize: "",
    tags: ["maxi", "dress", "flower", "orange", "summer", "lunch", "cocktail"],
  },
];

export const MockLinenShirts: MockMiniProduct[] = [
  {
    title: "VERSILIA LINEN SHIRT",
    price: { amount: 99.99, currency: "" },
    vendors: [
      {
        name: "lucafaloni",
        url: "https://lucafaloni.com/en-int/products/sky-blue-versilia-linen-shirt?utm_source=google&utm_medium=cpc&utm_campaign=UnitedStates&utm_content=Sky%20Blue%20Versilia%20Linen%20Shirt&srsltid=AfmBOooVz4DPa0BtbruYOt4scD77-kVXqiUciAGlCa35D96SLeAyoomni6Y",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://lucafaloni.com/cdn/shop/files/Luca-Faloni_Versilia-Linen-Shirt_Made-In-Italy_Sky_Blue_2421_1024x1024@2x.jpg?v=1689088545",
      "https://lucafaloni.com/cdn/shop/files/Luca-Faloni_Versilia-Linen-Shirt_Made-In-Italy_Sky_Blue_2424_1024x1024@2x.jpg?v=1689088545",
      "https://lucafaloni.com/cdn/shop/files/Luca-Faloni_Versilia-Linen-Shirt_Made-In-Italy_Sky_Blue_2421_1024x1024@2x.jpg?v=1689088545",
    ],
    brand: "Dolce&Gabana",
    id: "XC0R5",
    recommendedSize: "",
    tags: ["linen", "long-sleeve", "blue", "summer", "plain", "light"],
  },
  {
    title: "MEN'S SOLID COLOR MANDARIN COLLAR LINEN BUTTON-UP SHIRT",
    price: { amount: 99.99, currency: "" },
    vendors: [
      {
        name: "grozavushop",
        url: "https://grozavushop.com/products/mens-solid-color-mandarin-collar-linen-button-up-shirt?variant=46770497716555&om=16892&srsltid=AfmBOoqIYr9Fry6GeAygvhRm_B0il9VIsdaCVtSm34b-s7W2L27T0S4ILs4",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://grozavushop.com/cdn/shop/products/mens-solid-color-mandarin-collar-linen-button-up-shirt-608897.jpg?pad_color=fff&v=1695066937&width=533",
    ],
    brand: "Zara",
    id: "OURDS",
    recommendedSize: "",
    tags: ["linen", "short-sleeve", "dark", "brown", "plain"],
  },
  {
    title: "AMALFI STRIPED LINEN SHIRT",
    price: { amount: 99.99, currency: "" },
    vendors: [
      {
        name: "panareha",
        url: "https://www.panareha.com/en/shirts/1110-9325-amalfi-striped-linen-shirt-ch2318r21.html?srsltid=AfmBOopt8Jyh8nip0d2OA-kZPM_-35xqWyHwEnCcaItKu2gcNJv9DdrH8Gc",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://www.panareha.com/7091-home_default/amalfi-striped-linen-shirt-ch2318r21.jpg",
      "https://www.panareha.com/7097-large_default/amalfi-striped-linen-shirt-ch2318r21.jpg",
      "https://www.panareha.com/7091-home_default/amalfi-striped-linen-shirt-ch2318r21.jpg",
    ],
    brand: "Hm",
    id: "EJAZX",
    recommendedSize: "",
    tags: ["linen", "light", "summer", "long-sleeve", "stripes", "blue"],
  },
  {
    title: "Floral Printed Short-Sleeved Shirt",
    price: { amount: 99.99, currency: "" },
    vendors: [
      {
        name: "cettire",
        url: "https://www.cettire.com/il/products/maison-margiela-floral-printed-short-sleeved-shirt-929580026/cmVhY3Rpb24vcHJvZHVjdDprdVdueEQ4WThGNEh2UENDWA%3D%3D?clickref=1101ly3AVxqR",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://d3vfig6e0r0snz.cloudfront.net/rcYjnYuenaTH5vyDF/images/products/700bbb9b0ce7309a1082538168288972.webp",
      "https://d3vfig6e0r0snz.cloudfront.net/rcYjnYuenaTH5vyDF/images/products/730caaee99239c8b837153a16c9c99b0.webp",
      "https://d3vfig6e0r0snz.cloudfront.net/rcYjnYuenaTH5vyDF/images/products/700bbb9b0ce7309a1082538168288972.webp",
    ],
    brand: "Revolve",
    id: "X8CTF",
    recommendedSize: "",
    tags: ["linen", "big-pattern", "summer", "short-sleeve", "light", "blue"],
  },
  {
    title: "Tagus short-sleeve regular-fit linen shirt",
    price: { amount: 99.99, currency: "" },
    vendors: [
      {
        name: "selfridges",
        url: "https://www.selfridges.com/US/en/cat/ted-bakertagus-short-sleeve-regular-fit-linen-shirt_R04172745/?utm_source=partnerize&utm_medium=affil&utm_campaign=na_na_na_na_na_na&utm_term=1101ly3AVxrn&utm_content=laurenlyst&clickref=1101ly3AVxrn#colour=DK-ORANGE",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://images.selfridges.com/is/image/selfridges/R04172745_DKORANGE_M?wid=476&hei=634&fmt=webp&qlt=80,1&bgc=F6F6F6&dpr=on,2&extend=-18,0,-18,0",
      "https://images.selfridges.com/is/image/selfridges/R04172745_DKORANGE_ALT01?wid=476&hei=634&fmt=webp&qlt=80,1&bgc=F6F6F6&dpr=on,2&extend=-18,0,-18,0",
      "https://images.selfridges.com/is/image/selfridges/R04172745_DKORANGE_ALT03?wid=476&hei=634&fmt=webp&qlt=80,1&bgc=F6F6F6&dpr=on,2&extend=-18,0,-18,0",
    ],
    brand: "Dolce&Gabana",
    id: "H2GTG",
    recommendedSize: "",
    tags: ["linen", "big-pattern", "summer", "short-sleeve", "white"],
  },
  {
    title: "Blue Ikat Check Short Sleeved Shirt",
    price: { amount: 99.99, currency: "" },
    vendors: [
      {
        name: "brownsfashion",
        url: "https://www.brownsfashion.com/cz/shopping/blue-ikat-check-short-sleeved-shirt-18992491?clickref=1011ly3XUAFb&utm_campaign=browns_fashion&utm_medium=affiliate&utm_source=laurenlyst",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://img.brownsfashion.com/polo-ralph-lauren-blue-ikat-check-short-sleeved-shirt_18992491_44472764_800.jpg",
      "https://img.brownsfashion.com/polo-ralph-lauren-blue-ikat-check-short-sleeved-shirt_18992491_44472763_800.jpg",
      "https://img.brownsfashion.com/polo-ralph-lauren-blue-ikat-check-short-sleeved-shirt_18992491_44472764_800.jpg",
    ],
    brand: "RalphLauren",
    id: "TIK40",
    recommendedSize: "",
    tags: ["linen", "plaid", "light", "blue", "short-sleeve", "summer"],
  },
  {
    title: "Lytham striped short-sleeved linen and cotton-blend shirt",
    price: { amount: 99.99, currency: "" },
    vendors: [
      {
        name: "selfridges",
        url: "https://www.selfridges.com/US/en/cat/ted-bakerlytham-striped-short-sleeved-linen-and-cotton-blend-shirt_R04133337/?utm_source=partnerize&utm_medium=affil&utm_campaign=na_na_na_na_na_na&utm_term=1101ly3AVxAb&utm_content=laurenlyst&clickref=1101ly3AVxAb",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://images.selfridges.com/is/image/selfridges/R04133337_DKORANGE_ALT01?wid=476&hei=634&fmt=webp&qlt=80,1&bgc=F6F6F6&dpr=on,2&extend=-18,0,-18,0",
      "https://images.selfridges.com/is/image/selfridges/R04133337_DKORANGE_M?wid=476&hei=634&fmt=webp&qlt=80,1&bgc=F6F6F6&dpr=on,2&extend=-18,0,-18,0",
      "https://images.selfridges.com/is/image/selfridges/R04133337_DKORANGE_ALT01?wid=476&hei=634&fmt=webp&qlt=80,1&bgc=F6F6F6&dpr=on,2&extend=-18,0,-18,0",
    ],
    brand: "Dolce&Gabana",
    id: "ZQW20",
    recommendedSize: "",
    tags: ["linen", "short-sleeve", "summer", "stripes"],
  },
  {
    title: "Nanushka Floral Printed Short-Sleeved Shirt",
    price: { amount: 99.99, currency: "" },
    vendors: [
      {
        name: "cettire",
        url: "https://www.cettire.com/il/products/nanushka-floral-printed-short-sleeved-shirt-911241982/cmVhY3Rpb24vcHJvZHVjdDplR0pOcXVoeW5LZEZyYUN3bw%3D%3D?clickref=1011ly3XUB86",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://d3vfig6e0r0snz.cloudfront.net/rcYjnYuenaTH5vyDF/images/products/b927987ca93500d21a1db1cb03d40137.webp",
      "https://d3vfig6e0r0snz.cloudfront.net/rcYjnYuenaTH5vyDF/images/products/cfe648bf31d094642631b9891682d863.webp",
      "https://d3vfig6e0r0snz.cloudfront.net/rcYjnYuenaTH5vyDF/images/products/edad720c215aa96ec37af2ef9923b399.webp",
    ],
    brand: "Lacademie",
    id: "S7A29",
    recommendedSize: "",
    tags: ["linen", "short-sleeve", "orange", "big-pattern"],
  },
  {
    title: "Linen Short-Sleeve Shirt",
    price: { amount: 99.99, currency: "" },
    vendors: [
      {
        name: "harrods",
        url: "https://www.harrods.com/en-gb/shopping/linen-short-sleeve-shirt-20867634?utm_source=ran_uk&utm_medium=affiliate&utm_campaign=2523611_Lyst+UK%2FEU&utm_content=1429486&ranMID=36666&ranEAID=gcdL%2FATRVoE&ranSiteID=gcdL_ATRVoE-SVRFl8hCqxtHCGGtJXo8UA",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://image.harrods.com/nn07-linen-short-sleeve-shirt_20867634_45837566_1000.jpg",
      "https://image.harrods.com/nn07-linen-short-sleeve-shirt_20867634_45837566_1000.jpg",
      "https://image.harrods.com/nn07-linen-short-sleeve-shirt_20867634_45838519_1000.jpg",
    ],
    brand: "Bradot",
    id: "VT9V8",
    recommendedSize: "",
    tags: ["linen", "short-sleeve", "plain"],
  },
  {
    title: "Cotton-Linen Short-Sleeved Shirt",
    price: { amount: 99.99, currency: "" },
    vendors: [
      {
        name: "harrods",
        url: "https://www.harrods.com/en-gb/shopping/cotton-linen-short-sleeved-shirt-19830665?utm_source=ran_uk&utm_medium=affiliate&utm_campaign=2523611_Lyst+UK%2FEU&utm_content=1429486&ranMID=36666&ranEAID=gcdL%2FATRVoE&ranSiteID=gcdL_ATRVoE-hJ9ntN8LGO4PVy_ncFs1ug",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://image.harrods.com/polo-ralph-lauren-cotton-linen-short-sleeved-shirt_19830665_45577613_1000.jpg",
      "https://image.harrods.com/polo-ralph-lauren-cotton-linen-short-sleeved-shirt_19830665_45577626_1000.jpg",
      "https://image.harrods.com/polo-ralph-lauren-cotton-linen-short-sleeved-shirt_19830665_45577601_1000.jpg",
    ],
    brand: "RalphLauren",
    id: "V95A6",
    recommendedSize: "",
    tags: ["linen", "short-sleeve", "big-pattern", "summer"],
  },
  {
    title: "x No Vacancy Inn Printed Short-Sleeve Shirt",
    price: { amount: 99.99, currency: "" },
    vendors: [
      {
        name: "harrods",
        url: "https://www.harrods.com/en-gb/shopping/x-no-vacancy-inn-printed-short-sleeve-shirt-20222934?utm_source=ran_uk&utm_medium=affiliate&utm_campaign=2523611_Lyst+UK%2FEU&utm_content=1429486&ranMID=36666&ranEAID=gcdL%2FATRVoE&ranSiteID=gcdL_ATRVoE-xWaVIwNLqDWPqePfxy8Lnw",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://image.harrods.com/marni-x-no-vacancy-inn-printed-short-sleeve-shirt_20222934_45878978_1000.jpg",
      "https://image.harrods.com/marni-x-no-vacancy-inn-printed-short-sleeve-shirt_20222934_45878965_1000.jpg",
      "https://image.harrods.com/marni-x-no-vacancy-inn-printed-short-sleeve-shirt_20222934_45878991_1000.jpg",
    ],
    brand: "Song of Style",
    id: "4ZTAX",
    recommendedSize: "",
    tags: ["linen", "short-sleeve", "big-pattern", "summer"],
  },
  {
    title: "Printed Short Sleeve Shirt",
    price: { amount: 99.99, currency: "" },
    vendors: [
      {
        name: "shopbop",
        url: "https://www.shopbop.com/printed-short-sleeve-shirt-maison/vp/v=1/1545344302.htm?currencyCode=AUD&extid=affprg_linkshare_SB-tZkYzve9Cvk&cvosrc=affiliate.linkshare.tZkYzve9Cvk&affuid=1365312954&sharedid=42352&subid1=tZkYzve9Cvk-B7548__Rgz4J09g2gfl9_A",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://m.media-amazon.com/images/G/01/Shopbop/p/prod/products/mmmar/mmmar30265102d9/mmmar30265102d9_1691437582099_2-0._UX846_AGcontrast_FMwebp_.jpg",
      "https://m.media-amazon.com/images/G/01/Shopbop/p/prod/products/mmmar/mmmar30265102d9/mmmar30265102d9_1691437582098_2-0._UX846_AGcontrast_FMwebp_.jpg",
      "https://m.media-amazon.com/images/G/01/Shopbop/p/prod/products/mmmar/mmmar30265102d9/mmmar30265102d9_1691437582099_2-0._UX846_AGcontrast_FMwebp_.jpg",
    ],
    brand: "NBD",
    id: "263XV",
    recommendedSize: "",
    tags: ["linen", "short-sleeve", "big-pattern", "summer"],
  },
  {
    title: "Iznick Printed Short Sleeve Button Front Shirt",
    price: { amount: 99.99, currency: "" },
    vendors: [
      {
        name: "bloomingdales",
        url: "https://www.bloomingdales.com/shop/product/robert-graham-iznick-printed-short-sleeve-button-front-shirt?ID=4889243&CategoryID=17648",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://images.bloomingdalesassets.com/is/image/BLM/products/9/optimized/13127539_fpx.tif?op_sharpen=1&wid=700&fit=fit,1&$filtersm$&fmt=webp",
      "https://images.bloomingdalesassets.com/is/image/BLM/products/9/optimized/13127549_fpx.tif?op_sharpen=1&wid=700&fit=fit,1&$filtersm$&fmt=webp",
      "https://images.bloomingdalesassets.com/is/image/BLM/products/9/optimized/13127539_fpx.tif?op_sharpen=1&wid=700&fit=fit,1&$filtersm$&fmt=webp",
    ],
    brand: "Bradot",
    id: "Q78CQ",
    recommendedSize: "",
    tags: ["linen", "short-sleeve", "small-pattern", "summer"],
  },
  {
    title: " Terry Jacquard  Button Down Shirt",
    price: { amount: 99.99, currency: "" },
    vendors: [
      {
        name: "bloomingdales",
        url: "https://www.bloomingdales.com/shop/product/oas-cotton-terry-jacquard-regular-fit-button-down-shirt?ID=4736312&CategoryID=17648",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://images.bloomingdalesassets.com/is/image/BLM/products/7/optimized/12885947_fpx.tif?op_sharpen=1&wid=700&fit=fit,1&$filtersm$&fmt=webp",
      "https://images.bloomingdalesassets.com/is/image/BLM/products/8/optimized/12885948_fpx.tif?op_sharpen=1&wid=700&fit=fit,1&$filtersm$&fmt=webp",
      "https://images.bloomingdalesassets.com/is/image/BLM/products/7/optimized/12885947_fpx.tif?op_sharpen=1&wid=700&fit=fit,1&$filtersm$&fmt=webp",
    ],
    brand: "Zimerman",
    id: "F58WI",
    recommendedSize: "",
    tags: ["linen", "short-sleeve", "small-pattern", "summer"],
  },
  {
    title: "Playa Regular Fit Button Down Stretch Short Sleeve Shirt",
    price: { amount: 99.99, currency: "" },
    vendors: [
      {
        name: "bloomingdales",
        url: "https://www.bloomingdales.com/shop/product/faherty-playa-regular-fit-button-down-stretch-short-sleeve-shirt?ID=3981405&CategoryID=17648",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://images.bloomingdalesassets.com/is/image/BLM/products/8/optimized/11435698_fpx.tif?op_sharpen=1&wid=700&fit=fit,1&$filtersm$&fmt=webp",
      "https://images.bloomingdalesassets.com/is/image/BLM/products/9/optimized/11435699_fpx.tif?op_sharpen=1&wid=700&fit=fit,1&$filtersm$&fmt=webp",
      "https://images.bloomingdalesassets.com/is/image/BLM/products/8/optimized/11435698_fpx.tif?op_sharpen=1&wid=700&fit=fit,1&$filtersm$&fmt=webp",
    ],
    brand: "Bradot",
    id: "82RSU",
    recommendedSize: "",
    tags: ["linen", "short-sleeve", "small-pattern", "summer"],
  },
  {
    title: "Carson Short Sleeve Regular Fit Shirt",
    price: { amount: 99.99, currency: "" },
    vendors: [
      {
        name: "bloomingdales",
        url: "https://www.bloomingdales.com/shop/product/rails-carson-regular-fit-short-sleeve-shirt?ID=4792996&CategoryID=17648",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://images.bloomingdalesassets.com/is/image/BLM/products/8/optimized/13019768_fpx.tif?op_sharpen=1&wid=700&fit=fit,1&$filtersm$&fmt=webp",
      "https://images.bloomingdalesassets.com/is/image/BLM/products/9/optimized/13019769_fpx.tif?op_sharpen=1&wid=700&fit=fit,1&$filtersm$&fmt=webp",
      "https://images.bloomingdalesassets.com/is/image/BLM/products/8/optimized/13019768_fpx.tif?op_sharpen=1&wid=700&fit=fit,1&$filtersm$&fmt=webp",
    ],
    brand: "",
    id: "V93ZU",
    recommendedSize: "",
    tags: ["linen", "short-sleeve", "small-pattern", "summer"],
  },
  {
    title: "Ellino Relaxed Fit Button Down Camp Shirt",
    price: { amount: 99.99, currency: "" },
    vendors: [
      {
        name: "bloomingdales",
        url: "https://www.bloomingdales.com/shop/product/hugo-ellino-relaxed-fit-button-down-camp-shirt?ID=4938720&CategoryID=17648",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://images.bloomingdalesassets.com/is/image/BLM/products/2/optimized/12917192_fpx.tif?op_sharpen=1&wid=700&fit=fit,1&$filtersm$&fmt=webp",
      "https://images.bloomingdalesassets.com/is/image/BLM/products/4/optimized/12917194_fpx.tif?op_sharpen=1&wid=700&fit=fit,1&$filtersm$&fmt=webp",
      "https://images.bloomingdalesassets.com/is/image/BLM/products/2/optimized/12917192_fpx.tif?op_sharpen=1&wid=700&fit=fit,1&$filtersm$&fmt=webp",
    ],
    brand: "Revolve",
    id: "TIGGQ",
    recommendedSize: "",
    tags: ["linen", "short-sleeve", "small-pattern", "summer"],
  },
];

const MockMayaDress: MockMiniProduct[] = [
  {
    title: "Draped neck satin dress",
    price: { amount: 89.99, currency: "" },
    vendors: [
      {
        name: "shop",
        url: "https://shop.mango.com/gb/women/dresses-and-jumpsuits-dresses/draped-neck-satin-dress_57040458.html",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://st.mngbcn.com/rcs/pics/static/T5/fotos/S20/57040458_20.jpg?ts=1684310639069&imwidth=630&imdensity=2",
      "https://st.mngbcn.com/rcs/pics/static/T5/fotos/outfit/S20/57040458_20-99999999_01.jpg?ts=1684310639069&imwidth=630&imdensity=2",
      "https://st.mngbcn.com/rcs/pics/static/T5/fotos/S20/57040458_20_R.jpg?ts=1684310639069&imwidth=630&imdensity=2",
    ],
    brand: "Mango",
    id: "5IPX8",
    recommendedSize: "",
    tags: ["wedding guest dress", "avatarMaya"],
  },
  {
    title: "Kailyn Silk Dress\r\n",
    price: { amount: 323.0, currency: "" },
    vendors: [
      {
        name: "thereformation",
        url: "https://www.thereformation.com/products/kailyn-silk-dress/1313644AKO.html?dwvar_1313644AKO_color=AKO&_gl=1*60dsdp*_up*MQ..&gclid=EAIaIQobChMIo6WT1K-FgwMVa5eDBx005wHSEAAYASABEgIJq_D_BwE",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://media.thereformation.com/image/upload/f_auto,q_auto,dpr_1.0/w_800,c_scale//PRD-SFCC/1313644/ARTICHOKE/1313644.1.ARTICHOKE?_s=RAABAB0",
      "https://media.thereformation.com/image/upload/f_auto,q_auto,dpr_1.0/w_800,c_scale//PRD-SFCC/1313644/ARTICHOKE/1313644.2.ARTICHOKE?_s=RAABAB0",
      "https://media.thereformation.com/image/upload/f_auto,q_auto,dpr_1.0/w_800,c_scale//PRD-SFCC/1313644/ARTICHOKE/1313644.4.ARTICHOKE?_s=RAABAB0",
    ],
    brand: "Reformation",
    id: "2322R",
    recommendedSize: "",
    tags: ["wedding guest dress", "avatarMaya"],
  },
  {
    title: "Joana Silk Dress\r\n",
    price: { amount: 180.0, currency: "" },
    vendors: [
      {
        name: "thereformation",
        url: "https://www.thereformation.com/products/joana-silk-dress/1312264.html",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://media.thereformation.com/image/upload/f_auto,q_auto,dpr_1.0/w_800,c_scale//PRD-SFCC/1312264/ALGAE_BLOOM/1312264.1.ALGAE_BLOOM?_s=RAABAB0",
      "https://media.thereformation.com/image/upload/f_auto,q_auto,dpr_1.0/w_800,c_scale//PRD-SFCC/1312264/ALGAE_BLOOM/1312264.2.ALGAE_BLOOM?_s=RAABAB0",
      "https://media.thereformation.com/image/upload/f_auto,q_auto,dpr_1.0/w_800,c_scale//PRD-SFCC/1312264/ALGAE_BLOOM/1312264.3.ALGAE_BLOOM?_s=RAABAB0",
    ],
    brand: "Reformation",
    id: "57HJ2",
    recommendedSize: "",
    tags: ["wedding guest dress", "avatarMaya"],
  },
  {
    title: "Infatuation Gown",
    price: { amount: 226.0, currency: "" },
    vendors: [
      {
        name: "revolve",
        url: "https://www.revolve.com/katie-may-infatuation-gown-in-green-chartreuse/dp/KATR-WD251/?d=Womens&page=1&lc=1&plpSrc=%2Fr%2FSearch.jsp%3Fsearch%3DInfatuation+gown+in+green%26d%3DWomens%26sortBy%3Dfeatured&fromGrs=true&itrownum=1&itcurrpage=1&itview=05",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/KATR-WD251_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/KATR-WD251_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/KATR-WD251_V3.jpg",
    ],
    brand: "Katie May",
    id: "6G1CZ",
    recommendedSize: "",
    tags: ["wedding guest dress", "avatarMaya"],
  },
  {
    title: "Ayla Midi Dress",
    price: { amount: 110.0, currency: "" },
    vendors: [
      {
        name: "revolve",
        url: "https://www.revolve.com/bardot-ayla-midi-dress-in-orange-fizz/dp/BARD-WD618/?d=Womens&page=1&lc=11&plpSrc=%2Fr%2FBrands.jsp%3FaliasURL%3Dbardot-dresses%2Fbr%2F826204%26%26keyword%3DAYALA+MIDI+DRESS+IN+ORANGE+BARDOT%26color%3Dorange%26%26s%3Db%26c%3DBardot%26sc%3DDresses%26sortBy%3Dfeatured&itrownum=3&itcurrpage=1&itview=05",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/BARD-WD618_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/BARD-WD618_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/BARD-WD618_V3.jpg",
    ],
    brand: "Bardot",
    id: "PVWA7",
    recommendedSize: "",
    tags: ["wedding guest dress", "avatarMaya"],
  },
  {
    title: "Mini dress with print",
    price: { amount: 80.0, currency: "" },
    vendors: [
      {
        name: "zara",
        url: "https://www.zara.com/il/he/%D7%A9%D7%9E%D7%9C%D7%94-%D7%A7%D7%A6%D7%A8%D7%94-%D7%A2%D7%9D-%D7%94%D7%93%D7%A4%D7%A1-p09305157.html?v1=323851977&utm_campaign=productShare&utm_medium=mobile_sharing_iOS&utm_source=red_social_movil",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://static.zara.net/photos///2023/I/0/1/p/9305/157/303/12/w/750/9305157303_1_1_1.jpg?ts=1701773363607",
      "https://static.zara.net/photos///2023/I/0/1/p/9305/157/303/12/w/750/9305157303_2_1_1.jpg?ts=1701803554192",
      "https://static.zara.net/photos///2023/I/0/1/p/9305/157/303/12/w/750/9305157303_2_3_1.jpg?ts=1701773365387",
    ],
    brand: "Zara",
    id: "4QCHJ",
    recommendedSize: "",
    tags: ["wedding guest dress", "avatarMaya"],
  },
  {
    title: "La Lune Bias Cowl Maxi Dress",
    price: { amount: 299.0, currency: "" },
    vendors: [
      {
        name: "revolve",
        url: "https://www.revolve.com/shona-joy-la-lune-bias-cowl-maxi-dress-in-desert-rose/dp/SHON-WD368/?d=F&currency=ILS&source=google&countrycode=IL&lang=en&deeplink=true&gad_source=1&gclid=CjwKCAiAg9urBhB_EiwAgw88mRQCt5IRA1HJHYSst_843Zxf311ft1wbGenYxFZGKKyFYFoIos-6uhoC-98QAvD_BwE&gclsrc=aw.ds",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/uv/SHON-WD368_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/SHON-WD368_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/SHON-WD368_V5.jpg",
    ],
    brand: "Shona Joy",
    id: "W9T8W",
    recommendedSize: "",
    tags: ["wedding guest dress", "avatarMaya"],
  },
  {
    title: "La Lune Lace Back Maxi Dress",
    price: { amount: 350.0, currency: "" },
    vendors: [
      {
        name: "revolve",
        url: "https://www.revolve.com/shona-joy-la-lune-lace-back-maxi-dress-in-powder-blue/dp/SHON-WD495/?d=Womens&sectionURL=https%3A%2F%2Fwww.revolve.com%2Fshona-joy-la-lune-bias-cowl-maxi-dress-in-desert-rose%2Fdp%2FSHON-WD368%2F%3Fd%3DF%26currency%3DILS%26source%3Dgoogle%26countrycode%3DIL%26lang%3Den%26deeplink%3Dtrue%26gad_source%3D1%26gclid%3DCjwKCAiAg9urBhB_EiwAgw88mRQCt5IRA1HJHYSst_843Zxf311ft1wbGenYxFZGKKyFYFoIos-6uhoC-98QAvD_BwE%26gclsrc%3Daw.ds&srcType=dp_recs_expanded",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/uv/SHON-WD495_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/SHON-WD495_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/SHON-WD495_V3.jpg",
    ],
    brand: "Shona Joy",
    id: "1RB01",
    recommendedSize: "",
    tags: ["wedding guest dress", "avatarMaya"],
  },
  {
    title: "Millie Halter Dress",
    price: { amount: 222.0, currency: "" },
    vendors: [
      {
        name: "revolve",
        url: "https://www.revolve.com/millie-halter-dress-in-hazelnut/dp/SIGR-WD162/?d=Womens&srcType=dp_recs_viewed2",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/uv/SIGR-WD162_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/SIGR-WD162_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/SIGR-WD162_V3.jpg",
    ],
    brand: "Significant Other",
    id: "762VP",
    recommendedSize: "",
    tags: ["wedding guest dress", "avatarMaya"],
  },
  {
    title: "La Lune Bias Cowl Maxi Dress",
    price: { amount: 299.0, currency: "" },
    vendors: [
      {
        name: "revolve",
        url: "https://www.revolve.com/shona-joy-la-lune-bias-cowl-maxi-dress-in-desert-rose/dp/SHON-WD368/?d=F&currency=ILS&source=google&countrycode=IL&lang=en&deeplink=true&gad_source=1&gclid=CjwKCAiAg9urBhB_EiwAgw88mRQCt5IRA1HJHYSst_843Zxf311ft1wbGenYxFZGKKyFYFoIos-6uhoC-98QAvD_BwE&gclsrc=aw.ds",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/uv/SHON-WD368_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/SHON-WD368_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/SHON-WD368_V5.jpg",
    ],
    brand: "Shona Joy",
    id: "9NAQ9",
    recommendedSize: "",
    tags: ["maxi", "avatarMaya"],
  },
  {
    title: "Barrow Silk Dress",
    price: { amount: 250.0, currency: "" },
    vendors: [
      {
        name: "thereformation",
        url: "https://www.thereformation.com/products/barrow-silk-dress/1310049AKO.html?dwvar_1310049AKO_color=AKO",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://media.thereformation.com/image/upload/f_auto,q_auto,dpr_2.0/w_400,c_scale//PRD-SFCC/1310049/ARTICHOKE/1310049.1.ARTICHOKE?_s=RAABAB0",
      "https://media.thereformation.com/image/upload/f_auto,q_auto,dpr_2.0/w_400,c_scale//PRD-SFCC/1310049/ARTICHOKE/1310049.2.ARTICHOKE?_s=RAABAB0",
      "https://media.thereformation.com/image/upload/f_auto,q_auto,dpr_2.0/w_400,c_scale//PRD-SFCC/1310049/ARTICHOKE/1310049.3.ARTICHOKE?_s=RAABAB0",
    ],
    brand: "Reformation",
    id: "9GRDX",
    recommendedSize: "",
    tags: ["maxi", "avatarMaya"],
  },
  {
    title: "Jeany Silk Dress",
    price: { amount: 200.0, currency: "" },
    vendors: [
      {
        name: "thereformation",
        url: "https://www.thereformation.com/products/jeany-silk-dress/1311710OLI.html?dwvar_1311710OLI_color=TUR&quantity=1",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://media.thereformation.com/image/upload/f_auto,q_auto,dpr_1.0/w_800,c_scale//PRD-SFCC/1311710/TURMERIC/1311710.2.TURMERIC?_s=RAABAB0",
      "https://media.thereformation.com/image/upload/f_auto,q_auto,dpr_1.0/w_800,c_scale//PRD-SFCC/1311710/TURMERIC/1311710.3.TURMERIC?_s=RAABAB0",
      "https://media.thereformation.com/image/upload/f_auto,q_auto,dpr_1.0/w_800,c_scale//PRD-SFCC/1311710/TURMERIC/1311710.1.TURMERIC?_s=RAABAB0",
    ],
    brand: "Reformation",
    id: "6HEKL",
    recommendedSize: "",
    tags: ["maxi", "avatarMaya"],
  },
  {
    title: "Andee Dress",
    price: { amount: 190.0, currency: "" },
    vendors: [
      {
        name: "thereformation",
        url: "https://www.thereformation.com/products/andee-dress/1310069MNL.html?dwvar_1310069MNL_color=MNL&xse=sm_el_ab86c836d2384b6498a6ada29f6c71f6&xse_prod_code=1310069MNL",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://media.thereformation.com/image/upload/f_auto,q_auto,dpr_1.0/w_800,c_scale//PRD-SFCC/1310069/MINERAL/1310069.2.MINERAL?_s=RAABAB0",
      "https://media.thereformation.com/image/upload/f_auto,q_auto,dpr_1.0/w_800,c_scale//PRD-SFCC/1310069/MINERAL/1310069.0.MINERAL?_s=RAABAB0",
      "https://media.thereformation.com/image/upload/f_auto,q_auto,dpr_1.0/w_800,c_scale//PRD-SFCC/1310069/MINERAL/1310069.3.MINERAL?_s=RAABAB0",
    ],
    brand: "Reformation",
    id: "TUSED",
    recommendedSize: "",
    tags: ["maxi", "avatarMaya"],
  },
  {
    title: "Island Time Lime Green Ruffled Midi Dress",
    price: { amount: 80.0, currency: "" },
    vendors: [
      {
        name: "lulus",
        url: "https://www.lulus.com/products/island-time-lime-green-ruffled-midi-dress/773662.html",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://www.lulus.com/images/product/xlarge/3929950_773662.jpg?w=560",
      "https://www.lulus.com/images/product/xlarge/3929970_773662.jpg?w=560",
      "https://www.lulus.com/images/product/xlarge/3929990_773662.jpg?w=560",
    ],
    brand: "Lulus",
    id: "9RJSA",
    recommendedSize: "",
    tags: ["2 straps", "avatarMaya"],
  },
  {
    title: "Stacie Maxi Dress",
    price: { amount: 120.0, currency: "" },
    vendors: [
      {
        name: "revolve",
        url: "https://www.revolve.com/superdown-stacie-maxi-dress-in-sage/dp/SPDW-WD2000/?d=F&currency=ILS&source=google&countrycode=IL&lang=en&deeplink=true&gad_source=1&gclid=CjwKCAiA-P-rBhBEEiwAQEXhH0fBcJCtKNyhraFUAoVcvec-0ihv0TGuk0pl3DkvNuFHPCpEOKrSrxoC4AsQAvD_BwE&gclsrc=aw.ds",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/uv/SPDW-WD2000_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/SPDW-WD2000_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/SPDW-WD2000_V3.jpg",
    ],
    brand: "superdown",
    id: "H62VM",
    recommendedSize: "",
    tags: ["2 straps", "avatarMaya"],
  },
  {
    title: "Capri Diamonte Slip Dress",
    price: { amount: 150.0, currency: "" },
    vendors: [
      {
        name: "revolve",
        url: "https://www.revolve.com/bardot-capri-diamonte-slip-dress-in-canary-yellow/dp/BARD-WD582/?d=Womens&page=1&lc=42&itrownum=11&itcurrpage=1&itview=05",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/uv/BARD-WD582_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/BARD-WD582_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/BARD-WD582_V3.jpg",
    ],
    brand: "Bardot",
    id: "0DP5F",
    recommendedSize: "",
    tags: ["2 straps", "avatarMaya"],
  },
  {
    title: "Julietta Slip Dress",
    price: { amount: 220.0, currency: "" },
    vendors: [
      {
        name: "revolve",
        url: "https://www.revolve.com/alice-olivia-julietta-slip-dress-in-burnt-rose/dp/ALI-WD1000/?d=Womens&page=1&lc=58&itrownum=63&itcurrpage=1&itview=05",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/uv/ALI-WD1000_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/ALI-WD1000_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/ALI-WD1000_V3.jpg",
    ],
    brand: "Alice + Olivia",
    id: "CPRC7",
    recommendedSize: "",
    tags: ["2 straps", "avatarMaya"],
  },

  {
    title: "Jet Plunge Neck Gown",
    price: { amount: 300.0, currency: "" },
    vendors: [
      {
        name: "revolve",
        url: "https://www.revolve.com/lagence-jet-plunge-neck-gown-in-bright-orange/dp/LAGR-WD167/?d=Womens&sectionURL=undefined&srcType=dp_recs_expanded",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/uv/LAGR-WD167_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/LAGR-WD167_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/LAGR-WD167_V3.jpg",
    ],
    brand: "L'AGENCE",
    id: "G3D7Q",
    recommendedSize: "",
    tags: ["2 straps", "avatarMaya"],
  },
  {
    title: "Bambina Dress",
    price: { amount: 220.0, currency: "" },
    vendors: [
      {
        name: "revolve",
        url: "https://www.revolve.com/katie-may-bambina-dress-in-blush/dp/KATR-WD205/?d=Womens&page=1&lc=33&srcType=dp_recs_expanded&itrownum=7",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/KATR-WD205_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/KATR-WD205_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/KATR-WD205_V3.jpg",
    ],
    brand: "Katie May",
    id: "TV6LD",
    recommendedSize: "",
    tags: ["2 straps", "avatarMaya"],
  },
  {
    title: "Bambi Gown",
    price: { amount: 190.0, currency: "" },
    vendors: [
      {
        name: "revolve",
        url: "https://www.revolve.com/katie-may-bambi-gown-in-dusty-rose/dp/KATR-WD22/?d=Womens&page=1&lc=53&srcType=dp_recs_expanded&itrownum=11",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/uv/KATR-WD22_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/KATR-WD22_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/KATR-WD22_V3.jpg",
    ],
    brand: "Katie May",
    id: "DFU9N",
    recommendedSize: "",
    tags: ["2 straps", "avatarMaya"],
  },
  {
    title: "Daniela Silk Dress",
    price: { amount: 300.0, currency: "" },
    vendors: [
      {
        name: "thereformation",
        url: "https://www.thereformation.com/products/daniela-silk-dress/1314260COP.html?dwvar_1314260COP_color=COP",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://media.thereformation.com/image/upload/f_auto,q_auto,dpr_1.0/w_800,c_scale//PRD-SFCC/1314260/COPPER/1314260.1.COPPER?_s=RAABAB0",
      "https://media.thereformation.com/image/upload/f_auto,q_auto,dpr_1.0/w_800,c_scale//PRD-SFCC/1314260/COPPER/1314260.2.COPPER?_s=RAABAB0",
      "https://media.thereformation.com/image/upload/f_auto,q_auto,dpr_1.0/w_800,c_scale//PRD-SFCC/1314260/COPPER/1314260.3.COPPER?_s=RAABAB0",
    ],
    brand: "reformation",
    id: "T2DJK",
    recommendedSize: "",
    tags: ["2 straps", "avatarMaya"],
  },
  {
    title: "Fernanda Dress",
    price: { amount: 200.0, currency: "" },
    vendors: [
      {
        name: "thereformation",
        url: "https://www.thereformation.com/products/fernanda-dress/1314502ROW.html?dwvar_1314502ROW_color=ROW",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://media.thereformation.com/image/upload/f_auto,q_auto,dpr_1.0/w_800,c_scale//PRD-SFCC/1314502/DARK_BROWN/1314502.1.DARK_BROWN?_s=RAABAB0",
      "https://media.thereformation.com/image/upload/f_auto,q_auto,dpr_1.0/w_800,c_scale//PRD-SFCC/1314502/DARK_BROWN/1314502.2.DARK_BROWN?_s=RAABAB0",
      "https://media.thereformation.com/image/upload/f_auto,q_auto,dpr_1.0/w_800,c_scale//PRD-SFCC/1314502/DARK_BROWN/1314502.3.DARK_BROWN?_s=RAABAB0",
    ],
    brand: "reformation",
    id: "WT342",
    recommendedSize: "",
    tags: ["2 straps", "avatarMaya"],
  },
  {
    title: "Maureen Mock Neck Minidress\r\n",
    price: { amount: 40.0, currency: "" },
    vendors: [
      {
        name: "nordstrom",
        url: "https://www.nordstrom.com/s/maureen-mock-neck-minidress/7583887?origin=keywordsearch-personalizedsort&breadcrumb=Home%2FAll%20Results&color=001",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://n.nordstrommedia.com/id/sr3/1a58fb2b-0e07-403a-857b-34d968a9052d.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
      "https://n.nordstrommedia.com/id/sr3/2a822b03-8fc4-4ef3-a732-04c00b7baab7.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
      "https://n.nordstrommedia.com/id/sr3/0d8d5fd0-cee8-482c-9989-11c49fdb2419.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
    ],
    brand: "Michael Stars\r\n",
    id: "Y60OO",
    recommendedSize: "",
    tags: ["causal", "avatarMaya"],
  },
  {
    title: "Bootcut High Jeans",
    price: { amount: 26.0, currency: "" },
    vendors: [
      {
        name: "www2",
        url: "https://www2.hm.com/en_gb/productpage.1074295013.html",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://lp2.hm.com/hmgoepprod?set=format%5Bwebp%5D%2Cquality%5B79%5D%2Csource%5B%2F23%2F7c%2F237cf324baf9a7e17131cc901d6f2a7e367dd681.jpg%5D%2Corigin%5Bdam%5D%2Ccategory%5B%5D%2Ctype%5BLOOKBOOK%5D%2Cres%5Bm%5D%2Chmver%5B1%5D&call=url%5Bfile%3A%2Fproduct%2Fmain%5D",
      "https://lp2.hm.com/hmgoepprod?set=format%5Bwebp%5D%2Cquality%5B79%5D%2Csource%5B%2F65%2Fa8%2F65a8fd082c53e4d8f13449031aa9c4912111edcd.jpg%5D%2Corigin%5Bdam%5D%2Ccategory%5B%5D%2Ctype%5BLOOKBOOK%5D%2Cres%5Bm%5D%2Chmver%5B1%5D&call=url%5Bfile%3A%2Fproduct%2Fmain%5D",
      "https://lp2.hm.com/hmgoepprod?set=format%5Bwebp%5D%2Cquality%5B79%5D%2Csource%5B%2F28%2F14%2F281420eefe8f6579eb276867fca2fd247a69324b.jpg%5D%2Corigin%5Bdam%5D%2Ccategory%5B%5D%2Ctype%5BLOOKBOOK%5D%2Cres%5Bm%5D%2Chmver%5B1%5D&call=url%5Bfile%3A%2Fproduct%2Fmain%5D",
    ],
    brand: "H&M",
    id: "9D80X",
    recommendedSize: "",
    tags: ["causal", "avatarMaya"],
  },
  {
    title: "Roi sweater ",
    price: { amount: 28.0, currency: "" },
    vendors: [
      {
        name: "blueberrytlv",
        url: "https://blueberrytlv.co.il/product/roi-sweater",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://blueberrytlv.co.il/wp-content/uploads/2022/03/WhatsApp-Image-2023-12-12-at-11.29.56-2.jpeg",
      "https://blueberrytlv.co.il/wp-content/uploads/2022/03/WhatsApp-Image-2023-12-12-at-11.29.57.jpeg",
      "https://blueberrytlv.co.il/wp-content/uploads/2022/03/WhatsApp-Image-2023-12-12-at-11.29.56-3.jpeg",
    ],
    brand: "blueberrytlv",
    id: "T7ZL1",
    recommendedSize: "",
    tags: ["causal", "avatarMaya"],
  },
  {
    title: "CLEAN COTTON HEAVY WEIGHT T-SHIRT",
    price: { amount: 12.0, currency: "" },
    vendors: [
      {
        name: "zara",
        url: "https://www.zara.com/uk/en/clean-cotton-heavy-weight-t-shirt-p04424454.html?v1=284444549&v2=2354439",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://static.zara.net/photos///2023/I/0/1/p/4424/454/922/2/w/563/4424454922_1_1_1.jpg?ts=1695196004639",
      "https://static.zara.net/photos///2023/I/0/1/p/4424/454/922/2/w/563/4424454922_2_1_1.jpg?ts=1695196004539",
      "https://static.zara.net/photos///2023/I/0/1/p/4424/454/922/2/w/563/4424454922_2_3_1.jpg?ts=1695196002813",
    ],
    brand: "Zara",
    id: "NCVNZ",
    recommendedSize: "",
    tags: ["causal", "avatarMaya"],
  },
  {
    title: "Mori Oversize Faux Leather Jacket",
    price: { amount: 70.0, currency: "" },
    vendors: [
      {
        name: "nordstrom",
        url: "https://www.nordstrom.com/s/mori-oversize-faux-leather-jacket/7629477?origin=category-personalizedsort&breadcrumb=Home%2FWomen%2FClothing%2FJackets%20%26%20Blazers&color=200",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://n.nordstrommedia.com/id/sr3/c5c90975-c5fd-4ac7-9396-0587d7b3f517.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
      "https://n.nordstrommedia.com/id/sr3/b383f8b0-c526-4d3d-9bba-96385f8d78ab.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
      "https://n.nordstrommedia.com/id/sr3/4dd3d9df-6e93-43bf-a40b-04ca18c3fbee.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
    ],
    brand: "EDIKTED",
    id: "DWBTH",
    recommendedSize: "",
    tags: ["causal", "avatarMaya"],
  },
  {
    title: "Selly Leather Double-Breasted Trench Coat",
    price: { amount: 400.0, currency: "" },
    vendors: [
      {
        name: "saksfifthavenue",
        url: "https://www.saksfifthavenue.com/product/khaite-selly-leather-double-breasted-trench-coat-0400016988883.html?ranMID=13816&ranEAID=0c5b5rpoqTU&ranSiteID=0c5b5rpoqTU-aZBOLCQG9OSvDFh3UOFYag&site_refer=AFF001&mid=13816&siteID=0c5b5rpoqTU-aZBOLCQG9OSvDFh3UOFYag&LSoid=1278460&LSlinkid=10&LScreativeid=1",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://assets.vogue.com/photos/633c960c78be746c6de552cd/master/w_1600,c_limit/GettyImages-1238961475.jpg",
      "https://cdn.saksfifthavenue.com/is/image/saks/0400016988883_BLACK?wid=968&hei=1292&qlt=90&resMode=sharp2&op_usm=0.9,1.0,8,0",
      "https://cdn.saksfifthavenue.com/is/image/saks/0400016988883_BLACK_A1?wid=968&hei=1292&qlt=90&resMode=sharp2&op_usm=0.9,1.0,8,0",
    ],
    brand: "Khaite",
    id: "R1HMS",
    recommendedSize: "",
    tags: ["hailey biber", "avatarMaya"],
  },
  {
    title: "Women's Natural Marcelle Low-rise Cotton Twill Cargo Pants",
    price: { amount: 227.0, currency: "" },
    vendors: [
      {
        name: "lyst",
        url: "https://www.lyst.com/shop/cargo-pants/?atc_campaign=ROWil-en-Paid-Google-DSA-WEB-Designer&atc_content=ROWil-en-Paid-DSA-WEB-All&atc_country=ROW&atc_grouping=DSA&atc_medium=cpc&atc_source=google&atc_term=&sem_id=A6868931410&atc_label=&gad_source=1&gclid=CjwKCAiAvJarBhA1EiwAGgZl0GyPddMl0rnHRJVDmggH1wMwk5NWOM1BPOQVQbQR8ZKQqefgBlf5hhoCCikQAvD_BwE&page=2&paid_session_id=363a52a7-d1f7-4f6e-9753-5140563ff9aa&reason=feed-product-image&link_id=977877435&previous_page_type=feed&previous_page_sub_type=generic_subcategory&origin_feed=%2Fshop%2Fcargo-pants%2F#slug=citizens-of-humanity-marcelle-low-rise-cotton-twill-cargo-pants",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://img.mytheresa.com/1094/1238/100/jpeg/catalog/product/55/P00851812_b1.jpg",
      "https://cdna.lystit.com/520/650/n/photos/mytheresa/17e2d8f5/citizens-of-humanity-green-Marcelle-Low-rise-Cotton-Twill-Cargo-Pants.jpeg",
      "https://cdna.lystit.com/520/650/n/photos/mytheresa/df98428f/citizens-of-humanity-green-Marcelle-Low-rise-Cotton-Twill-Cargo-Pants.jpeg",
    ],
    brand: "Citizens of Humanity",
    id: "J0WMT",
    recommendedSize: "",
    tags: ["hailey biber", "avatarMaya"],
  },
  {
    title: "BARDOT",
    price: { amount: 50.0, currency: "" },
    vendors: [
      {
        name: "is4",
        url: "https://is4.revolveassets.com/images/p4/n/z/BARD-WS197_V4.jpg",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://i.pinimg.com/564x/56/03/ed/5603edea80d31bce613460a355afd905.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/BARD-WS197_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/BARD-WS197_V2.jpg",
    ],
    brand: "Katja Roll Neck Knit Bodysuit",
    id: "UKDBE",
    recommendedSize: "",
    tags: ["hailey biber", "avatarMaya"],
  },
  {
    title: "Trailblazer",
    price: { amount: 70.0, currency: "" },
    vendors: [
      {
        name: "alohas",
        url: "https://www.alohas.io/products/trailblazer-black?variant=39823104934081",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://i.pinimg.com/564x/35/a2/ac/35a2ac87bd6598df2c81d4e72f780560.jpg",
      "https://www.alohas.io/cdn/shop/files/ECOM-5-753_1080x.jpg?v=1698144120",
      "https://www.alohas.io/cdn/shop/files/ECOM-5-750_1080x.jpg?v=1698144120",
    ],
    brand: " Black Leather Loafers\r\n",
    id: "HSY8M",
    recommendedSize: "",
    tags: ["hailey biber", "avatarMaya"],
  },
  {
    title: "UOOZEE",
    price: { amount: 81.0, currency: "" },
    vendors: [
      {
        name: "uoozee",
        url: "https://www.uoozee.com/products/loose-pleated-solid-color-wide-leg-pants-bottoms?epik=dj0yJnU9ckZyRUNvVHBhQ2hURXcyQ01QNnZFODRwS19wUTZ6VWYmcD0wJm49UmhEMnduRlVqdGd3ejMyd290MXBRUSZ0PUFBQUFBR1dVZDVF",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://i.pinimg.com/564x/69/78/eb/6978ebb59edf2fbcd3b63cedf5b8fcd7.jpg",
      "https://img.fantaskycdn.com/30a5280ed723445bd3df355d16a8a8d4_750x.jpeg",
      "https://img.fantaskycdn.com/936f643e87351d5f791fdcb2f9590d24_750x.jpeg",
    ],
    brand: "Loose Pleated Solid Color Wide Leg Pants Bottoms\r\n",
    id: "JSR9F",
    recommendedSize: "",
    tags: ["hailey biber", "avatarMaya"],
  },
  {
    title: "Twist Split Front Tube Top",
    price: { amount: 35.0, currency: "" },
    vendors: [
      {
        name: "nordstrom",
        url: "https://www.nordstrom.com/s/twist-split-front-tube-top/7582584?origin=coordinating-7582584-0-3-PDP_2_BLUEPRINT.SEEDED_SUB_BLUEPRINT_1-recbot-blueprint_item_to_item&recs_placement=PDP_2_BLUEPRINT.SEEDED_SUB_BLUEPRINT-1&recs_strategy=blueprint_item_to_item&recs_source=recbot&recs_page_type=product&recs_seed=7453714&color=BLACK",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://n.nordstrommedia.com/id/sr3/74adfff8-2215-4a22-8486-1c8d33984960.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
      "https://n.nordstrommedia.com/id/sr3/e8063a4d-54ba-40b3-a181-5cd58fb56678.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
      "https://n.nordstrommedia.com/id/sr3/805c8a1c-ce6a-46bc-a0f7-bd55e6e9ae28.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
    ],
    brand: "EDIKTED",
    id: "WNRD8",
    recommendedSize: "",
    tags: ["night out", "avatrMaya"],
  },
  {
    title: "Relaxed Fit Blazer",
    price: { amount: 50.0, currency: "" },
    vendors: [
      {
        name: "nordstrom",
        url: "https://www.nordstrom.com/s/relaxed-fit-blazer/7286306?origin=coordinating-7286306-0-1-PDP_1-recbot-also_viewed_graph&recs_placement=PDP_1&recs_strategy=also_viewed_graph&recs_source=recbot&recs_page_type=product&recs_seed=6861965&color=BLACK",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://n.nordstrommedia.com/id/sr3/27f16795-2621-4b13-a6c6-fd2550558788.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
      "https://n.nordstrommedia.com/id/sr3/6f118e39-1a42-4393-9a98-da09854be071.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
      "https://n.nordstrommedia.com/id/sr3/27f16795-2621-4b13-a6c6-fd2550558788.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
    ],
    brand: "Open Edit",
    id: "NHK4L",
    recommendedSize: "",
    tags: ["night out", "avatrMaya"],
  },
  {
    title: "It Girl Back Cutout Minidress\r\n",
    price: { amount: 34.0, currency: "" },
    vendors: [
      {
        name: "Edikted",
        url: "https://edikted.com/products/s14086_black",
        thumbnailUrl: "",
        origin: "",
      },
      {
        name: "Nordstrom",
        url: "https://www.nordstrom.com/s/it-girl-back-cutout-minidress/7497878?origin=category-personalizedsort&breadcrumb=Home%2FWomen%2FClothing%2FDresses&color=001",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://n.nordstrommedia.com/id/sr3/8f095814-476b-4d2a-9566-00b2af87ecb2.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196",
      "https://n.nordstrommedia.com/id/sr3/0ada4b65-c56f-4533-87ef-b242036868eb.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196",
      "https://n.nordstrommedia.com/id/sr3/778e4aae-9cd4-4bef-a145-d346b85bc017.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196",
    ],
    brand: "EDIKTED",
    id: "A8S0N",
    recommendedSize: "",
    tags: ["night out", "avatrMaya"],
  },
  {
    title: "POLYAMIDE CROP TOP",
    price: { amount: 18.0, currency: "" },
    vendors: [
      {
        name: "zara",
        url: "https://www.zara.com/uk/en/polyamide-crop-top-p03641839.html?v1=300091002&v2=2354495",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://static.zara.net/photos///2023/I/0/1/p/3641/839/800/2/w/563/3641839800_6_1_1.jpg?ts=1699960738461",
      "https://static.zara.net/photos///2023/I/0/1/p/3641/839/800/2/w/563/3641839800_6_2_1.jpg?ts=1699960737888",
      "https://static.zara.net/photos///2023/I/0/1/p/3641/839/800/2/w/563/3641839800_6_3_1.jpg?ts=1699960737885",
    ],
    brand: "Zara",
    id: "3AW8C",
    recommendedSize: "",
    tags: ["night out", "avatrMaya"],
  },
  {
    title: "Jayde Denim Flared Jumpsuit",
    price: { amount: 158.0, currency: "" },
    vendors: [
      {
        name: "Free people",
        url: "https://www.freepeople.com/shop/jayde-flare-jumpsuit/?color=047&type=REGULAR&quantity=1",
        thumbnailUrl: "",
        origin: "",
      },
      {
        name: "revolve",
        url: "https://www.revolve.com/free-people-jayde-flare-jumpsuit-in-sunburst-blue/dp/FREE-WC259/?d=F&sectionURL=%2Fr%2FDisplayProduct.jsp%3FaliasURL%3Dfree-people-jayde-flare-jumpsuit-in-sunburst-blue%2Fdp%2FFREE-WC259%26d%3DF%26currency%3DILS%26source%3Dgoogle%26countrycode%3DIL%26lang%3Den%26deeplink%3Dtrue%26gad_source%3D1%26gclid%3DCjwKCAiAqNSsBhAvEiwAn_tmxYlGkfRp93TxcRa4i0cyIEoovDwuv5l-0n1R9_zhYJlAP0GgFTXQ3xoCFPcQAvD_BwE%26gclsrc%3Daw.ds%26product%3DFREE-WC259&code=FREE-WC259&srcType=dp_coloroption",
        thumbnailUrl: "",
        origin: "",
      },
      {
        name: "nordstrom",
        url: "https://www.nordstrom.com/s/free-people-jayde-denim-flared-jumpsuit-sunburst-blue/7189888?origin=keywordsearch-personalizedsort&breadcrumb=Home%2FAll%20Results&color=403",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://n.nordstrommedia.com/id/sr3/aa04afb1-5c06-4c6e-b24f-78aee5ed8548.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196",
      "https://n.nordstrommedia.com/id/sr3/f83d0e4a-2f9b-4915-900a-bcc15622e770.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196",
      "https://n.nordstrommedia.com/id/sr3/c395c44e-91aa-46a8-bd2b-fc1c8da2d9e4.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196",
    ],
    brand: "Free People",
    id: "WELS8",
    recommendedSize: "",
    tags: ["night out", "avatrMaya"],
  },
];

const MockVikiDress: MockMiniProduct[] = [
  {
    title: "Traci Strapless Dress",
    price: { amount: 395.0, currency: "" },
    vendors: [
      {
        name: "bloomingdales",
        url: "https://www.bloomingdales.com/shop/product/milly-traci-strapless-dress?ID=4967193&CategoryID=1005206",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://images.bloomingdalesassets.com/is/image/BLM/products/9/optimized/13304959_fpx.tif?op_sharpen=1&wid=700&fit=fit,1&$filtersm$&fmt=webp",
      "https://images.bloomingdalesassets.com/is/image/BLM/products/4/optimized/13304964_fpx.tif?op_sharpen=1&wid=700&fit=fit,1&$filtersm$&fmt=webp",
      "https://images.bloomingdalesassets.com/is/image/BLM/products/2/optimized/13304962_fpx.tif?op_sharpen=1&wid=700&fit=fit,1&$filtersm$&fmt=webp",
    ],
    brand: "MILLY",
    id: "O8MKC",
    recommendedSize: "",
    tags: ["afternoon wedding", " avatarViki"],
  },
  {
    title: "RENATA GOWN",
    price: { amount: 2298.0, currency: "" },
    vendors: [
      {
        name: "cultgaia",
        url: "https://cultgaia.com/products/renata-gown-gold?variant=40369573232714",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://cultgaia.com/cdn/shop/files/231006_DR_CULT_GAIA_ECOM_RESHOOT_LOOK_09_0009_WEB_810x.jpg?v=1697655232",
      "https://cultgaia.com/cdn/shop/files/231006_DR_CULT_GAIA_ECOM_RESHOOT_LOOK_09_0093_WEB_810x.jpg?v=1697655290",
      "https://cultgaia.com/cdn/shop/files/231006_DR_CULT_GAIA_ECOM_RESHOOT_LOOK_09_0028_WEB_810x.jpg?v=1697655290",
    ],
    brand: "Cult Gaia",
    id: "FI2TP",
    recommendedSize: "",
    tags: ["afternoon wedding", " avatarViki"],
  },
  {
    title: "Embellished Strapless Midi Dress",
    price: { amount: 798.0, currency: "" },
    vendors: [
      {
        name: "nordstrom",
        url: "https://www.nordstrom.com/s/mac-duggal-embellished-strapless-midi-dress/7554383?origin=category-personalizedsort&breadcrumb=Home%2FBrands%2FMac%20Duggal&color=040",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://n.nordstrommedia.com/id/sr3/e19674ba-9ce5-4474-8dca-aa4afbfee56e.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
      "https://n.nordstrommedia.com/id/sr3/e0e16af4-4b7c-4739-ac89-e5932a50bc4f.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
      "https://n.nordstrommedia.com/id/sr3/46e0a853-8ba0-49fb-b83a-c9bad901fccc.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
    ],
    brand: "Mac Duggal",
    id: "7D6LM",
    recommendedSize: "",
    tags: ["afternoon wedding", " avatarViki"],
  },
  {
    title:
      "Michelle velvet-trimmed floral-print hammered silk-satin maxi dress",
    price: { amount: 940.0, currency: "" },
    vendors: [
      {
        name: "net-a-porter",
        url: "https://www.net-a-porter.com/en-it/shop/product/saloni/clothing/maxi-dresses/michelle-velvet-trimmed-floral-print-hammered-silk-satin-maxi-dress/1647597326306598",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://www.net-a-porter.com/variants/images/1647597326306598/ou/w2000_a3-4_q60.jpg",
      "https://www.net-a-porter.com/variants/images/1647597326306598/bk/w920_a3-4_q60.jpg",
      "https://www.net-a-porter.com/variants/images/1647597326306598/in/w920_a3-4_q60.jpg",
    ],
    brand: "SALONI",
    id: "82EFK",
    recommendedSize: "",
    tags: ["afternoon wedding", " avatarViki"],
  },
  {
    title: "One-shoulder ruffled charmeuse gown",
    price: { amount: 2900.0, currency: "" },
    vendors: [
      {
        name: "net-a-porter",
        url: "https://www.net-a-porter.com/en-it/shop/product/lanvin/clothing/gowns/one-shoulder-ruffled-charmeuse-gown/1647597299304512",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://www.net-a-porter.com/variants/images/1647597299304512/ou/w2000_a3-4_q60.jpg",
      "https://www.net-a-porter.com/variants/images/1647597299304512/in/w920_a3-4_q60.jpg",
      "https://www.net-a-porter.com/variants/images/1647597299304512/bk/w920_a3-4_q60.jpg",
    ],
    brand: "LANVIN",
    id: "5QGHQ",
    recommendedSize: "",
    tags: ["afternoon wedding", " avatarViki"],
  },
  {
    title: "Strapless draped silk-chiffon gown",
    price: { amount: 3500.0, currency: "" },
    vendors: [
      {
        name: "net-a-porter",
        url: "https://www.net-a-porter.com/en-us/shop/product/alexander-mcqueen/clothing/gowns/strapless-draped-silk-chiffon-gown/1647597331983281",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://www.net-a-porter.com/variants/images/1647597331983281/ou/w2000_a3-4_q60.jpg",
      "https://www.net-a-porter.com/variants/images/1647597331983281/in/w920_a3-4_q60.jpg",
      "https://www.net-a-porter.com/variants/images/1647597331983281/bk/w920_a3-4_q60.jpg",
    ],
    brand: "ALEXANDER MCQUEEN",
    id: "VDIY7",
    recommendedSize: "",
    tags: ["afternoon wedding", " avatarViki"],
  },
  {
    title: "Wanda belted crepe de chine gown",
    price: { amount: 1960.0, currency: "" },
    vendors: [
      {
        name: "net-a-porter",
        url: "https://www.net-a-porter.com/en-it/shop/product/nervi/clothing/gowns/wanda-belted-crepe-de-chine-gown/1647597321323771",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://www.net-a-porter.com/variants/images/1647597321323771/ou/w2000_a3-4_q60.jpg",
      "https://www.net-a-porter.com/variants/images/1647597321323771/in/w920_a3-4_q60.jpg",
      "https://www.net-a-porter.com/variants/images/1647597321323771/bk/w920_a3-4_q60.jpg",
    ],
    brand: "NERVI",
    id: "AEYQN",
    recommendedSize: "",
    tags: ["afternoon wedding", " avatarViki"],
  },
  {
    title: "Falabella one-shoulder crystal-embellished satin gown",
    price: { amount: 2300.0, currency: "" },
    vendors: [
      {
        name: "net-a-porter",
        url: "https://www.net-a-porter.com/en-it/shop/product/stella-mccartney/clothing/gowns/plus-net-sustain-falabella-one-shoulder-crystal-embellished-satin-gown/1647597310919015",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://www.net-a-porter.com/variants/images/1647597310919015/ou/w2000_a3-4_q60.jpg",
      "https://www.net-a-porter.com/variants/images/1647597310919015/in/w920_a3-4_q60.jpg",
      "https://www.net-a-porter.com/variants/images/1647597310919015/ou/w2000_a3-4_q60.jpg",
    ],
    brand: "STELLA MCCARTNEY",
    id: "2BGU3",
    recommendedSize: "",
    tags: ["afternoon wedding", " avatarViki"],
  },
  {
    title: "ALEXA GOWN",
    price: { amount: 998.0, currency: "" },
    vendors: [
      {
        name: "cultgaia",
        url: "https://cultgaia.com/products/alexa-gown-gold?variant=40413844045898",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://cultgaia.com/cdn/shop/files/LOOK_12_1013_WEB_810x.jpg?v=1699919992",
      "https://cultgaia.com/cdn/shop/files/LOOK_12_1028_WEB_33c68906-003c-468c-98ba-1e833e193325_810x.jpg?v=1699919992",
      "https://cultgaia.com/cdn/shop/files/LOOK_12_1057_WEB_99884fec-3df7-44d6-9a35-d844926d52eb_810x.jpg?v=1699919992",
    ],
    brand: "Cult Gaia",
    id: "GWJIB",
    recommendedSize: "",
    tags: ["daring and sexy", " avatarViki"],
  },
  {
    title: "Sequin Wrap Front Cocktail Minidress",
    price: { amount: 358.0, currency: "" },
    vendors: [
      {
        name: "nordstrom",
        url: "https://www.nordstrom.com/s/sequin-wrap-front-cocktail-minidress/7050586?origin=keywordsearch-personalizedsort&breadcrumb=Home%2FAll%20Results&fashioncolor=Ivory&color=270",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "\nhttps://n.nordstrommedia.com/id/sr3/120ae4e4-b22e-4583-93e9-918d63638363.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
      "\nhttps://n.nordstrommedia.com/id/sr3/d1c8d0e4-256d-4ad7-bb89-265f805f2678.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
      "https://n.nordstrommedia.com/id/sr3/3c392db6-2844-4fef-bf14-594cefca1cec.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
    ],
    brand: "Leena for Mac Duggal",
    id: "UR740",
    recommendedSize: "",
    tags: ["daring and sexy", " avatarViki"],
  },
  {
    title: "Asymmetric One-Shoulder Metallic Minidress",
    price: { amount: 298.0, currency: "" },
    vendors: [
      {
        name: "nordstrom",
        url: "https://www.nordstrom.com/s/asymmetric-one-shoulder-metallic-minidress/7646052?origin=coordinating-7646052-0-1-PDP_1-recbot-also_viewed_graph&recs_placement=PDP_1&recs_strategy=also_viewed_graph&recs_source=recbot&recs_page_type=product&recs_seed=7050586&color=ANTIQUE%20GOLD",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "\nhttps://n.nordstrommedia.com/id/sr3/42364ef9-eafb-4b77-857e-4612521a0b16.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
      "\nhttps://n.nordstrommedia.com/id/sr3/ac1dcf36-bef3-448f-ac4e-d55bcadfbf0a.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
      "https://n.nordstrommedia.com/id/sr3/69e4f766-7d63-41f8-80f2-e7ef447e7717.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
    ],
    brand: "Leena for Mac Duggal",
    id: "GXK1Y",
    recommendedSize: "",
    tags: ["daring and sexy", " avatarViki"],
  },
  {
    title: "Embellished Halter Neck Trapeze Minidress",
    price: { amount: 458.0, currency: "" },
    vendors: [
      {
        name: "nordstrom",
        url: "https://www.nordstrom.com/s/mac-duggal-embellished-halter-neck-trapeze-minidress/7518794?origin=coordinating-7518794-0-11-PDP_2_BLUEPRINT.SEEDED_SUB_BLUEPRINT_1-recbot-blueprint_item_to_item&recs_placement=PDP_2_BLUEPRINT.SEEDED_SUB_BLUEPRINT-1&recs_strategy=blueprint_item_to_item&recs_source=recbot&recs_page_type=product&recs_seed=7050586&color=GOLD",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "\nhttps://n.nordstrommedia.com/id/sr3/7b0ccc9e-d3be-4f52-8c6b-73b4d105837f.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
      "https://n.nordstrommedia.com/id/sr3/d9bad157-291b-4499-b256-c9f6bba25901.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
      "https://n.nordstrommedia.com/id/sr3/67ad9c21-e4e6-426a-835c-08ad4210e736.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
    ],
    brand: "Mac Duggal",
    id: "BSPAU",
    recommendedSize: "",
    tags: ["daring and sexy", " avatarViki"],
  },
  {
    title: "Hanna Gown",
    price: { amount: 849.0, currency: "" },
    vendors: [
      {
        name: "revolve",
        url: "https://www.revolve.com/bronx-and-banco-hanna-gown-in-multicolor/dp/BROR-WD106/?d=Womens&page=1&lc=9&plpSrc=%2Fr%2FBrands.jsp%3FpageNumVisNav%3D1%26price%3DFIVE_TO_SIX%26price%3DSIX_TO_1000%26price%3DOVER_1000%26filters%3Dprice%26loadVisNav%3Dtrue%26sortBy%3Dfeatured&vn=true&vnclk=true",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/BROR-WD106_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/BROR-WD106_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/BROR-WD106_V3.jpg",
    ],
    brand: "Bronx and Banco",
    id: "HAUQN",
    recommendedSize: "",
    tags: ["daring and sexy", " avatarViki"],
  },
  {
    title: "Crystal Strap Ruched Jersey Gown",
    price: { amount: 398.0, currency: "" },
    vendors: [
      {
        name: "nordstrom",
        url: "https://www.nordstrom.com/s/mac-duggal-crystal-strap-ruched-jersey-gown/6632255?origin=coordinating-6632255-0-2-PDP_1-recbot-also_viewed_graph&recs_placement=PDP_1&recs_strategy=also_viewed_graph&recs_source=recbot&recs_page_type=product&recs_seed=5779325&color=RED",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "\nhttps://n.nordstrommedia.com/id/sr3/d588b809-854c-43cb-978d-023f9571c627.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
      "\nhttps://n.nordstrommedia.com/id/sr3/951faa52-8f07-417e-b10b-cf12a7f1bb8f.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
      "https://n.nordstrommedia.com/id/sr3/7b6ed679-4ccb-4c2c-b643-a532e765266f.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
    ],
    brand: "Mac Duggal",
    id: "0LJ7Q",
    recommendedSize: "",
    tags: ["daring and sexy", " avatarViki"],
  },
  {
    title: "CHIARA DRESS",
    price: { amount: 578.0, currency: "" },
    vendors: [
      {
        name: "cultgaia",
        url: "https://cultgaia.com/products/chiara-dress-cabana?variant=40413853155402",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://cultgaia.com/cdn/shop/files/231006_DR_CULT_GAIA_ECOM_RESHOOT_LOOK_18_0001_810x.jpg?v=1701270942",
      "https://cultgaia.com/cdn/shop/files/231006_DR_CULT_GAIA_ECOM_RESHOOT_LOOK_18_0016_810x.jpg?v=1701271026",
      "https://cultgaia.com/cdn/shop/files/231006_DR_CULT_GAIA_ECOM_RESHOOT_LOOK_18_0032_810x.jpg?v=1701271039",
    ],
    brand: "Cult Gaia",
    id: "6JDKB",
    recommendedSize: "",
    tags: ["daring and sexy", " avatarViki"],
  },
  {
    title: "Paris Gown",
    price: { amount: 298.0, currency: "" },
    vendors: [
      {
        name: "revolve",
        url: "https://www.revolve.com/michael-costello-x-revolve-paris-gown-in-black/dp/MELR-WD69/?d=Womens&page=1&lc=52&itrownum=13&itcurrpage=1&itview=05",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/MELR-WD69_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/MELR-WD69_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/MELR-WD69_V3.jpg",
    ],
    brand: "Michael Costello",
    id: "H1321",
    recommendedSize: "",
    tags: ["daring and sexy", " avatarViki"],
  },
  {
    title: "Reyna Sequin Dress",
    price: { amount: 595.0, currency: "" },
    vendors: [
      {
        name: "bloomingdales",
        url: "https://www.bloomingdales.com/shop/product/ramy-brook-reyna-sequin-dress?ID=4941827&CategoryID=1005206",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://images.bloomingdalesassets.com/is/image/BLM/products/3/optimized/13248133_fpx.tif?op_sharpen=1&wid=700&fit=fit,1&$filtersm$&fmt=webp",
      "https://images.bloomingdalesassets.com/is/image/BLM/products/5/optimized/13248135_fpx.tif?op_sharpen=1&wid=700&fit=fit,1&$filtersm$&fmt=webp",
      "https://images.bloomingdalesassets.com/is/image/BLM/products/4/optimized/13248134_fpx.tif?op_sharpen=1&wid=700&fit=fit,1&$filtersm$&fmt=webp",
    ],
    brand: "Ramy Brook",
    id: "K71D2",
    recommendedSize: "",
    tags: ["daring and sexy", " avatarViki"],
  },
  {
    title: "Clarisse Sheer Sequin Dress",
    price: { amount: 872.7857142857143, currency: "" },
    vendors: [
      {
        name: "bloomingdales",
        url: "https://www.bloomingdales.com/shop/product/retrofete-clarisse-sheer-sequin-dress?ID=4930006&CategoryID=1005206",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://images.bloomingdalesassets.com/is/image/BLM/products/6/optimized/13266186_fpx.tif?op_sharpen=1&wid=700&fit=fit,1&$filtersm$&fmt=webp",
      "https://images.bloomingdalesassets.com/is/image/BLM/products/8/optimized/13266188_fpx.tif?op_sharpen=1&wid=700&fit=fit,1&$filtersm$&fmt=webp",
      "https://images.bloomingdalesassets.com/is/image/BLM/products/7/optimized/13266187_fpx.tif?op_sharpen=1&wid=700&fit=fit,1&$filtersm$&fmt=webp",
    ],
    brand: "Retrofete",
    id: "E02AS",
    recommendedSize: "",
    tags: ["daring and sexy", " avatarViki"],
  },
  {
    title: "Long Sleeve Turtle Fishtail Gown",
    price: { amount: 335.0, currency: "" },
    vendors: [
      {
        name: "revolve",
        url: "https://www.revolve.com/norma-kamali-long-sleeve-turtle-fishtail-gown-in-large-glenn-plaid/dp/NKAM-WD249/?d=Womens&vn=true&page=1&lc=9&itrownum=3&itcurrpage=1&itview=05",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/NKAM-WD249_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/NKAM-WD249_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/NKAM-WD249_V3.jpg",
    ],
    brand: "Norma Kamali",
    id: "DD148",
    recommendedSize: "",
    tags: ["formal wear", "feed", "avatarViki"],
  },
  {
    title: "Spliced Dress Fishtail Gown",
    price: { amount: 300.0, currency: "" },
    vendors: [
      {
        name: "saksfifthavenue",
        url: "https://www.saksfifthavenue.com/product/norma-kamali-spliced-dress-fishtail-gown-0400020035183.html?dwvar_0400020035183_color=BLACK%20AND%20BLACK%20MESH",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://cdn.saksfifthavenue.com/is/image/saks/0400020035183_BLACKANDBLACKMESH_A1?wid=968&hei=1292&qlt=90&resMode=sharp2&op_usm=0.9,1.0,8,0",
      "https://cdn.saksfifthavenue.com/is/image/saks/0400020035183_BLACKANDBLACKMESH_ASTL?wid=984&hei=1312&qlt=90&resMode=sharp2&op_usm=0.9,1.0,8,0",
      "https://cdn.saksfifthavenue.com/is/image/saks/0400020035183_BLACKANDBLACKMESH?wid=968&hei=1292&qlt=90&resMode=sharp2&op_usm=0.9,1.0,8,0",
    ],
    brand: "Norma Kamali",
    id: "259BY",
    recommendedSize: "",
    tags: ["wedding guest dress", "avatarViki"],
  },
  {
    title: "Benjamin Strapless Gown",
    price: { amount: 500.0, currency: "" },
    vendors: [
      {
        name: "saksfifthavenue",
        url: "https://www.saksfifthavenue.com/product/staud-benjamin-strapless-gown-0400020268921.html?dwvar_0400020268921_color=NAVY",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://cdn.saksfifthavenue.com/is/image/saks/0400020268921_NAVY_A1?wid=968&hei=1292&qlt=90&resMode=sharp2&op_usm=0.9,1.0,8,0",
      "https://cdn.saksfifthavenue.com/is/image/saks/0400020268921_NAVY_ASTL?wid=984&hei=1312&qlt=90&resMode=sharp2&op_usm=0.9,1.0,8,0",
      "https://cdn.saksfifthavenue.com/is/image/saks/0400020268921_NAVY?wid=968&hei=1292&qlt=90&resMode=sharp2&op_usm=0.9,1.0,8,0",
    ],
    brand: "Staud",
    id: "MVFEV",
    recommendedSize: "",
    tags: ["wedding guest dress", "avatarViki"],
  },
  {
    title: "Strapless Silk Crepe Bow Gown",
    price: { amount: 4290.0, currency: "" },
    vendors: [
      {
        name: "saksfifthavenue",
        url: "https://www.saksfifthavenue.com/product/adam-lippes-strapless-silk-crepe-bow-gown-0400019820250.html?dwvar_0400019820250_color=VERMILION",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://cdn.saksfifthavenue.com/is/image/saks/0400019820250_VERMILION_ASTL?wid=984&hei=1312&qlt=90&resMode=sharp2&op_usm=0.9,1.0,8,0",
      "https://cdn.saksfifthavenue.com/is/image/saks/0400019820250_VERMILION?wid=968&hei=1292&qlt=90&resMode=sharp2&op_usm=0.9,1.0,8,0",
      "https://cdn.saksfifthavenue.com/is/image/saks/0400019820250_VERMILION_A1?wid=968&hei=1292&qlt=90&resMode=sharp2&op_usm=0.9,1.0,8,0",
    ],
    brand: "Adam Lippes",
    id: "QPVCY",
    recommendedSize: "",
    tags: ["wedding guest dress", "avatarViki"],
  },
  {
    title: "Silk Chiffon Plissé Gown",
    price: { amount: 998.0, currency: "" },
    vendors: [
      {
        name: "saksfifthavenue",
        url: "https://www.saksfifthavenue.com/product/adam-lippes-silk-chiffon-pliss%C3%A9-gown-0400017493038.html?dwvar_0400017493038_color=HOT%20PINK",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://cdn.saksfifthavenue.com/is/image/saks/0400017493038_HOTPINK_A1?wid=968&hei=1292&qlt=90&resMode=sharp2&op_usm=0.9,1.0,8,0",
      "https://cdn.saksfifthavenue.com/is/image/saks/0400017493038_HOTPINK_ASTL?wid=984&hei=1312&qlt=90&resMode=sharp2&op_usm=0.9,1.0,8,0",
      "https://cdn.saksfifthavenue.com/is/image/saks/0400017493038_HOTPINK?wid=968&hei=1292&qlt=90&resMode=sharp2&op_usm=0.9,1.0,8,0",
    ],
    brand: "Adam Lippes",
    id: "BR8M3",
    recommendedSize: "",
    tags: ["wedding guest dress", "avatarViki"],
  },
  {
    title: "Ribbon Sleeve Gown",
    price: { amount: 225.0, currency: "" },
    vendors: [
      {
        name: "revolve",
        url: "https://www.revolve.com/norma-kamali-ribbon-sleeve-gown-in-black/dp/NKAM-WD377/?d=Womens&page=1&lc=20&itrownum=5&itcurrpage=1&itview=05",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/NKAM-WD377_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/NKAM-WD377_V3.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/NKAM-WD377_V2.jpg",
    ],
    brand: "Norma Kamali",
    id: "6NBM3",
    recommendedSize: "",
    tags: ["wedding guest dress", "avatarViki"],
  },
  {
    title: "Slinky Jersey Sarong Maxi Dress",
    price: { amount: 328.0, currency: "" },
    vendors: [
      {
        name: "revolve",
        url: "https://www.revolve.com/helsa-slinky-jersey-sarong-maxi-dress-in-samba-red/dp/HLSA-WD43/?d=Womens&sectionURL=https%3A%2F%2Fwww.revolve.com%2Fdresses-gowns%2Fbr%2Fce1e59%2F%3Fnavsrc%3DsubDresses&code=HLSA-WD43&srcType=dp_coloroption",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/HLSA-WD43_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/HLSA-WD43_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/HLSA-WD43_V3.jpg",
    ],
    brand: "Norma Kamali",
    id: "ORT1U",
    recommendedSize: "",
    tags: ["wedding guest dress", "avatarViki"],
  },
  {
    title: "Diana Gown W/ Sleeve",
    price: { amount: 235.0, currency: "" },
    vendors: [
      {
        name: "revolve",
        url: "https://www.revolve.com/norma-kamali-diana-gown-w-sleeve-in-black/dp/NKAM-WD468/?d=Womens&page=1&lc=39&itrownum=10&itcurrpage=1&itview=05",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/NKAM-WD468_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/NKAM-WD468_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/NKAM-WD468_V3.jpg",
    ],
    brand: "Norma Kamali",
    id: "3I5ZB",
    recommendedSize: "",
    tags: ["wedding guest dress", "avatarViki"],
  },
  {
    title: "Diana Fishtail Gown",
    price: { amount: 495.0, currency: "" },
    vendors: [
      {
        name: "revolve",
        url: "https://www.revolve.com/norma-kamali-diana-fishtail-gown-in-black-mesh-nude/dp/NKAM-WD430/?d=Womens&page=1&lc=41&itrownum=11&itcurrpage=1&itview=05",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/NKAM-WD430_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/NKAM-WD430_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/NKAM-WD430_V3.jpg",
    ],
    brand: "Norma Kamali",
    id: "UAW7O",
    recommendedSize: "",
    tags: ["wedding guest dress", "avatarViki"],
  },
  {
    title: "The Demi one-shoulder maxi dress",
    price: { amount: 560.0, currency: "" },
    vendors: [
      {
        name: "farfetch",
        url: "https://www.farfetch.com/ie/shopping/women/solace-london-the-demi-one-shoulder-maxi-dress-item-22233917.aspx?storeid=13537",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://cdn-images.farfetch-contents.com/22/23/39/17/22233917_52163603_1000.jpg",
      "https://cdn-images.farfetch-contents.com/22/23/39/17/22233917_52163588_1000.jpg",
      "https://cdn-images.farfetch-contents.com/22/23/39/17/22233917_52163582_1000.jpg",
    ],
    brand: "Solace London",
    id: "FUUCZ",
    recommendedSize: "",
    tags: ["wedding guest dress", "avatarViki"],
  },
  {
    title: "Kara one-shoulder maxi dress",
    price: { amount: 580.0, currency: "" },
    vendors: [
      {
        name: "farfetch",
        url: "https://www.farfetch.com/ie/shopping/women/solace-london-kara-one-shoulder-maxi-dress-item-22299073.aspx?storeid=13537",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://cdn-images.farfetch-contents.com/22/29/90/73/22299073_52274697_1000.jpg",
      "https://cdn-images.farfetch-contents.com/22/29/90/73/22299073_52274698_1000.jpg",
      "https://cdn-images.farfetch-contents.com/22/29/90/73/22299073_52274696_1000.jpg",
    ],
    brand: "London solace",
    id: "IUXP6",
    recommendedSize: "",
    tags: ["wedding guest dress", "avatarViki"],
  },
  {
    title: "ADIRA GOWN",
    price: { amount: 1798.0, currency: "" },
    vendors: [
      {
        name: "cultgaia",
        url: "https://cultgaia.com/products/adira-gown-green-multi?variant=40369568383050",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://cultgaia.com/cdn/shop/files/231006_DR_CULT_GAIA_ECOM_RESHOOT_LOOK_01_0118_WEB_810x.jpg?v=1697655010",
      "https://cultgaia.com/cdn/shop/files/231006_DR_CULT_GAIA_ECOM_RESHOOT_LOOK_01_0175_WEB_810x.jpg?v=1697738429",
      "https://cultgaia.com/cdn/shop/files/231006_DR_CULT_GAIA_ECOM_RESHOOT_LOOK_01_0036_WEB_810x.jpg?v=1697738429",
    ],
    brand: "Cult Gaia",
    id: "ABRTX",
    recommendedSize: "",
    tags: ["wedding guest dress", "avatarViki"],
  },
  {
    title: "Sequin Cutout One-Shoulder Gown",
    price: { amount: 398.0, currency: "" },
    vendors: [
      {
        name: "nordstrom",
        url: "https://www.nordstrom.com/s/sequin-cutout-one-shoulder-gown/6815100?origin=coordinating-6815100-0-4-PDP_1-recbot-best_seller_brand_category_v2&recs_placement=PDP_1&recs_strategy=best_seller_brand_category_v2&recs_source=recbot&recs_page_type=product&recs_seed=7050586&color=SILVER",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://n.nordstrommedia.com/id/sr3/bd8beade-2e27-44ab-b0f8-b1bbe3635989.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
      "https://n.nordstrommedia.com/id/sr3/052f73da-b1c7-4fab-a2c0-26abeb11ed55.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
      "\nhttps://n.nordstrommedia.com/id/sr3/083352af-9bb8-41a8-9351-20157d8e18a1.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
    ],
    brand: "Mac Duggal",
    id: "UM7KG",
    recommendedSize: "",
    tags: ["wedding guest dress", "avatarViki"],
  },
  {
    title: "V-Neck Crepe Ballgown",
    price: { amount: 398.0, currency: "" },
    vendors: [
      {
        name: "nordstrom",
        url: "https://www.nordstrom.com/s/mac-duggal-v-neck-crepe-ballgown/5370911?origin=category-personalizedsort&breadcrumb=Home%2FBrands%2FMac%20Duggal&color=001",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://n.nordstrommedia.com/id/sr3/f8dcefd7-7fa0-4090-8fd7-5ac3782c05b5.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
      "https://n.nordstrommedia.com/id/sr3/a7372988-af38-4564-98c9-42ea4068eab8.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
      "\nhttps://n.nordstrommedia.com/id/sr3/b6ee87c4-50e8-46d4-9116-3cb65fa83d1b.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
    ],
    brand: "Mac Duggal",
    id: "JOIAF",
    recommendedSize: "",
    tags: ["wedding guest dress", "avatarViki"],
  },
  {
    title: "One-Shoulder Long Sleeve Jersey Gown",
    price: { amount: 398.0, currency: "" },
    vendors: [
      {
        name: "nordstrom",
        url: "https://www.nordstrom.com/s/mac-duggal-one-shoulder-long-sleeve-jersey-gown/5901021?origin=category-personalizedsort&breadcrumb=Home%2FBrands%2FMac%20Duggal&color=415",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://n.nordstrommedia.com/id/sr3/9de1ff13-32b8-4bdd-a1e8-3749966ea233.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
      "https://n.nordstrommedia.com/id/sr3/558f5777-be89-4e23-a518-d35ebbaa82fa.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
      "https://n.nordstrommedia.com/id/sr3/97620037-ed02-43df-83a0-4c22a818b02f.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
    ],
    brand: "Mac Duggal",
    id: "IMRF2",
    recommendedSize: "",
    tags: ["wedding guest dress", "avatarViki"],
  },
  {
    title: "Long Sleeve Sequin Wrap Gown",
    price: { amount: 398.0, currency: "" },
    vendors: [
      {
        name: "nordstrom",
        url: "https://www.nordstrom.com/s/ieena-for-mac-duggal-long-sleeve-sequin-wrap-gown/5755781?origin=coordinating-5755781-0-3-PDP_1-recbot-also_viewed_graph&recs_placement=PDP_1&recs_strategy=also_viewed_graph&recs_source=recbot&recs_page_type=product&recs_seed=6815100&color=SILVER",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://n.nordstrommedia.com/id/sr3/52b163d2-d4c0-4b14-9a8b-3f327cc14c89.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
      "https://n.nordstrommedia.com/id/sr3/db36b7ca-a127-4c6e-8010-a4ec8da023d7.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
      "\nhttps://n.nordstrommedia.com/id/sr3/e99f9980-fd48-4479-a19f-a6a64aa61237.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
    ],
    brand: "Leena for Mac Duggal",
    id: "NS9JI",
    recommendedSize: "",
    tags: ["wedding guest dress", "avatarViki"],
  },
  {
    title: "Checked wool flared pants",
    price: { amount: 700.0, currency: "" },
    vendors: [
      {
        name: "mytheresa",
        url: "https://www.mytheresa.com/int/en/women/balmain-checked-wool-flared-pants-grey-p00821607",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://img.mytheresa.com/1094/1238/100/jpeg/catalog/product/a1/P00821607_b1.jpg",
      "https://img.mytheresa.com/1094/1238/100/jpeg/catalog/product/a1/P00821607_b2.jpg",
      "https://img.mytheresa.com/1094/1238/100/jpeg/catalog/product/a1/P00821607.jpg",
    ],
    brand: "BALMAIN",
    id: "YZCMY",
    recommendedSize: "",
    tags: ["work attire", "feed", "avatarViki"],
  },
  {
    title: "GEORGINA ITALIAN PINSTRIPE PANT",
    price: { amount: 130.0, currency: "" },
    vendors: [
      {
        name: "bananarepublic",
        url: "https://bananarepublic.gap.com/browse/product.do?pid=817837002&cid=5013&vid=1&nav=meganav%3AWomen%3AWomen%27s%20Clothing%3ASuits#pdp-page-content",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://bananarepublic.gap.com/webcontent/0054/398/834/cn54398834.jpg",
      "https://bananarepublic.gap.com/webcontent/0054/398/768/cn54398768.jpg",
      "https://bananarepublic.gap.com/webcontent/0054/398/675/cn54398675.jpg",
    ],
    brand: "Banana republic",
    id: "4AYG5",
    recommendedSize: "",
    tags: ["work attire", "feed", "avatarViki"],
  },
  {
    title: "LUCI SLIM LUREX PINSTRIPE PANT",
    price: { amount: 105.0, currency: "" },
    vendors: [
      {
        name: "bananarepublic",
        url: "https://bananarepublic.gap.com/browse/product.do?pid=817845002&cid=5013&vid=1&nav=meganav%3AWomen%3AWomen%27s%20Clothing%3ASuits#pdp-page-content",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://bananarepublic.gap.com/webcontent/0054/329/174/cn54329174.jpg",
      "https://bananarepublic.gap.com/webcontent/0054/329/164/cn54329164.jpg",
      "https://bananarepublic.gap.com/webcontent/0054/337/825/cn54337825.jpg",
    ],
    brand: "Banana republic",
    id: "G8G9S",
    recommendedSize: "",
    tags: ["work attire", "feed", "avatarViki"],
  },
  {
    title: "Taryn Two Piece",
    price: { amount: 400.0, currency: "" },
    vendors: [
      {
        name: "thereformation",
        url: "https://www.thereformation.com/products/taryn-two-piece/1314122OYS.html?dwvar_1314122OYS_color=OYS&utm_source=25ZRSXYPVYg&utm_medium=10&utm_campaign=LinkShare&sid=LS298X102&ranMID=40090&ranEAID=1921634&ranSiteID=25ZRSXYPVYg-jeBXh4hXWvUlEByLKwY3Fg",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://media.thereformation.com/image/upload/f_auto,q_auto,dpr_2.0/w_700,c_scale//PRD-SFCC/1314122/OYSTER/1314122.3.OYSTER?_s=RAABAB0",
      "https://media.thereformation.com/image/upload/f_auto,q_auto,dpr_2.0/w_700,c_scale//PRD-SFCC/1314122/OYSTER/1314122.2.OYSTER?_s=RAABAB0",
      "https://media.thereformation.com/image/upload/f_auto,q_auto,dpr_2.0/w_700,c_scale//PRD-SFCC/1314122/OYSTER/1314122.1.OYSTER?_s=RAABAB0",
    ],
    brand: "reformation",
    id: "IHSVB",
    recommendedSize: "",
    tags: ["work attire", "feed", "avatarViki"],
  },
  {
    title: "Long Sleeve Turtle Fishtail Midi Dress",
    price: { amount: 872.7857142857143, currency: "" },
    vendors: [
      {
        name: "revolve",
        url: "https://www.revolve.com/norma-kamali-long-sleeve-turtle-fishtail-midi-dress-in-chocolate-glenn-plaid-tweed/dp/NKAM-WD507/?d=Womens&sectionURL=undefined&srcType=dp_recs_expanded",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://is4.revolveassets.com/images/p4/n/z/NKAM-WD507_V1.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/NKAM-WD507_V2.jpg",
      "https://is4.revolveassets.com/images/p4/n/z/NKAM-WD507_V3.jpg",
    ],
    brand: "Norma Kamali",
    id: "7QO0W",
    recommendedSize: "",
    tags: ["amal clooney", "feed", "avatarViki"],
  },
  {
    title: "Pradera Encantada Dress",
    price: { amount: 1995.0, currency: "" },
    vendors: [
      {
        name: "johannaortiz",
        url: "https://www.johannaortiz.com/collections/ready-to-wear-dresses/products/pradera-encantada-mini-dress?variant=44190906777838",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://www.johannaortiz.com/cdn/shop/files/JORESORT24RTW17979_1800x1800.jpg?v=1686068046",
      "https://www.johannaortiz.com/cdn/shop/files/JORESORT24RTW17983_1000x.jpg?v=1686068045",
      "https://www.johannaortiz.com/cdn/shop/files/JORESORT24RTW17997_1800x1800.jpg?v=1686068046",
    ],
    brand: "Johanna ortiz",
    id: "WV2I8",
    recommendedSize: "",
    tags: ["amal clooney", "feed", "avatarViki"],
  },
  {
    title: "Belted cloqué and pleated chiffon midi dress",
    price: { amount: 555.0, currency: "" },
    vendors: [
      {
        name: "net-a-porter",
        url: "https://www.net-a-porter.com/en-us/shop/product/self-portrait/clothing/midi-dresses/belted-cloque-and-pleated-chiffon-midi-dress/1647597324583746",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://www.net-a-porter.com/variants/images/1647597324583746/ou/w2000_a3-4_q60.jpg",
      "https://www.net-a-porter.com/variants/images/1647597324583746/bk/w920_a3-4_q60.jpg",
      "https://www.net-a-porter.com/variants/images/1647597324583746/in/w920_a3-4_q60.jpg",
    ],
    brand: "SELF-PORTRAIT",
    id: "L6O80",
    recommendedSize: "",
    tags: ["amal clooney", "feed", "avatarViki"],
  },
  {
    title: "Oakley Vest",
    price: { amount: 200.0, currency: "" },
    vendors: [
      {
        name: "thereformation",
        url: "https://www.thereformation.com/products/oakley-vest/1314012OYS.html?dwvar_1314012OYS_color=BLK&quantity=1",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://media.thereformation.com/image/upload/f_auto,q_auto,dpr_2.0/w_700,c_scale//PRD-SFCC/1314012/BLACK/1314012.1.BLACK?_s=RAABAB0",
      "https://media.thereformation.com/image/upload/f_auto,q_auto,dpr_2.0/w_700,c_scale//PRD-SFCC/1314012/BLACK/1314012.4.BLACK?_s=RAABAB0",
      "https://media.thereformation.com/image/upload/f_auto,q_auto,dpr_2.0/w_700,c_scale//PRD-SFCC/1314012/BLACK/1314012.2.BLACK?_s=RAABAB0",
    ],
    brand: "reformation",
    id: "Y7GWT",
    recommendedSize: "",
    tags: ["amal clooney", "feed", "avatarViki"],
  },
  {
    title: "Rib-knit high-rise midi skirt",
    price: { amount: 215.0, currency: "" },
    vendors: [
      {
        name: "mytheresa",
        url: "https://www.mytheresa.com/int/en/women/proenza-schouler-rib-knit-high-rise-midi-skirt-white-p00756488?utm_source=sea_pla&utm_medium=google&utm_campaign=google_sea&ef_id=Cj0KCQiAhc-sBhCEARIsAOVwHuQeWNSozhqzK9I_VW7tZg0MjT_OPs_6SycK1Bb01SSboN2u9--ODeIaApu5EALw_wcB&chn=sea_shopping&src=google&cmp=17381422691&tarea=row&tar=&ag=&ptyp=&feed_num=P00756488-2&gclid=Cj0KCQiAhc-sBhCEARIsAOVwHuQeWNSozhqzK9I_VW7tZg0MjT_OPs_6SycK1Bb01SSboN2u9--ODeIaApu5EALw_wcB&gad_source=1&slink=1",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://img.mytheresa.com/1094/1238/100/jpeg/catalog/product/23/P00756488.jpg",
      "https://img.mytheresa.com/1094/1238/100/jpeg/catalog/product/23/P00756488_b1.jpg",
      "https://img.mytheresa.com/1094/1238/100/jpeg/catalog/product/23/P00756488_d1.jpg",
    ],
    brand: "PROENZA SCHOULER",
    id: "PDXE3",
    recommendedSize: "",
    tags: ["amal clooney", "feed", "avatarViki"],
  },
  {
    title: "Cape Sleeve Crepe Sheath Dress",
    price: { amount: 200.0, currency: "" },
    vendors: [
      {
        name: "nordstrom",
        url: "https://www.nordstrom.com/s/cape-sleeve-crepe-sheath-dress/6547573?origin=keywordsearch-personalizedsort&breadcrumb=Home%2FAll%20Results&color=001",
        thumbnailUrl: "",
        origin: "",
      },
    ],
    imgUrls: [
      "https://n.nordstrommedia.com/id/sr3/ebd64531-00dd-4adc-bfb6-03598611fb94.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
      "https://n.nordstrommedia.com/id/sr3/bc7b3311-f23d-471e-8113-809a9bf2468b.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
      "https://n.nordstrommedia.com/id/sr3/c9843760-cd5d-4b97-9a6c-453caf4fd7f1.jpeg?crop=pad&pad_color=FFF&format=jpeg&w=780&h=1196&dpr=2",
    ],
    brand: "Betsy & Adam",
    id: "Z3CY3",
    recommendedSize: "",
    tags: ["work attire", "feed", "avatarViki"],
  },
];

export const allMockProducts: ProductDetails[] = [
  ...MockVikiDress,
  // ...MockWeddingDress,
  // ...mockBlackDressSimilar,
  // ...MockCargoPants,
  // ...MockMatchingCargoPants,
  // ...MockProducts,
  // ...MockLinenShirts,
  ...MockMayaDress,
].map((miniProduct) => ({
  brand: miniProduct.brand,
  description: "",
  id: miniProduct.id,
  imgUrls: miniProduct.imgUrls,
  price: miniProduct.price.amount as number,
  recommendedSize: miniProduct.recommendedSize,
  title: miniProduct.title,
  variants: {
    size: [
      { size: "Small", available: false },
      { size: "Medium", available: true },
      { size: "Large", available: true },
    ],
  },
  tags: miniProduct.tags?.map((tag) => tag.trim()),
  vendors: miniProduct.vendors.map(
    (vendor) =>
      ({
        ...omit(vendor, "price"),
        shipping: { max: 5, min: 2 },
        productLink: vendor.url,
        price: miniProduct.price,
      } as VendorOffer)
  ),
}));
const SECTION_SLICE = 5;

export const convertProductToNormal = (product: MockMiniProduct) => ({
  ...product,
  price: product.price.amount as number,
});

export const mockProductSectionsByUser: {
  avatarMaya: ProductSection[];
  avatarViki: ProductSection[];
} = {
  avatarMaya: [
    {
      title: "Date night outfits",
      products: MockMayaDress.filter((product) =>
        product.tags?.includes("night out")
      ).map(convertProductToNormal),
    },
    {
      title: "Hailey Bieber vibes",
      products: MockMayaDress.filter((product) =>
        product.tags?.includes("hailey biber")
      ).map(convertProductToNormal),
    },
    {
      title: "Casual looks",
      products: MockMayaDress.filter((product) =>
        product.tags?.includes("causal")
      ).map(convertProductToNormal),
    },
  ],
  avatarViki: [
    {
      title: "Work attire",
      products: shuffle(
        allMockProducts.filter((product) =>
          product.tags?.includes("work attire")
        )
      ).slice(0, SECTION_SLICE),
    },
    {
      title: "Dress like Amal Cloony",
      products: allMockProducts.filter((product) =>
        product.tags?.includes("amal clooney")
      ),
    },
    {
      title: "Formal dresses",
      products: MockVikiDress.filter((product) =>
        product.tags?.includes("wedding guest dress")
      ).map(convertProductToNormal),
    },
    {
      title: "Cocktail party dresses",
      products: MockVikiDress.filter(
        (product) =>
          product.tags?.includes("daring and sexy") ||
          product.tags?.includes("afternoon wedding")
      ).map(convertProductToNormal),
    },
  ],
};
