import { useInfiniteQuery } from "react-query";
import { InfiniteQuery } from "../../../@types/query";
import { LIMIT } from "../../../params";
import {
  BrandFilters,
  getSuggestedBrandsForUser,
  searchBrands,
} from "../../../services/api/brands/brand.api";
import { getNextSkip } from "../../../utils/reactQuery";

export const useBrands = (filters: BrandFilters) => {
  return useInfiniteQuery({
    queryKey: ["Brands", filters],

    queryFn: async ({ pageParam }) => {
      const res = await searchBrands({
        ...filters,
        limit: LIMIT,
        skip: pageParam,
      });
      return res.items;
    },
    getNextPageParam: getNextSkip,
    cacheTime: 60 * 1000,
    staleTime: 60 * 1000,
  });
};

export const useSuggestedBrands = (query: InfiniteQuery) => {
  return useInfiniteQuery({
    queryKey: ["SuggestedBrands", query.limit],
    queryFn: async ({ pageParam }) => {
      const res = await getSuggestedBrandsForUser({
        limit: query.limit,
        skip: pageParam,
      });

      return res.items;
    },
    getNextPageParam: (last: any[], all: any[][]) =>
      last.length === query.limit ? all.length * query.limit : null,
  });
};
