import axios, { Axios } from "axios";

export const baseUrl =
  process.env.NODE_ENV === "production" || process.env.REACT_APP_ENV === "prod"
    ? "https://shopper-backend-thtx.onrender.com"
    : "http://localhost:3030";

class ApiCalls {
  calls: Axios = axios.create({
    withCredentials: true,
  });

  setJWT(token: string) {
    this.calls = axios.create({
      withCredentials: true,
      headers: {
        Authorization: `${`Bearer ${token}`}`,
      },
    });
  }

  destroyJWT() {
    this.calls = axios.create({ withCredentials: true });
  }
}

export const api = new ApiCalls();
