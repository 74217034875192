import { useInfiniteQuery } from "react-query";
import { LIMIT } from "../../../params";
import { getListProducts } from "../../../services/api/lists/lists.api";
import { getNextSkip } from "../../../utils/reactQuery";

export const useListProducts = (id?: string) =>
  useInfiniteQuery({
    queryFn: async ({ pageParam }) => {
      const res = await getListProducts(id!, {
        limit: LIMIT,
        skip: pageParam,
      });
      return res.items;
    },
    queryKey: ["lists", "listsProducts", id],
    getNextPageParam: getNextSkip,
    enabled: !!id,
  });
