import { User } from "../../../@types/entities/user";
import { api } from "../api";
import { adminApi } from "./common";

export const authLogin = async (form: any): Promise<{ user: User }> => {
  try {
    const { data } = await api.calls.post(`${adminApi}/auth/login`, {
      form,
    });
    api.setJWT(data.token);
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const authVerify = async (): Promise<void> => {
  try {
    const { data } = await api.calls.post(`${adminApi}/auth`);
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
