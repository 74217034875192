import { EventWizardFormStep } from "../EventWizard";
import { WizardOptionsView } from "../common/commonEventWizard";

export const EventTime: React.FC<EventWizardFormStep> = ({
  onChange,
  value,
}) => {
  const onSelectEvent = (ev: string) => {
    onChange(ev);
  };
  return (
    <WizardOptionsView
      options={["Afternoon", "Evening"].map((o) => ({ value: o, label: o }))}
      onSelect={onSelectEvent}
      value={[value]}
      title="It's happening at..."
    />
  );
};
