import { ReactNode } from "react";
import s from "./TransitionContainer.module.scss";

export const TransitionContainer: React.FC<{
  step: number;
  children: ReactNode[];
}> = ({ step, children }) => {
  return (
    <div className={s.formContainer}>
      <div
        className={s.slider}
        style={{
          transform: `translateX(-${step * 100}%)`,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export const TransitionPage: React.FC<{
  children: ReactNode;
  naked?: boolean;
}> = ({ children, naked }) => {
  return (
    <div className={`${s.formBody} ${naked ? s.naked : ""}`}>{children}</div>
  );
};
