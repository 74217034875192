import { FC, useMemo, useState } from "react";
import { BaseFormContentProps } from "../../../../../../@types/form";
import { SearchAndRate } from "../../../../../../components/searchAndRate/SearchAndRate";

import { map } from "lodash";
import { AutoCompleteOption } from "../../../../../../components/Input/types";
import { BrandThumbnail } from "../../../../../../components/brandThumbnail/BrandThumbnail";

interface BrandSearchAndRateProps extends BaseFormContentProps {
  data: Record<string, number>;
}

export const BrandSearchAndRate: FC<BrandSearchAndRateProps> = ({
  onChange,
  data,
}) => {
  const [currentBrandOptions, setCurrentBrandOptions] = useState<
    AutoCompleteOption[] | null
  >();
  const onSearchBrands = async (term: string) => {};

  const dataForDisplay = useMemo(() => {
    if (!data) return [];
    console.log(data);

    return map(data, (val, key) => ({
      content: <BrandThumbnail size={50} icon={key as any} />,
      id: key,
      rate: val,
    }));
  }, [data]);

  return (
    <SearchAndRate
      selectedData={dataForDisplay}
      onRateSelected={(id, rate) => null}
      onSearch={onSearchBrands}
      searchOptions={currentBrandOptions}
      onSelectOption={(id) => onChange({ ...data, [id]: 3 })}
      isAsync
      placeholder="Search for your favorite brands"
      recommendedItems={[
        {
          content: <BrandThumbnail size={50} icon={"Asos"} />,
          id: "Asos",
          rate: 1,
        },
        {
          content: <BrandThumbnail size={50} icon={"Hm"} />,
          id: "Hm",
          rate: 1,
        },
        {
          content: <BrandThumbnail size={50} icon={"CalvinKlein"} />,
          id: "CalvinKlein",
          rate: 1,
        },
        {
          content: <BrandThumbnail size={50} icon={"RalphLauren"} />,
          id: "RalphLauren",
          rate: 1,
        },
        {
          content: <BrandThumbnail size={50} icon={"LuluLemon"} />,
          id: "LuluLemon",
          rate: 1,
        },
      ]}
    />
  );
};
