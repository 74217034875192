import { TutorialType } from "../../hooks/query/useTutorial";
import { api, baseUrl } from "./api";

export const analytics = {
  productView: async (productId: string) => {
    await api.calls.post(`${baseUrl}/api/events/product`, {
      type: "productView",
      productId,
    });
  },
  productLike: async (productId: string) => {
    await api.calls.post(`${baseUrl}/api/events/product`, {
      type: "productLike",
      productId,
    });
  },
  tutorialDone: async (type: TutorialType) => {
    await api.calls.post(`${baseUrl}/api/events/onboarding`, {
      type: "tutorialDone",
      tutorial: type,
    });
  },
  productDislike: async (productId: string) => {
    await api.calls.post(`${baseUrl}/api/events/product`, {
      type: "productDislike",
      productId,
    });
  },
  search() {},
  feedClick() {},
  chatInit() {},
};
