import { useContext } from "react";
import { AppContext } from "../../App";

export const FooterSpacerMobileApp = () => {
  const { isMobile, isApp } = useContext(AppContext);

  if (!isMobile || isApp) return <></>;

  return <div className="mobileFooterSpacer" />;
};
