export function camelCaseToCapitalized(str: string) {
  if (!str) return "";
  return str
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase())
    .trim();
}

export function getTagAndContent(
  tagStr: string
): { tag: string; content: string } | null {
  let openingTagEnd = tagStr.indexOf(">");
  if (openingTagEnd === -1) return null; // No opening tag found

  let openingTag = tagStr.substring(0, openingTagEnd + 1);

  const results = openingTag.match(/<(\w+)/i);
  if (!results) return null; // No tag name found
  if (typeof results[1] !== "string") return null;
  const tag = results[1];
  // Finding the closing tag
  let closingTag = `</${tag}>`;
  let closingTagStart = tagStr.toLowerCase().indexOf(closingTag.toLowerCase());
  if (closingTagStart === -1) return null; // No matching closing tag found

  // Extracting text content
  const content = tagStr.substring(openingTagEnd + 1, closingTagStart).trim();

  return { content, tag };
}
