import { Slide, SlideProps, Snackbar, SnackbarOrigin } from "@mui/material";
import React, {
  ReactElement,
  ReactNode,
  createContext,
  useContext,
  useState,
} from "react";

const SnackbarContext = createContext({
  openSnackbar: (content: ReactNode) => {},
});

export const useSnackbar = () => useContext(SnackbarContext);

const DEFAULT_ORIGIN: SnackbarOrigin = {
  vertical: "bottom",
  horizontal: "center",
};

export const SnackbarProvider: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [snackbarContent, setSnackbarContent] = useState<{
    content: ReactNode;
    origin: SnackbarOrigin;
  } | null>();
  const [isOpen, setIsOpen] = useState(false);

  const openSnackbar = (content: ReactNode, origin?: SnackbarOrigin) => {
    setSnackbarContent({ content, origin: origin ?? DEFAULT_ORIGIN });
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => {
      setSnackbarContent(null);
    }, 400);
  };

  return (
    <SnackbarContext.Provider value={{ openSnackbar }}>
      {children}
      <Snackbar
        open={isOpen}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={snackbarContent?.origin}
        TransitionComponent={SlideTransition}
      >
        <div
          style={{
            backgroundColor: "#191919f3",
            padding: "15px 20px",
            fontSize: "14px",
            color: "white",
            borderRadius: "5px",
            width: "calc(100% - 20px)",
          }}
        >
          {snackbarContent?.content}
        </div>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}
