import { isEmpty } from "lodash";
import { useMemo } from "react";
import { InfiniteData } from "react-query";

export const useFlattenInfiniteQuery: <T extends any = any>(
  data: InfiniteData<any[]> | undefined
) => T[] = (data) => {
  const flattened = useMemo(() => {
    if (!data || isEmpty(data)) return [];
    return data.pages.flatMap((p) => p);
  }, [data]);

  return flattened;
};
